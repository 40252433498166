import axios from 'axios';

const auth_token = localStorage.getItem('auth_token');
const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL + '/admin',
	timeout: 15000,
	headers: {
		Authorization: 'Bearer ' + auth_token,
	},
});

api.interceptors.response.use((res) => {
	// Add configurations here
	if (res.data.status === 401) {
		window.location.href = '/';
	}
	return res;
});

export default api;
