import React, { useEffect, useState } from 'react';
import './Events.scss';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import allActions from 'redux/actions';
import { listEventService } from './Events.service.js';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import about_bg from 'assets/images/about-btm.jpeg';
import ModalEventRegister from 'components/EventRegister/EventRegister';
import { EqualHeight, EqualHeightElement } from 'react-equal-height';

function Events() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const lang = useSelector((state) => state.lang);
	const auth = useSelector((state) => state.auth);
	const [page, setPage] = useState(1);
	const [event, setEvent] = useState([]);
	const [eventMeta, setEventMeta] = useState([]);

	useEffect(() => {
		getEvent();
	}, [page]);

	const getEvent = async () => {
		dispatch(allActions.loadingAction.showLoading());
		const result = await listEventService(page);
		dispatch(allActions.loadingAction.hideLoading());
		if (result.data.status === 200) {
			setEvent(result.data.data.records);
			setEventMeta(result.data.data.meta);
		} else {
			toast.error(result.data.error);
		}
	};
	const [openModals, setOpenModals] = useState({});

	const openModal = (eventId) => {
		setOpenModals((prevOpenModals) => ({
			...prevOpenModals,
			[eventId]: true,
		}));
	};

	const closeModal = (eventId) => {
		setOpenModals((prevOpenModals) => ({
			...prevOpenModals,
			[eventId]: false,
		}));
	};

	return (
		<>
			<Header />
			<section className="no-banner events-sec">
				<div className="container-fluid left-right-40">
					<EqualHeight>
						<div className="row g-3">
							{event?.map((val, i) => (
								<div class="col-xxl-3 col-lg-4 col-md-6 col-sm-12 col-12" key={val.id}>
									<div class="events-box">
										<div className="events-box-desc">
											<span className="events-box-img">
												<img className="img-block" src={val?.image ? val?.image : about_bg} alt="" />
											</span>
											<span className="events-type">
												{t('event.event_type')} : {val?.get_event_type?.name?.[lang]}
											</span>
											<EqualHeightElement name="eventTitle">
												<h4>
													<a href="javascript:;">{val?.title?.[lang]}</a>
												</h4>
											</EqualHeightElement>
											<EqualHeightElement name="eventdesc">
												<p>{val?.description?.[lang]}</p>
											</EqualHeightElement>
										</div>
										<EqualHeightElement name="eventdescInfo">
											<div className="event-organizer">
												<p>
													{t('event.organizer')} : {val?.organizer_name}
												</p>
												<ul class="event-organizer-info">
													<li>
														{t('order_sucess.phone')} : <span>{val?.organizer_phone_no}</span>
													</li>
													<li>
														{t('admin_user.email')} : <span>{val?.organizer_email}</span>
													</li>
												</ul>
											</div>
										</EqualHeightElement>
										<ul class="events-book-info">
											<li>
												{t('event.location')} : <span>{val?.location}</span>
											</li>
											<li>
												{t('order_sucess.date')} : <span>{moment(val?.event_date).format('DD/MM/YYYY')}</span>
											</li>
											<li>
												{t('event.time')} : <span>{val?.event_time}</span>
											</li>
										</ul>
										<div className="register-event">
											<button
												type="button"
												className="register-event-reg"
												onClick={() => {
													openModal(val.id);
												}}
											>
												{t('order_sucess.register')}
											</button>
											<ModalEventRegister
												title="Custom Modal"
												content="This is a custom modal created in React."
												isOpen={openModals[val.id] || false}
												onClose={() => closeModal(val.id)}
												eventId={val.id}
											/>
										</div>
									</div>
								</div>
							))}
						</div>
					</EqualHeight>
					<div className="popularBox-paginate mt-md-4 mt-3 mb-lg-0 mb-3">
						{event.length > 0 && (
							<ReactPaginate
								previousLabel={t('previos')}
								nextLabel={t('next')}
								breakLabel={'...'}
								pageCount={eventMeta?.totalPages}
								marginPagesDisplayed={2}
								pageRangeDisplayed={3}
								onPageChange={(page) => setPage(page.selected + 1)}
								containerClassName={'pagination justify-content-center custom-paginate'}
								pageClassName={'page-item'}
								pageLinkClassName={'page-link'}
								previousClassName={'page-item'}
								previousLinkClassName={'page-link'}
								nextClassName={'page-item'}
								nextLinkClassName={'page-link'}
								breakClassName={'page-item'}
								breakLinkClassName={'page-link'}
								activeClassName={'active'}
							/>
						)}
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Events;
