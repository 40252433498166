import { default as api } from 'config/frontApiConf';

export const getContentService = async (pageType = 3) => {
	try {
		let result = await api.get(`/get-page-content/${pageType}`);
		return result;
	} catch (error) {
		return error;
	}
};
export const getEventService = async (page) => {
	try {
		let result = await api.get(`/upcoming-event/list`);
		return result;
	} catch (error) {
		return error;
	}
};
export const getPublishedReportService = async (page) => {
	try {
		let result = await api.get(`/published-report/list`);
		return result;
	} catch (error) {
		return error;
	}
};
