import counterAction from './counterAction';
import langAction from './langAction';
import sidebarAction from './sidebarAction';
import authAction from './authAction';
import loadingAction from './loadingAction';
import cartAction from './cartAction';
import contentAction from './contentAction';

const allActions = {
	counterAction,
	langAction,
	sidebarAction,
	authAction,
	loadingAction,
	cartAction,
	contentAction,
};

export default allActions;
