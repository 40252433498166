import React from 'react';
import './WhoCanUse.scss';
import './WhoCanUse.responsive.scss';
import who_can1 from 'assets/images/who-can1.png';
import who_can2 from 'assets/images/who-can2.png';
import who_can3 from 'assets/images/who-can3.png';
import { useTranslation } from 'react-i18next';
function WhoCanUse() {
	const { t } = useTranslation();
	return (
		<>
			<section className="how-it-works-sec">
				<div className="container-fluid left-right-40">
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-12">
							<div className="sec-heading-who">
								<h3 className="pb-0">{t('who_can_use_it')}</h3>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12 how-it-box-wrap">
							<div className="how-it-box">
								<span className="it-box-img">
									<img className="img-block" src={who_can1} alt />
								</span>
								<p>{t('farmers_and_agri_entrepreneurs')}</p>
								<span className="arrow-icon-white" />
								<span className="arrow-icon-green" />
								<span className="arrow-sdw" />
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12 how-it-box-wrap">
							<div className="how-it-box">
								<span className="it-box-img">
									<img className="img-block" src={who_can2} alt />
								</span>
								<p>{t('transport_agencies_delivery_services')}</p>
								<span className="arrow-icon-white" />
								<span className="arrow-icon-green" />
								<span className="arrow-sdw" />
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12 how-it-box-wrap">
							<div className="how-it-box">
								<span className="it-box-img">
									<img className="img-block" src={who_can3} alt />
								</span>
								<p>{t('money_transfer_agencies')}</p>
								<span className="arrow-icon-white" />
								<span className="arrow-icon-green" />
								<span className="arrow-sdw" />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default WhoCanUse;
