import { default as api } from 'config/frontApiConf.js';

export const listCatService = async () => {
	try {
		let result = await api.get(`/fund/category-list`);
		return result;
	} catch (error) {
		return error;
	}
};

export const listFundService = async (data = {}, page = 1) => {
	try {
		let result = await api.post(`/fund/search-fundings?page=${page}`, data);
		return result;
	} catch (error) {
		return error;
	}
};
