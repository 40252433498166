import React, { useEffect, useState } from 'react';
import './Forum.scss';
import './Forum.responsive.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
import allActions from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { listForumService, postCommentService } from './Forum.service.js';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import ProfileImage from 'components/ProfileImage';

function Forum() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const lang = useSelector((state) => state.lang);
	const auth = useSelector((state) => state.auth);
	const [page, setPage] = useState(1);

	const [forum, setForum] = useState([]);
	const [forumMeta, setForumMeta] = useState([]);

	const [comment, setComment] = useState('');

	useEffect(() => {
		getForum();
	}, [page]);

	const getForum = async () => {
		dispatch(allActions.loadingAction.showLoading());
		const result = await listForumService(page);
		dispatch(allActions.loadingAction.hideLoading());
		if (result.data.status === 200) {
			setForum(result.data.data.records);
			setForumMeta(result.data.data.meta);
		} else {
			toast.error(result.data.error);
		}
	};

	const submitComment = async (parentId = 0, forumId = 0) => {
		const formData = {
			parent_id: parentId,
			forum_id: forumId,
			comments: comment,
		};
		let result = await postCommentService(formData);
		if (result.data.status === 200) {
			toast.success(`${t('forum_page.comment_done')} !`);
			window.location.reload();
		} else {
			toast.error(result.data.error);
		}
	};

	return (
		<>
			<Header />
			<div>
				<section className="no-banner Forum responsive-tabs">
					<div className="container-fluid left-right-40">
						<div className="row">
							<div className="forumLeft">
								<div className="popularBoxWrap">
									{forum?.map((val, i) => (
										<div className="popularBox">
											<div className="popularBoxTop d-flex flex-wrap">
												<div className="pbtLeft">
													<ProfileImage
														src={val?.get_created_by?.get_user_details?.avatar}
														firstName={val?.get_created_by?.first_name}
														lastName={val?.get_created_by?.last_name}
													/>
												</div>
												<div className="pbtRight">
													<div className="pbtRightTop d-flex flex-wrap justify-content-between align-items-center">
														<div className="pbtRightTopLeft d-flex align-items-center">
															<h5>{val?.get_created_by?.full_name}</h5>
															<p>{val?.time_ago}</p>
														</div>
														<div className="pbtRightTopRight">
															<i className="far fa-comment" /> {val?.get_comments?.length}
														</div>
													</div>
													<div className="pbtRightBtm">
														<h4>{val?.title?.en}</h4>
														<p>{val?.description?.en}</p>
													</div>
												</div>
											</div>
											{auth?.id && (
												<div className="popularBoxBtm mt-4">
													<div className="pbtRight position-relative ps-0">
														<textarea
															className="textArea"
															rows={4}
															placeholder="Write your comment"
															onChange={(e) => {
																setComment(e.target.value);
															}}
														/>
														<button
															type="button"
															className="post-comment"
															onClick={() => {
																submitComment(0, val?.id);
															}}
														>
															<i className="fas fa-paper-plane"></i>
														</button>
													</div>
												</div>
											)}

											{val?.get_comments?.length > 0 && (
												<div className="more-comments-wrap">
													<button type="button" className="more-comments-btn" data-bs-toggle="collapse" data-bs-target={`#commentliat${val?.id}`}>
														{t('forum_page.view_comment')}
													</button>
												</div>
											)}
											{val?.get_comments?.length > 0 && (
												<div id={`commentliat${val?.id}`} className="comments-wrap-list collapse ">
													{val?.get_comments?.map((comment, i) => (
														<div className="comment-wrap">
															<div className="comment-box">
																<div className="rply-comment d-flex flex-wrap">
																	<div className="rplylft">
																		<span className="d-block">
																			<ProfileImage
																				src={comment?.get_commented_by?.get_user_details?.avatar}
																				firstName={comment?.get_commented_by?.first_name}
																				lastName={comment?.get_commented_by?.last_name}
																			/>
																		</span>
																	</div>
																	<div className="rplyrgt">
																		<div className="rplyinfo">
																			<h5>
																				{comment?.get_commented_by?.full_name} <span className="cmnt-time">{comment?.time_ago}</span>
																			</h5>
																			<p>{comment?.comments} </p>
																		</div>
																	</div>
																</div>
																<div className="create-rply">
																	<ul>
																		{auth?.id && (
																			<li>
																				<button
																					type="button"
																					className="create-rply-btn cmnt-btn"
																					data-bs-toggle="collapse"
																					data-bs-target={`#createreply${comment?.id}`}
																				>
																					<i className="fas fa-reply"></i>
																					{t('forum_page.reply')}
																				</button>
																			</li>
																		)}

																		{comment?.get_reply?.length > 0 && (
																			<li>
																				<button
																					type="button"
																					className="create-rply-btn dlt-rply"
																					data-bs-toggle="collapse"
																					data-bs-target={`#rplyliat${comment?.id}`}
																				>
																					<i className="far fa-eye"></i>
																					{t('forum_page.view_reply')}
																				</button>
																			</li>
																		)}
																	</ul>
																</div>

																<div id={`createreply${comment?.id}`} className="popularBoxBtm mt-2 collapse">
																	<div className="pbtRight position-relative ps-0">
																		<textarea
																			className="textArea"
																			rows="4"
																			placeholder="Write your comment"
																			onChange={(e) => {
																				setComment(e.target.value);
																			}}
																		></textarea>
																		<button
																			type="button"
																			className="post-comment"
																			onClick={() => {
																				submitComment(comment?.id, val?.id);
																			}}
																		>
																			<i className="fas fa-paper-plane"></i>
																		</button>
																	</div>
																</div>
															</div>
															{comment?.get_reply?.length > 0 && (
																<div id={`rplyliat${comment?.id}`} className="reply-boxlist collapse">
																	{comment?.get_reply?.map((reply, i) => (
																		<div className="comment-box">
																			<div className="rply-comment d-flex flex-wrap">
																				<div className="rplylft">
																					<span className="d-block">
																						<ProfileImage
																							src={reply?.get_commented_by?.get_user_details?.avatar}
																							firstName={reply?.get_commented_by?.first_name}
																							lastName={reply?.get_commented_by?.last_name}
																						/>
																					</span>
																				</div>
																				<div className="rplyrgt">
																					<div className="rplyinfo">
																						<h5>
																							{reply?.get_commented_by?.full_name}{' '}
																							<span className="cmnt-time">{reply?.time_ago}</span>
																						</h5>
																						<p>{reply?.comments}</p>
																					</div>
																				</div>
																			</div>
																		</div>
																	))}
																</div>
															)}
														</div>
													))}
												</div>
											)}
										</div>
									))}
								</div>
								<div className="popularBox-paginate mt-md-4 mt-3 mb-lg-0 mb-3">
									{forum.length > 0 && (
										<ReactPaginate
											previousLabel={t('previos')}
											nextLabel={t('next')}
											breakLabel={'...'}
											pageCount={forumMeta?.totalPages}
											marginPagesDisplayed={2}
											pageRangeDisplayed={3}
											onPageChange={(page) => setPage(page.selected + 1)}
											containerClassName={'pagination justify-content-center custom-paginate'}
											pageClassName={'page-item'}
											pageLinkClassName={'page-link'}
											previousClassName={'page-item'}
											previousLinkClassName={'page-link'}
											nextClassName={'page-item'}
											nextLinkClassName={'page-link'}
											breakClassName={'page-item'}
											breakLinkClassName={'page-link'}
											activeClassName={'active'}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<Footer />
		</>
	);
}

export default Forum;
