import React, { useEffect, useState } from 'react';
import Breadcrumb from 'components/Admin/Breadcrumb';
import Alert from 'components/Alert';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { listOrder, addShippingChargeService, productDeliveredService } from './DeliveryServiceOrderList.service';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import OrderDetail from 'components/OrderDetail';
import InputErrorMsg from 'components/InputErrorMsg';

function DeliveryServiceOrderList() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const [orders, setOrders] = useState([]);
	const [orderMeta, setOrderMeta] = useState({});
	const [page, setPage] = useState(1);
	const { t } = useTranslation();
	const [orderId, setOrderId] = useState(0);
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [deliveryAgentList, setDeliveryAgentList] = useState([]);
	const [assignDeliveryAgent, setAssignDeliveryAgent] = useState([]);

	useEffect(() => {
		document.querySelector('.input-number').addEventListener('keypress', function (evt) {
			if ((evt.which !== 8 && evt.which !== 0 && evt.which < 48) || evt.which > 57) {
				evt.preventDefault();
			}
		});

		getOrderList();
	}, [page]);

	const getOrderList = async () => {
		let list = await listOrder(page);
		if (list.data.status === 200) {
			setOrders(list.data.data.records);
			setOrderMeta(list.data.data.meta);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};

	const onSubmit = async (data) => {
		let formData = {
			order_id: orderId,
			charge: data.shipping_charge,
		};
		let result = await addShippingChargeService(formData);
		if (result.data.status === 200) {
			await getOrderList();
			document.querySelector('.btn-close').click();
			reset();
			toast.success(`${t('admin_order.shipping_charge_added')} !`);
		} else {
			document.querySelector('.btn-close').click();
			toast.error(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const productDelivered = async (orderId) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-confirmation-box">
						<h1>{t('admin_user.are_you_sure')}</h1>
						<p>{t('vendor_order.you_want_to_do_this')}</p>
						<button onClick={onClose} className="no-btn">
							{t('admin_user.no')}
						</button>
						<button
							className="yes-btn"
							onClick={async () => {
								let formdata = {
									order_id: orderId,
								};

								let result = await productDeliveredService(formdata);
								if (result.data.status === 200) {
									await getOrderList();
									toast.success(`${t('delivery_service_order.product_has_been_delivered')}`);
								} else {
									toast.error(result.data.error);
									window.scrollTo(0, 0);
								}
								onClose();
							}}
						>
							{t('admin_user.yes')}
						</button>
					</div>
				);
			},
		});
	};
	const setOrderIdfunc = async (id) => {
		setOrderId(id);
	};
	const columns = [
		{
			name: t('admin_order.order_id'),
			selector: (row) => <div className="order-id-text">{row?.auto_gen_id}</div>,
			sortable: true,
		},
		{
			name: t('admin_order.order_date'),
			selector: (row) => moment(row?.created_at).format('DD-MM-YYYY'),
			sortable: true,
		},
		{
			name: t('admin_order.shipping_charge_mru'),
			selector: (row) => row?.shipping_charge,
			sortable: true,
		},
		{
			name: t('admin_order.total_amount_mru'),
			selector: (row) => row?.total_amount + row?.shipping_charge,
			sortable: true,
		},
		{
			name: t('admin_order.action'),
			cell: (row) => (
				<div className="dropdown">
					<div className="actionList" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg
							style={{ cursor: 'pointer' }}
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-sliders dropdown-toggle"
							data-toggle="dropdown"
							role="button"
							aria-expanded="false"
						>
							<line x1={4} y1={21} x2={4} y2={14} />
							<line x1={4} y1={10} x2={4} y2={3} />
							<line x1={12} y1={21} x2={12} y2={12} />
							<line x1={12} y1={8} x2={12} y2={3} />
							<line x1={20} y1={21} x2={20} y2={16} />
							<line x1={20} y1={12} x2={20} y2={3} />
							<line x1={1} y1={14} x2={7} y2={14} />
							<line x1={9} y1={8} x2={15} y2={8} />
							<line x1={17} y1={16} x2={23} y2={16} />
						</svg>
					</div>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						{row?.status === 1 && (
							<Link
								to="#"
								className="dropdown-item"
								data-bs-toggle="modal"
								data-bs-target="#shippingChargeModal"
								onClick={() => {
									setOrderIdfunc(row?.id);
								}}
							>
								{t('delivery_service_order.add_shipping_charge')}
							</Link>
						)}
						{row?.status === 7 && (
							<Link
								to="#"
								className="dropdown-item"
								onClick={() => {
									productDelivered(row?.id);
								}}
							>
								{t('admin_order.delivered')}
							</Link>
						)}
					</div>
				</div>
			),
		},
	];

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('admin_order.order') },
					{ link: '#', name: t('admin_order.list') },
				]}
				title={t('admin_order.order')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="datatable-wrap">
				<DataTable
					columns={columns}
					data={orders}
					striped
					pagination
					paginationServer
					paginationTotalRows={orderMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					expandableRows
					expandableRowsComponent={OrderDetail}
					onChangePage={(page) => setPage(page)}
				/>
			</div>

			<div
				className="modal fade cmn-modal"
				id="shippingChargeModal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="shippingChargeModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="shippingChargeModalLabel">
								{t('delivery_service_order.add_shipping_charge')}
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="row">
									<div className="col-12">
										<label className="admin-lbl">{t('admin_order.shipping_charge_mru')}</label>
										<div className="admim-input-wrap">
											<input
												type="number"
												className="form-control admim-input-style input-number"
												min={1}
												placeholder={t('delivery_service_order.enter_shipping_charge')}
												{...register('shipping_charge', { required: t('delivery_service_order.shipping_charge_is_required') })}
											/>
											{errors.shipping_charge && <InputErrorMsg error={errors.shipping_charge?.message} />}
										</div>
									</div>
									<div className="col-12">
										<div className="cmn-btn-wrap text-center">
											<button type="submit" className="cmn-btn">
												{t('funding_details.submit')}
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default DeliveryServiceOrderList;
