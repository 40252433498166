/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import './Header.scss';
import partner1 from 'assets/images/partner/partner1.png';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';
import NoImage from 'assets/images/avatar.png';
import { Link } from 'react-router-dom';
import globe from 'assets/images/globe.png';
import i18next, { changeLanguage } from 'i18next';
import { useTranslation } from 'react-i18next';
// import LoadingBar from 'react-top-loading-bar'
const languageMap = {
	en: { label: 'English', dir: 'ltr', active: true, value: 'en' },
	fr: { label: 'Français', dir: 'ltr', active: false, value: 'fr' },
	ar: { label: 'العربية', dir: 'rtl', active: false, value: 'ar' },
};
function Header() {
	// const [progressBar, setProgressBar] = useState(10);
	// useEffect(() => {
	//     // setTimeout(() => {
	//     setProgressBar(100);
	//     // }, 1000);

	// }, [progressBar])
	const auth = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const toggleSidebar = () => {
		// alert();
		dispatch(allActions.sidebarAction.toggleSidebar());
	};
	const logOut = () => {
		localStorage.removeItem('auth_token');
		window.location.href = '/';
	};
	//Language
	const { t } = useTranslation();
	const selected = localStorage.getItem('i18nextLng').split('-')[0] || 'en';
	const [langSeleted, setLangSelected] = useState(selected);
	const lang = useSelector((state) => state.lang);

	let dir = 'ltr';
	dir = lang === 'ar' ? 'rtl' : 'ltr';
	useEffect(() => {
		document.body.dir = languageMap[langSeleted].dir;
		document.body.className = `style-${languageMap[langSeleted].dir}`;
		dispatch(allActions.langAction.changeLanguage(languageMap[langSeleted].value));
	}, [langSeleted]);
	const changeLanguage = (e) => {
		i18next.changeLanguage(e.target.value);

		setLangSelected(e.target.value);
	};
	return (
		<>
			{/* <LoadingBar
                progress={progressBar}
                height={4}
                color='#78bc4c'
            /> */}
			<nav className="navbar navbar-expand navbar-light navbar-bg db-header">
				<a className="sidebar-toggle js-sidebar-toggle" onClick={toggleSidebar}>
					<i className="hamburger align-self-center" />
				</a>
				<div className="navbar-collapse collapse">
					<ul className="navbar-nav navbar-align">
						{/* <li className="nav-item dropdown">
                            <a className="nav-icon dropdown-toggle" href="#" id="alertsDropdown" data-bs-toggle="dropdown">
                                <div className="position-relative">
                                    <i className="far fa-bell"></i>
                                    <span className="indicator">4</span>
                                </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0" aria-labelledby="alertsDropdown">
                                <div className="dropdown-menu-header">
                                    4 New Notifications
                                </div>
                                <div className="list-group">
                                    <a href="#" className="list-group-item">
                                        <h4>Update completed</h4>
                                        <p>Restart server 12 to complete the update.</p>
                                        <p className="mt-1 rcv-time">30m ago</p>
                                    </a>
                                    <a href="#" className="list-group-item">
                                        <h4>Lorem ipsum</h4>
                                        <div className="text-dark"></div>
                                        <p>Aliquam ex eros, imperdiet vulputate hendrerit et.</p>
                                        <p className="mt-1 rcv-time">2h ago</p>
                                    </a>
                                </div>
                                <div className="dropdown-menu-footer">
                                    <a href="#" className="text-muted">Show all notifications</a>
                                </div>
                            </div>
                        </li> */}
						<li>
							<div className="admin-lang">
								<select className="form-select" onChange={changeLanguage} value={langSeleted}>
									{Object.keys(languageMap)?.map((item) => {
										return (
											<option value={item} key={item}>
												{languageMap[item].label}
											</option>
										);
									})}
								</select>
								<span className="admin-lang-globe">
									<img className="img-block" src={globe} alt="" />
								</span>
							</div>
						</li>
						<li className="nav-item dropdown custom-dropdown">
							<a className="nav-icon dropdown-toggle d-inline-block d-sm-none" href="#" data-bs-toggle="dropdown">
								<img src={auth.avatar} className="avatar me-1" alt={auth.name} />
							</a>
							<a className="nav-link dropdown-toggle d-none d-sm-inline-block carat-icon" href="#" data-bs-toggle="dropdown">
								<img src={auth.avatar} className="avatar me-1" alt={auth.name} />
								<span className="text-dark">
									{/* {auth.name} */}
									{auth.name.length > 15 ? auth.name.substring(0, 15) + '...' : auth.name}
									<i className="fas fa-angle-down"></i>
								</span>
							</a>
							<div className="dropdown-menu">
								{/* https://freefrontend.com/css-hamburger-menu-icons/ */}
								<Link className="dropdown-item" to="/admin/profile">
									<i className="fas fa-user"></i> Profile
								</Link>
								{/* <a className="dropdown-item" href="#"><i className="far fa-chart-bar"></i> Analytics</a> */}
								{/* <div className="dropdown-divider" /> */}
								{/* <a className="dropdown-item" href="index.html"><i className="fas fa-cog"></i> Settings &amp; Privacy</a> */}
								{/* <a className="dropdown-item" href="#"><i className="far fa-question-circle"></i> Help Center</a> */}
								<div className="dropdown-divider" />
								<Link className="dropdown-item" to="#" onClick={logOut}>
									<i className="fas fa-sign-out-alt"></i> Log out
								</Link>
							</div>
						</li>
					</ul>
				</div>
			</nav>
		</>
	);
}

export default Header;
