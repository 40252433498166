import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import Breadcrumb from 'components/Admin/Breadcrumb';
import Alert from 'components/Alert';
import { toast } from 'react-toastify';
import { addRequestFormService, listRequestType } from './AddRequestForm.service.js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function AddRequestForm() {
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const { t } = useTranslation();
	const lang = useSelector((state) => state.lang);
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const [attachFile, setRequestAttachFile] = useState(null);
	const [requestTypeList, SetRequestTypeList] = useState([]);
	useEffect(() => {
		getRequestType();
	}, []);

	const getRequestType = async () => {
		let result = await listRequestType();
		console.log(result);
		if (result.data.status === 200) {
			let request_types = result.data.data.request_types;
			SetRequestTypeList(request_types);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const onSubmit = async (data) => {
		let formData = new FormData();

		formData.append('request_type', data.request_type);
		formData.append('subject', data.subject);
		formData.append('subject_ar', data.subject_ar);
		formData.append('subject_fr', data.subject_fr);
		formData.append('subject_fr', data.subject_fr);
		formData.append('description', data.description);
		formData.append('description_fr', data.description_fr);
		formData.append('description_ar', data.description_ar);
		formData.append('attach_file', attachFile);

		const result = await addRequestFormService(formData);
		if (result.data.status === 200) {
			toast.success(`${t('request_form.request_form_added_successfully')} !`);
			reset();
		} else {
			toast.error(result.data.error);
		}
		reset();
	};
	const handleFile = async (e) => {
		const file = e.target.files[0];
		setRequestAttachFile(file);

		if (file.size > 1000000) {
			SetAlertType('error');
			SetAlertMessage(t('request_form.image_size_is_than_1mb'));
			return false;
		}
		setRequestAttachFile(file);
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('request_form.request_form') },
					{ link: '#', name: t('request_form.add_request_form') },
				]}
				title={t('request_form.add_request_form')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}

			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-12">
							<label className="admin-lbl">{t('request_form.subject')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('request_form.subject')}
									{...register('subject', { required: t('request_form.subject_is_required') })}
								/>
								{errors.subject && <InputErrorMsg error={errors.subject?.message} />}
							</div>
						</div>
						<div className="col-sm-6 col-12">
							<label className="admin-lbl">{t('request_form.request_type')}</label>
							<select
								className="form-select"
								{...register('request_type', {
									required: t('request_form.request_type_is_required'),
								})}
							>
								<option value="">{t('request_form.select_request_type')}</option>
								{requestTypeList?.map((value, index) => {
									return (
										<option key={index} value={value.id}>
											{value.name?.[lang]}
										</option>
									);
								})}
							</select>
							{errors.request_type && <InputErrorMsg error={errors.request_type?.message} />}
						</div>
						<div className="col-sm-6 col-12">
							<div className="mb-3">
								<label htmlFor="formFile" className="form-label admin-lbl">
									{t('request_form.attach_file')}
								</label>
								<input className="form-control" type="file" id="formFile" onChange={handleFile} />
							</div>
						</div>
					</div>
					<div className="row g-3 mt-0">
						<div className="col-12">
							<label className="admin-lbl">{t('about_content.description')}</label>
							<div className="admim-input-wrap">
								<textarea
									className="form-control resize-none"
									placeholder={t('about_content.description')}
									rows="5"
									{...register('description', {
										required: t('about_content.title_is_required'),
									})}
								></textarea>
								{errors.description && <InputErrorMsg error={errors.description?.message} />}
							</div>
						</div>
						<div className="col-12 cmn-btn-tag-wrap mt-3">
							<button className="cmn-btn-tag">{t('payout_order.submit')}</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default AddRequestForm;
