const addToCart = (productId = []) => {
	return {
		type: 'ADD_TO_CART',
		productId,
	};
};
const removeFromCart = (productId = []) => {
	return {
		type: 'REMOVE_FROM_CART',
		productId,
	};
};

const emptyCart = () => {
	return {
		type: 'EMPTY_CART',
	};
};
const data = {
	addToCart,
	removeFromCart,
	emptyCart,
};

export default data;
