import React, { useEffect, useState } from 'react';
import Breadcrumb from 'components/Admin/Breadcrumb';
import Alert from 'components/Alert';
import DataTable from 'react-data-table-component';
import { listOrder, clearPayment } from './PayoutOrderList.service.js';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function PayoutOrderList() {
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [orders, setOrders] = useState([]);
	const [orderMeta, setOrderMeta] = useState({});
	const [page, setPage] = useState(1);
	const [receiptImg, setReceiptImg] = useState('');
	const [orderProductId, setOrderProductId] = useState(0);
	const { t } = useTranslation();

	useEffect(() => {
		getOrderList();
	}, [page]);

	const getOrderList = async () => {
		let list = await listOrder(page);
		console.log(list);
		if (list.data.status === 200) {
			setOrders(list.data.data.records);
			setOrderMeta(list.data.data.meta);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};

	const handlePayment = async (order_id) => {
		setReceiptImg('');
		setOrderProductId(order_id);
	};

	const handleFile = async (e) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			toast.error(t('about_content.image_size_less_200_kb'));
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setReceiptImg(img);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};
	const uploadPaymentReceipt = async () => {
		if (!receiptImg) {
			toast.error(t('payout_order.please_upload_the_reciept'));
			return false;
		}
		const order_product_id = orderProductId;
		const image = receiptImg;
		const formData = {
			order_product_id,
			image,
		};
		let result = await clearPayment(formData);
		if (result.data.status === 200) {
			await getOrderList();
			document.querySelector('.btn-close').click();
			toast.success(`${t('payout_order.payement_cleared_successfully')} !`);
		} else if (result.data.status === 401) {
			toast.error(`${t('payout_order.unauthorized_action')} !`);
		} else {
			toast.error(result.data.error);
		}
	};
	const columns = [
		{
			name: t('admin_order.order_id'),
			selector: (row) => <div className="order-id-text">{row?.get_order?.auto_gen_id}</div>,
			sortable: true,
		},
		{
			name: t('admin_order.order_date'),
			selector: (row) => moment(row?.created_at).format('DD-MM-YYYY'),
			sortable: true,
		},
		{
			name: t('payout_order.order_by'),
			selector: (row) => row?.get_order?.get_shipping_detail?.first_name + ' ' + row?.get_order?.get_shipping_detail?.last_name,
			sortable: true,
		},
		{
			name: t('payout_order.product_image'),
			cell: (row) => (
				<span className="list-table-img">
					<img className="img-block" src={row?.get_vendor_product?.image} alt="Product" />
				</span>
			),
		},
		{
			name: t('payout_order.product_name'),
			selector: (row) => row?.get_vendor_product?.name?.en,
			sortable: true,
		},
		{
			name: t('payout_order.price_mru'),
			selector: (row) => row?.price,
			sortable: true,
		},
		{
			name: t('payout_order.quantity'),
			selector: (row) => row?.quantity + ` (${row?.get_vendor_product?.get_measure_unit?.name?.en})`,
			sortable: true,
		},
		{
			name: t('payout_order.total_amount_mru'),
			selector: (row) => row?.quantity * row?.price,
			sortable: true,
		},
		{
			name: t('payout_order.payout_status'),
			selector: (row) => {
				if (row.payout_status === 0) {
					return <span className="badge bg-warning text-dark">{t('payout_order.not_requested')}</span>;
				}
				if (row.payout_status === 1) {
					return <span className="badge bg-primary">{t('payout_order.requested')}</span>;
				}
				if (row.payout_status === 2) {
					return <span className="badge bg-success">{t('payout_order.payment_cleared')}</span>;
				}
				if (row.payout_status === 3) {
					return <span className="badge bg-success">{t('payout_order.confirmed')}</span>;
				}
			},
			sortable: false,
		},
		{
			name: t('payout_order.action'),
			cell: (row) => (
				<div className="dropdown">
					<div className="actionList " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg
							style={{ cursor: 'pointer' }}
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-sliders dropdown-toggle"
							data-toggle="dropdown"
							role="button"
							aria-expanded="false"
						>
							<line x1={4} y1={21} x2={4} y2={14} />
							<line x1={4} y1={10} x2={4} y2={3} />
							<line x1={12} y1={21} x2={12} y2={12} />
							<line x1={12} y1={8} x2={12} y2={3} />
							<line x1={20} y1={21} x2={20} y2={16} />
							<line x1={20} y1={12} x2={20} y2={3} />
							<line x1={1} y1={14} x2={7} y2={14} />
							<line x1={9} y1={8} x2={15} y2={8} />
							<line x1={17} y1={16} x2={23} y2={16} />
						</svg>
					</div>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						{row?.payout_status === 1 && (
							<Link
								to="#"
								className="dropdown-item"
								data-bs-toggle="modal"
								data-bs-target="#paymentModal"
								onClick={() => {
									handlePayment(row.id);
								}}
							>
								{t('payout_order.clear_payment')}
							</Link>
						)}
					</div>
				</div>
			),
		},
	];

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('admin_order.order') },
					{ link: '#', name: t('admin_user.list') },
				]}
				title={t('payout_order.orders')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="datatable-wrap">
				<DataTable
					columns={columns}
					data={orders}
					striped
					pagination
					paginationServer
					paginationTotalRows={orderMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					onChangePage={(page) => setPage(page)}
				/>
			</div>

			<div
				className="modal fade cmn-modal"
				id="paymentModal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="paymentModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="paymentModalLabel">
								{t('payout_order.payment_receipt')}
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-12">
									<div className="product-img-wrap">
										<div className="product-img-upload">
											<input type="file" id="receiptImg" onChange={handleFile} />
											<label htmlFor="receiptImg">
												{!receiptImg && (
													<div className="product-upload-text">
														<i className="fa fa-download"></i>
														<p>{`${t('payout_order.upload_receipt')}...`}</p>
													</div>
												)}
												{receiptImg && (
													<div className="preview-img">
														<img src={receiptImg} className="img-block" alt="product" />
													</div>
												)}
											</label>
										</div>
									</div>
								</div>
								<div className="col-12 cmn-btn-tag-wrap">
									<button className="cmn-btn-tag" type="button" onClick={uploadPaymentReceipt}>
										{t('payout_order.submit')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default PayoutOrderList;
