import React from 'react';
import './Alert.scss';

function Alert(props) {
	return (
		<>
			{props.type === 'error' && (
				<>
					<div className="alert-box">
						<div className="alert alert-danger alert-dismissible fade show" role="alert">
							<i className="fas fa-exclamation-triangle"></i>
							{props.message}
							<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
						</div>
					</div>
				</>
			)}
			{props.type === 'success' && (
				<>
					<div className="alert-box">
						<div className="alert alert-success alert-dismissible fade show" role="alert">
							<i className="fas fa-check-circle"></i>
							{props.message}
							<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
						</div>
					</div>
				</>
			)}
			{props.type === 'info' && (
				<>
					<div className="alert-box">
						<div className="alert alert-info alert-dismissible fade show" role="alert">
							<i className="fas fa-info-circle"></i>
							{props.message}
							<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
						</div>
					</div>
				</>
			)}
		</>
	);
}

export default Alert;
