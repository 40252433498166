import axios from 'axios';

const auth_token = localStorage.getItem('auth_token');
const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL + '/admin',
	timeout: 15000,
	headers: { Authorization: 'Bearer ' + auth_token },
});

export const getAuthUser = async (data = {}) => {
	try {
		let result = await api.get('/get-auth-user', data);
		return result;
	} catch (error) {
		return error;
	}
};
