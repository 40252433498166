import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.scss';
import notfoungBg from 'assets/images/404bg.jpg';
import { useTranslation } from 'react-i18next';
function NotFound() {
	const { t } = useTranslation();
	return (
		<>
			<section className="not-found-wrap d-flex align-items-center justify-content-center" style={{ background: `url(${notfoungBg}) center center no-repeat` }}>
				<div className="eror-404-text">
					<h3>404</h3>
					<p>{t('not_found_page.nothing_to_see_here_try_another_link')}</p>
					<Link to="/" className="error-bkhome">
						{t('not_found_page.back_to_home')}
					</Link>
				</div>
			</section>
		</>
	);
}

export default NotFound;
