import { default as api } from 'config/adminApiConf.js';

export const addCategory = async (data = {}) => {
	try {
		let result = await api.post('/fund/create-category', data);
		return result;
	} catch (error) {
		return error;
	}
};

export const catList = async (page) => {
	try {
		let result = await api.get('/fund/list-category?page=' + page);
		return result;
	} catch (error) {
		return error;
	}
};

export const categoryDtl = async (id) => {
	try {
		let result = await api.get(`/fund/category-detail/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const editCategory = async (id, data = {}) => {
	try {
		let result = await api.put(`/fund/edit-category/${id}`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const delCategory = async (id) => {
	try {
		let result = await api.delete(`/fund/delete-category/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};
