import { default as api } from 'config/adminApiConf.js';

export const dashboardDtlService = async (data = {}) => {
	try {
		let result = await api.get('/get-dashboard-dtl');
		return result;
	} catch (error) {
		return error;
	}
};
