import React from 'react';
import './DataNotFound.scss';
function DataNotFound(props) {
	return (
		<>
			<div className="col-12">
				<div className="not-found-text">
					<i className="fas fa-exclamation-triangle"></i>
					<p>{props.text}</p>
				</div>
			</div>
		</>
	);
}

export default DataNotFound;
