import React from 'react';
import './ValuePreposition.scss';
import './ValuePreposition.responsive.scss';
import our_value from 'assets/images/our-value.jpg';
import chakra from 'assets/images/chakra.png';
import market from 'assets/images/market.png';
import market1 from 'assets/images/market1.png';
import link from 'assets/images/link.png';
import control_system from 'assets/images/control-system.png';
import analysis from 'assets/images/analysis.png';
import funding from 'assets/images/funding.png';
import innovation from 'assets/images/innovation.png';
import { useTranslation } from 'react-i18next';
function ValuePreposition() {
	const { t } = useTranslation();
	return (
		<>
			<section className="our-value-sec our-valuebg" style={{ background: `url(${our_value}) no-repeat center bottom` }}>
				<div className="container-fluid left-right-40">
					<div className="row">
						<div className="col-12">
							<div className="sec-heading mb-3">
								<h3 className="text-white">{t('our_value_preposition')}</h3>
							</div>
						</div>
					</div>
					<div className="value-proces for-mobile">
						<div className="value-box d-flex align-items-center">
							<div className="value-box-icon">
								<span className="value-icon">
									<img className="img-block" src={market} alt />
								</span>
							</div>
							<div className="value-box-text gap-lft">
								<p>{t('inclusive_access_to_market')}</p>
							</div>
						</div>
						<div className="value-box d-flex align-items-center">
							<div className="value-box-icon">
								<span className="value-icon">
									<img className="img-block" src={market1} alt />
								</span>
							</div>
							<div className="value-box-text gap-lft">
								<p>{t('digital_agri_market_mauritania')}</p>
							</div>
						</div>
						<div className="value-box d-flex align-items-center">
							<div className="value-box-icon">
								<span className="value-icon">
									<img className="img-block" src={link} alt />
								</span>
							</div>
							<div className="value-box-text gap-lft">
								<p>{t('reliable_value_chain')}</p>
							</div>
						</div>
						<div className="value-box d-flex align-items-center">
							<div className="value-box-icon">
								<span className="value-icon">
									<img className="img-block" src={control_system} alt />
								</span>
							</div>
							<div className="value-box-text gap-lft">
								<p>{t('quality_control')}</p>
							</div>
						</div>
						<div className="value-box d-flex align-items-center">
							<div className="value-box-icon">
								<span className="value-icon">
									<img className="img-block" src={analysis} alt />
								</span>
							</div>
							<div className="value-box-text gap-lft">
								<p>{t('market_insight')}</p>
							</div>
						</div>
						<div className="value-box d-flex align-items-center">
							<div className="value-box-icon">
								<span className="value-icon">
									<img className="img-block" src={funding} alt />
								</span>
							</div>
							<div className="value-box-text gap-lft">
								<p>{t('funding_opportunities')}</p>
							</div>
						</div>
						<div className="value-box d-flex align-items-center">
							<div className="value-box-icon">
								<span className="value-icon">
									<img className="img-block" src={innovation} alt />
								</span>
							</div>
							<div className="value-box-text gap-lft">
								<p>{t('innovative_technology')}</p>
							</div>
						</div>
					</div>
					<div className="value-proces for-dsk" dir="ltr">
						<img className="img-block" src={chakra} alt />
						<div className="value-box d-flex align-items-center first">
							<div className="value-box-text text-end gap-rgt">
								<p>{t('inclusive_access_to_market')}</p>
							</div>
							<div className="value-box-icon">
								<span className="value-icon">
									<img className="img-block" src={market} alt />
								</span>
							</div>
						</div>
						<div className="value-box d-flex align-items-center second">
							<div className="value-box-text text-end gap-rgt">
								<p>{t('digital_agri_market_mauritania')}</p>
							</div>
							<div className="value-box-icon">
								<span className="value-icon">
									<img className="img-block" src={market1} alt />
								</span>
							</div>
						</div>
						<div className="value-box d-flex align-items-center third">
							<div className="value-box-text text-end gap-rgt">
								<p>{t('reliable_value_chain')}</p>
							</div>
							<div className="value-box-icon">
								<span className="value-icon">
									<img className="img-block" src={link} alt />
								</span>
							</div>
						</div>
						<div className="value-box fourth">
							<div className="value-box-icon">
								<span className="value-icon">
									<img className="img-block" src={control_system} alt />
								</span>
							</div>
							<div className="value-box-text text-start gap-top">
								<p>{t('quality_control')}</p>
							</div>
						</div>
						<div className="value-box d-flex align-items-center fifth">
							<div className="value-box-icon">
								<span className="value-icon">
									<img className="img-block" src={analysis} alt />
								</span>
							</div>
							<div className="value-box-text gap-lft">
								<p>{t('market_insight')}</p>
							</div>
						</div>
						<div className="value-box d-flex align-items-center sixth">
							<div className="value-box-icon">
								<span className="value-icon">
									<img className="img-block" src={funding} alt />
								</span>
							</div>
							<div className="value-box-text gap-lft">
								<p>{t('funding_opportunities')}</p>
							</div>
						</div>
						<div className="value-box d-flex align-items-center seventh">
							<div className="value-box-icon">
								<span className="value-icon">
									<img className="img-block" src={innovation} alt />
								</span>
							</div>
							<div className="value-box-text gap-lft">
								<p>{t('innovative_technology')}</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default ValuePreposition;
