import React from 'react';
import './Platform.scss';
import './Platform.responsive.scss';
import hasebg from 'assets/images/hasedbg.svg';
import registration from 'assets/images/hassad-platform/registration.svg';
import add_user from 'assets/images/hassad-platform/add-user.svg';
import discount from 'assets/images/hassad-platform/discount.svg';
import dollar from 'assets/images/hassad-platform/dollar.svg';
import crop from 'assets/images/hassad-platform/crop.svg';
import wallet from 'assets/images/hassad-platform/wallet.svg';
import logistics from 'assets/images/hassad-platform/logistics.svg';
import mail from 'assets/images/hassad-platform/mail.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { EqualHeight, EqualHeightElement } from 'react-equal-height';
function Platform() {
	const { t } = useTranslation();
	const homeContent = useSelector((state) => state?.content?.home);
	const lang = useSelector((state) => state?.lang);
	return (
		<>
			<section className="hased-platform-sec hasd-bg" style={{ background: `url(${hasebg}) no-repeat center bottom` }} id="how_to_use_platfrom">
				<div className="container-fluid left-right-40">
					<div className="row">
						<div className="col-12">
							<div className="sec-heading mb-3">
								<h3>{t('how_to_use_hassad_platform')}</h3>

								<span
									dangerouslySetInnerHTML={{
										__html: homeContent?.how_to_use_platform?.[lang] ?? t('hassad_digital_platform'),
									}}
								/>
							</div>
						</div>
					</div>
					<div className="step-arrow-shape">
						<EqualHeight>
							<ul className="d-flex flex-wrap justify-content-center">
								<li>
									<span className="step-srl">
										<h4>1</h4>
									</span>
									<span className="step-arrow-img">
										<img className="img-block" src={registration} alt />
									</span>
									<EqualHeightElement name="steparrowText">
										<p>{t('register_online_or_visit')} </p>
									</EqualHeightElement>
								</li>
								<li>
									<span className="step-srl">
										<h4>2</h4>
									</span>
									<span className="step-arrow-img">
										<img className="img-block" src={add_user} alt />
									</span>
									<EqualHeightElement name="steparrowText">
										<p>{t('sign_up_to_platform')}</p>
									</EqualHeightElement>
								</li>
								<li>
									<span className="step-srl">
										<h4>3</h4>
									</span>
									<span className="step-arrow-img">
										<img className="img-block" src={discount} alt />
									</span>
									<EqualHeightElement name="steparrowText">
										<p>{t('create_your_offer')}</p>
									</EqualHeightElement>
								</li>
								<li>
									<span className="step-srl">
										<h4>4</h4>
									</span>
									<span className="step-arrow-img">
										<img className="img-block" src={dollar} alt />
									</span>
									<EqualHeightElement name="steparrowText">
										<p>{t('set_a_qty')}</p>
									</EqualHeightElement>
								</li>
								<li>
									<span className="step-srl">
										<h4>5</h4>
									</span>
									<span className="step-arrow-img">
										<img className="img-block" src={crop} alt />
									</span>
									<EqualHeightElement name="steparrowText">
										<p>{t('provide_us_with_crop')}</p>
									</EqualHeightElement>
								</li>
								<li>
									<span className="step-srl">
										<h4>6</h4>
									</span>
									<span className="step-arrow-img">
										<img className="img-block" src={wallet} alt />
									</span>
									<EqualHeightElement name="steparrowText">
										<p>{t('decide_upon_your_preferred')}</p>
									</EqualHeightElement>
								</li>
								<li>
									<span className="step-srl">
										<h4>7</h4>
									</span>
									<span className="step-arrow-img">
										<img className="img-block" src={logistics} alt />
									</span>
									<EqualHeightElement name="steparrowText">
										<p>{t('descide_upon_logistics')}</p>
									</EqualHeightElement>
								</li>
								<li>
									<span className="step-srl">
										<h4>8</h4>
									</span>
									<span className="step-arrow-img">
										<img className="img-block" src={discount} alt />
									</span>
									<EqualHeightElement name="steparrowText">
										<p>{t('post_your_seller_offer')}</p>
									</EqualHeightElement>
								</li>
								<li>
									<span className="step-srl">
										<h4>9</h4>
									</span>
									<span className="step-arrow-img">
										<img className="img-block" src={mail} alt />
									</span>
									<EqualHeightElement name="steparrowText">
										<p>{t('stay_updated_by_email')}</p>
									</EqualHeightElement>
								</li>
							</ul>
						</EqualHeight>
					</div>
				</div>
			</section>
		</>
	);
}

export default Platform;
