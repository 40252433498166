import { default as api } from 'config/adminApiConf.js';

export const editEventService = async (id, data) => {
	try {
		let result = await api.put(`/event/edit/${id}`, data);
		return result;
	} catch (error) {
		return error;
	}
};
export const eventDetailsService = async (id) => {
	try {
		let result = await api.get(`/event/details/${id}`);
		console.log(result);
		return result;
	} catch (error) {
		return error;
	}
};
export const listEventType = async (data) => {
	try {
		let result = await api.get(`/event-type`, data);
		return result;
	} catch (error) {
		return error;
	}
};
