import { default as api } from 'config/adminApiConf.js';

export const subCatList = async (cat_id) => {
	try {
		let result = await api.get(`/products/subcat-list/${cat_id}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const catList = async () => {
	try {
		let result = await api.get('/products/cat-list');
		return result;
	} catch (error) {
		return error;
	}
};

export const addProductRequest = async (data = {}) => {
	try {
		let result = await api.post('/products/vendor-product/add-product-request', data);
		return result;
	} catch (error) {
		return error;
	}
};
