import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import Footer from 'components/Footer';
import HomeBanner from 'components/HomeBanner';
import Platform from 'components/Platform';
import ValuePreposition from 'components/ValuePreposition';
import Partner from 'components/Partner';
import WhoCanUse from 'components/WhoCanUse';
import ContactUs from 'components/ContactUs';
import Quotes from 'components/Quotes';

import './Home.scss';
import { useDispatch, useSelector } from 'react-redux';
import allActions from 'redux/actions';
import { getContentService } from './Home.service.js';
import { toast } from 'react-toastify';

function Home() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const homeContent = useSelector((state) => state?.content?.home);
	useEffect(() => {
		getContent();
	}, []);

	const getContent = async () => {
		dispatch(allActions.loadingAction.showLoading());
		const result = await getContentService();
		if (result.data.status === 200) {
			dispatch(allActions.contentAction.setHomeContent(result?.data?.data?.content?.content));
		} else {
			toast.error(result.data.error);
		}
		dispatch(allActions.loadingAction.hideLoading());
	};
	return (
		<>
			<Header />
			<HomeBanner />
			<Platform />
			<ValuePreposition />
			<WhoCanUse />
			<Quotes />
			<ContactUs />
			<Partner />
			<Footer />
		</>
	);
}

export default Home;
