import React from 'react';
import './ProfileImage.scss';

function ProfileImage({ src = null, firstName = null, lastName = null }) {
	return (
		<>
			<div className="profile-pic-wrap">
				{src ? (
					<span className="profile-pic">
						<img src={src} alt={firstName.charAt(0)} />
					</span>
				) : (
					<span className="name-ltr">
						{firstName.charAt(0).toUpperCase()}
						{lastName.charAt(0).toUpperCase()}
					</span>
				)}
			</div>
		</>
	);
}

export default ProfileImage;
