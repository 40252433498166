import { default as api } from 'config/adminApiConf.js';

export const listOrder = async (page) => {
	try {
		let result = await api.get(`/payout/get-order-list?page=${page}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const clearPayment = async (data) => {
	try {
		let result = await api.post(`/payout/clear-payment`, data);
		return result;
	} catch (error) {
		return error;
	}
};
