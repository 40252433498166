import { default as commonApi } from 'config/commonApiConf';

export const getCartService = async () => {
	try {
		let result = await commonApi.get('/front/customer/get-cart');
		return result;
	} catch (error) {
		return error;
	}
};

export const cartQtyUpdateService = async (data) => {
	try {
		let result = await commonApi.post('/front/customer/update-cart-qty', data);
		return result;
	} catch (error) {
		return error;
	}
};
export const removeFromCartService = async (data) => {
	try {
		let result = await commonApi.post('/front/customer/remove-from-cart', data);
		return result;
	} catch (error) {
		return error;
	}
};
