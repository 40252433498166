import counter from './counter';
import lang from './language';
import auth from './auth';
import collapseSidebar from './sidebar.js';
import loading from './loading';
import cart from './cart';
import content from './content';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
	counter,
	lang,
	collapseSidebar,
	auth,
	loading,
	cart,
	content,
});

export default rootReducer;
