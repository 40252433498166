import { default as api } from 'config/frontApiConf';
import { default as commonApi } from 'config/commonApiConf';

export const CatListService = async () => {
	try {
		let result = await api.get(`/category-list`);
		return result;
	} catch (error) {
		return error;
	}
};

export const productListService = async (cat = 0, sub_cat = 0) => {
	try {
		let result = await api.get(`/product-list?cat=${cat}&sub_cat=${sub_cat}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const productSearchService = async (data = {}, page = 1) => {
	try {
		let result = await api.post(`/product-search?page=${page}`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const loginService = async (data) => {
	try {
		let result = await commonApi.post(`/auth/login`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const productDtlService = async (id) => {
	try {
		let result = await api.get(`/product-detail/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const createOrderService = async (data) => {
	try {
		let result = await api.post(`/customer/create-order`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const addToCartService = async (data) => {
	try {
		let result = await api.post(`/customer/add-to-cart`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const getUserDtlService = async (id) => {
	try {
		let result = await api.get(`/customer/get-user-dtl/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const getRegionListService = async (id) => {
	try {
		let result = await api.get(`/region-list`);
		return result;
	} catch (error) {
		return error;
	}
};
