import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import './AdminLayout.scss';
import './AdminLayout.responsive.scss';
import Sidebar from 'components/Admin/Sidebar';
import Header from 'components/Admin/Header';
import Footer from 'components/Admin/Footer';
import { getAuthUser } from './AdminLayout.service.js';
import allActions from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import NoImage from 'assets/images/avatar.png';

function DashboardLayout() {
	const [redirect, setRedirect] = useState(0);
	const lang = useSelector((state) => state.lang);
	const dispatch = useDispatch();
	useEffect(() => {
		const auth_token = localStorage.getItem('auth_token');
		if (!auth_token) {
			setRedirect(1);
		}
		getAuthUser().then((res) => {
			if (res.data.status === 200) {
				const user = res.data.data.user;
				if (user.role !== 1 && user.role !== 2 && user.role !== 4 && user.role !== 5 && user.role !== 6 && user.role !== 7 && user.role !== 8) {
					setRedirect(1);
				}
				dispatch(
					allActions.authAction.setAuthUser({
						id: user.id,
						role: user.role,
						avatar: user.get_user_details?.avatar ? user.get_user_details.avatar : NoImage,
						name: user.first_name + ' ' + user.last_name,
					}),
				);
			} else {
				setRedirect(1);
			}
		});
		console.log('lang', lang);
	}, []);

	if (redirect) {
		return <Navigate to="/" replace />;
	}
	return (
		<>
			<div className="wrapper">
				<Sidebar />
				<div className="main">
					<Header />
					<main className="content">
						<div className="container-fluid p-0">
							<Outlet />
						</div>
					</main>
					<Footer />
				</div>
			</div>
		</>
	);
}

export default DashboardLayout;
