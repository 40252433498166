import React, { useEffect, useState } from 'react';
import Breadcrumb from 'components/Admin/Breadcrumb';
import Alert from 'components/Alert';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import {
	listOrder,
	getDeliveryAgentService,
	assignDeliveryAgentService,
	getAssignedDeliveryService,
	confirmDeliveryChargeService,
	getPaymentReceiptService,
	confirmPaymentService,
} from './AdminOrderList.service.js';

import moment from 'moment';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import OrderDetail from 'components/OrderDetail';
import Select from 'react-select';
import InputErrorMsg from 'components/InputErrorMsg';
import './AdminOrderList.scss';

function AdminOrderList() {
	const {
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const [orders, setOrders] = useState([]);
	const [orderMeta, setOrderMeta] = useState({});
	const [page, setPage] = useState(1);
	const { t } = useTranslation();
	const [orderId, setOrderId] = useState(0);
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [deliveryAgentList, setDeliveryAgentList] = useState([]);
	const [assignDeliveryAgent, setAssignDeliveryAgent] = useState([]);
	const [paymentReceipt, setPaymentReceipt] = useState({});

	useEffect(() => {
		getOrderList();
	}, [page]);

	const getAssignedDeliveryAgent = async (orderId) => {
		const formData = {
			order_id: orderId,
		};
		let list = await getAssignedDeliveryService(formData);
		console.log(list);
		if (list.data.status === 200) {
			console.log(list.data.data.asigned_delivery_services);
			setAssignDeliveryAgent(list.data.data.asigned_delivery_services);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};
	const getOrderList = async () => {
		let list = await listOrder(page);
		if (list.data.status === 200) {
			setOrders(list.data.data.records);
			setOrderMeta(list.data.data.meta);
		} else if (list.data.status === 401) {
			window.location = '/';
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};
	const getDeliveryService = async (id, orderId) => {
		let result = await getDeliveryAgentService(id);

		if (result.data.status === 200) {
			let delivery_agent = result.data.data.delivery_agent.map((res, i) => {
				return { value: res.id, label: res.first_name + ' ' + res.last_name };
			});
			setDeliveryAgentList(delivery_agent);
			setOrderId(orderId);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};
	const onSubmit = async (data) => {
		let formData = { deliver_agent: data.delivery_agent.map((res) => res.value), order_id: orderId };

		let result = await assignDeliveryAgentService(formData);
		if (result.data.status === 200) {
			await getOrderList();
			document.querySelector('.btn-close').click();
			reset();
			toast.success(`${t('admin_complete_order.delivery_service_assigned')} !`);
		} else {
			document.querySelector('.btn-close').click();
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const confirmShippingCharge = async (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-confirmation-box">
						<h1>{t('admin_user.are_you_sure')}</h1>
						<p>{`${t('admin_order.you_want_to_accept_shipping_charge')}`}</p>
						<button onClick={onClose} className="no-btn">
							{t('admin_user.no')}
						</button>
						<button
							className="yes-btn"
							onClick={async () => {
								let formData = {
									order_id: id,
								};
								let result = await confirmDeliveryChargeService(formData);
								if (result.data.status === 200) {
									await getOrderList();

									toast.success(`${t('admin_order.shipping_charge_confirmed')} !`);
								} else {
									toast.error(result.data.error);
									window.scrollTo(0, 0);
								}
								onClose();
							}}
						>
							{t('admin_user.yes')}
						</button>
					</div>
				);
			},
		});
	};

	const showPaymentReceipt = async (id) => {
		setOrderId(id);
		let result = await getPaymentReceiptService(id);
		console.log('result:', result);

		if (result.data.status === 200) {
			console.log('payment receipt ', result.data.data.payment_receipt);
			setPaymentReceipt(result.data.data.payment_receipt);
		}
	};

	const confirmPayment = async () => {
		let formData = {
			order_id: orderId,
		};
		let result = await confirmPaymentService(formData);
		if (result.data.status === 200) {
			await getOrderList();
			document.querySelector('#confirmPaymentModalClose').click();
			toast.success(`${t('admin_order.payment_confirmed')} !`);
		} else {
			toast.error(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const columns = [
		{
			name: t('admin_order.order_id'),
			selector: (row) => <div className="order-id-text">{row?.auto_gen_id}</div>,
			sortable: true,
		},
		{
			name: t('admin_order.order_date'),
			selector: (row) => moment(row?.created_at).format('DD-MM-YYYY'),
			sortable: true,
		},
		{
			name: t('admin_order.shipping_charge_mru'),
			selector: (row) => row?.shipping_charge,
			sortable: true,
		},
		{
			name: t('admin_order.total_amount_mru'),
			selector: (row) => row?.total_amount + row?.shipping_charge,
			sortable: true,
		},
		{
			name: t('admin_order.action'),
			cell: (row) => (
				<div className="dropdown">
					<div className="actionList" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg
							style={{ cursor: 'pointer' }}
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-sliders dropdown-toggle"
							data-toggle="dropdown"
							role="button"
							aria-expanded="false"
						>
							<line x1={4} y1={21} x2={4} y2={14} />
							<line x1={4} y1={10} x2={4} y2={3} />
							<line x1={12} y1={21} x2={12} y2={12} />
							<line x1={12} y1={8} x2={12} y2={3} />
							<line x1={20} y1={21} x2={20} y2={16} />
							<line x1={20} y1={12} x2={20} y2={3} />
							<line x1={1} y1={14} x2={7} y2={14} />
							<line x1={9} y1={8} x2={15} y2={8} />
							<line x1={17} y1={16} x2={23} y2={16} />
						</svg>
					</div>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						{row?.status === 0 && (
							<>
								<Link
									to="#"
									className="dropdown-item"
									data-bs-toggle="modal"
									data-bs-target="#deliveryServiceAssignModal"
									onClick={() => {
										getDeliveryService(row?.get_shipping_detail?.region, row?.id);
									}}
								>
									{t('admin_complete_order.assign_delivery_agent')}
								</Link>
							</>
						)}
						{row?.status === 2 && (
							<>
								<Link
									to="#"
									className="dropdown-item"
									onClick={() => {
										confirmShippingCharge(row?.id);
									}}
								>
									{t('admin_complete_order.confirm_shipping_charge')}
								</Link>
							</>
						)}
						{row?.status === 4 && (
							<>
								<Link
									to="#"
									className="dropdown-item"
									data-bs-toggle="modal"
									data-bs-target="#confirmPaymentModal"
									onClick={() => {
										showPaymentReceipt(row?.id);
									}}
								>
									{t('donation_list.confirm_payment')}
								</Link>
							</>
						)}
					</div>
				</div>
			),
		},
	];

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('admin_order.order') },
					{ link: '#', name: t('admin_order.list') },
				]}
				title={t('admin_order.order')}
				refresh={true}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="datatable-wrap">
				<DataTable
					columns={columns}
					data={orders}
					striped
					pagination
					paginationServer
					paginationTotalRows={orderMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					expandableRows
					expandableRowsComponent={OrderDetail}
					onChangePage={(page) => setPage(page)}
				/>
			</div>

			<div
				className="modal fade cmn-modal"
				id="deliveryServiceAssignModal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="deliveryServiceAssignModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="deliveryServiceAssignModalLabel">
								{t('admin_complete_order.assign_delivery_service')}
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<div className="row">
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="col-12">
										<label className="admin-lbl">{t('admin_complete_order.delivery_service')}</label>
										<div className="admim-select-wrap">
											<Controller
												name="delivery_agent"
												control={control}
												render={({ field }) => (
													<Select
														noOptionsMessage={() => t('admin_user_type.no_delivery_agent_available_in_that_region')}
														isMulti
														{...field}
														options={deliveryAgentList}
													/>
												)}
												rules={{ required: t('admin_complete_order.delivery_agent_is_required') }}
											/>
											{errors.delivery_agent && <InputErrorMsg error={errors.delivery_agent?.message} />}
										</div>
									</div>
									<div className="col-12">
										<div className="cmn-btn-tag-wrap">
											<button type="submit" className="cmn-btn-tag">
												{t('admin_complete_order.assign')}
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				className="modal fade cmn-modal"
				id="deliveryChargeModal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="deliveryChargeModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="deliveryChargeModalLabel">
								{t('admin_complete_order.confirm_delivery_charge')}
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="table-responsive">
									<table className="table">
										<thead>
											<tr>
												<th>{t('admin_complete_order.delivery_service_name')}</th>
												<th>{t('admin_complete_order.shipping_charge')}</th>
												<th>{t('admin_user.status')}</th>
												<th>{t('admin_user.action')}</th>
											</tr>
										</thead>
										<tbody>
											{assignDeliveryAgent.map((res, i) => {
												return (
													<tr key={i}>
														<td>
															{res?.get_delivery_agent?.first_name} {res?.get_delivery_agent?.last_name}
														</td>
														<td>{res?.shipping_charge ?? 0}</td>
														<td>
															{res.status === 0 ? (
																<span className="badge bg-warning">{t('admin_my_donation.pending')}</span>
															) : res.status === 1 ? (
																<span className="badge bg-primary">{t('admin_complete_order.charge_added')}</span>
															) : (
																''
															)}
														</td>
														<td>{res.status === 1 && <button className="btn btn primary">{t('admin_complete_order.accept')}</button>}</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				className="modal fade cmn-modal"
				id="confirmPaymentModal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="confirmPaymentModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="confirmPaymentModalLabel">
								{t('donation_list.confirm_payment')}
							</h5>
							<button type="button" className="btn-close" id="confirmPaymentModalClose" data-bs-dismiss="modal" aria-label="Close">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<div className="payment-receipt">
								<span className="payment-receipt-img">
									<img className="img-block" src={paymentReceipt?.image} alt="payment reciept" />
								</span>
								<span className="payment-btn-wrap cmn-btn-tag-wrap">
									<button type="button" className="cmn-btn-tag" onClick={confirmPayment}>
										{t('donation_list.confirm_payment')}
									</button>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default AdminOrderList;
