const showLoading = () => {
	return {
		type: 'SHOW_LOADING',
	};
};

const hideLoading = () => {
	return { type: 'HIDE_LOADING' };
};

const data = {
	showLoading,
	hideLoading,
};

export default data;
