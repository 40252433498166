import React, { useEffect, useState } from 'react';
import Breadcrumb from 'components/Admin/Breadcrumb';
import './Dashboard.scss';
import { dashboardDtlService } from './Dashboard.service.js';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Dashboard() {
	const [dashboardDtl, setDashboardDtl] = useState([]);
	const auth = useSelector((state) => state.auth);
	const { t } = useTranslation();

	useEffect(() => {
		getDashboardDtl();
	}, []);

	const getDashboardDtl = async () => {
		const result = await dashboardDtlService();
		if (result.data.status === 200) {
			setDashboardDtl(result.data.data);
		} else {
			toast.error(result.data.error);
		}
	};

	return (
		<>
			<Breadcrumb data={[{ link: '#', name: t('admin_dashboard.dashboard') }]} title={t('admin_dashboard.dashboard')} />
			<div className="row g-3">
				{auth.role === 2 && (
					<>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/vendor-product/list'} className="text-green">
													{t('admin_dashboard.total_product')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-truck" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_products']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/vendor-order/list'} className="text-green">
													{t('admin_dashboard.total_product_sale')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-box-open" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_product_sale']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/vendor-order/list'} className="text-green">
													{t('admin_dashboard.total_sale_amount')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-box-open" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_sale_amount']} MRU</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/fund-raiser/list-fund'} className="text-green">
													{t('admin_dashboard.total_raise_fund')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-hand-holding-usd" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_fund_raise']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'#'} className="text-green">
													{t('admin_dashboard.total_donation')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-hand-holding-usd" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_donation']} MRU</h1>
								</div>
							</div>
						</div>
					</>
				)}

				{auth.role === 1 && (
					<>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'#'} className="text-green">
													{t('admin_dashboard.total_product')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-truck" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_products']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/order/list'} className="text-green">
													{t('admin_dashboard.total_product_sale')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-box-open" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_product_sale']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/order/list'} className="text-green">
													{t('admin_dashboard.total_sale_amount')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-box-open" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_sale_amount']} MRU</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/users'} className="text-green">
													{t('admin_dashboard.total_customer')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-users" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_customer']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/users'} className="text-green">
													{t('admin_dashboard.total_vendor')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-users" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_vendor']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/users'} className="text-green">
													{t('admin_dashboard.total_fund_raiser')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-users" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_fund_raiser']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/fund/raise-fund-list'} className="text-green">
													{t('admin_dashboard.total_raise_fund')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-hand-holding-usd" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_fund_raise']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/fund/donaiton-list'} className="text-green">
													{t('admin_dashboard.total_donation')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-hand-holding-usd" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_donation']} MRU</h1>
								</div>
							</div>
						</div>
					</>
				)}
				{auth.role === 6 && (
					<>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/fund-raiser/list-fund'} className="text-green">
													{t('admin_dashboard.total_raise_fund')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-hand-holding-usd" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_fund_raise']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'#'} className="text-green">
													{t('admin_dashboard.total_donation')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-hand-holding-usd" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_donation']} MRU</h1>
								</div>
							</div>
						</div>
					</>
				)}

				{auth.role === 4 && (
					<>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/delivery-order/list'} className="text-green">
													{t('admin_dashboard.total_order_assigned')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-hands-helping" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_order_assigned']}</h1>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-4 col-sm-4 col-12">
							<div className="card box-wrap">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">
												<Link to={'/admin/delivery-order/list'} className="text-green">
													{t('admin_dashboard.total_shipping_amount')}
												</Link>
											</h5>
										</div>
										<div className="col-auto">
											<div className="stat text-green">
												<i className="fas fa-hand-holding-usd" />
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{dashboardDtl?.['total_shipping_amount']} MRU</h1>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default Dashboard;
