import { default as api } from 'config/adminApiConf.js';

export const editForumService = async (id, data) => {
	try {
		let result = await api.put(`/forum/edit/${id}`, data);
		return result;
	} catch (error) {
		return error;
	}
};
export const forumDetailsService = async (id) => {
	try {
		let result = await api.get(`/forum/details/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};
