import React, { useEffect, useState } from 'react';
import Breadcrumb from 'components/Admin/Breadcrumb';
import Alert from 'components/Alert';
import { listProductRequest } from './ProductRequest.service';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ProductRequest() {
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [products, setProducts] = useState([]);
	const [productMeta, setProductMeta] = useState({});
	const [page, setPage] = useState(1);
	const { t } = useTranslation();

	useEffect(() => {
		getProductList();
	}, [page]);

	const getProductList = async () => {
		let list = await listProductRequest(page);
		if (list.data.status === 200) {
			setProducts(list.data.data.records);
			setProductMeta(list.data.data.meta);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};

	const columns = [
		{
			name: t('admin_request_product.image'),
			cell: (row) => (
				<span className="list-table-img">
					<img className="img-block" src={row.image} alt="Product" />
				</span>
			),
		},
		{
			name: t('admin_request_product.category'),
			selector: (row) => row?.get_category?.name_lang?.en,
			sortable: true,
		},
		{
			name: t('admin_request_product.sub_category'),
			selector: (row) => row?.get_sub_category?.name_lang?.en,
			sortable: true,
		},
		{
			name: t('admin_request_product.product_type_name'),
			selector: (row) => row.name?.en,
			sortable: true,
		},
		{
			name: t('admin_request_product.status'),
			selector: (row) => {
				if (row.status === 0) {
					return <span className="badge bg-warning text-dark">{t('admin_my_donation.pending')}</span>;
				}
				if (row.status === 1) {
					return <span className="badge bg-success">{t('admin_my_donation.accepted')}</span>;
				}
				if (row.status === 2) {
					return <span className="badge bg-danger">{t('vendor_product.rejected')}</span>;
				}
			},
		},
	];

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('admin_dashboard_menu.product_type') },
					{ link: '#', name: t('admin_user.list') },
				]}
				title={t('admin_dashboard_menu.product_type')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="cmn-a-tag-wrap mb-3">
				<Link to={`/admin/vendor-product-req/add`} className="cmn-a-tag">
					{t('admin_product_type.add_product_type')}
				</Link>
			</div>
			<div className="datatable-wrap">
				<DataTable
					columns={columns}
					data={products}
					striped
					pagination
					paginationServer
					paginationTotalRows={productMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					onChangePage={(page) => setPage(page)}
				/>
			</div>
		</>
	);
}

export default ProductRequest;
