import React, { useEffect, useState } from 'react';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import { addContentService, getContentService } from './DataObvervatoryContent.service.js';
import { useTranslation } from 'react-i18next';

function DataObvervatoryContent() {
	const {
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'blog',
	});
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [blogImg, setBlogImg] = useState([]);
	const [content, setContent] = useState({});
	const [reRender, setReRender] = useState(0);
	const { t } = useTranslation();

	useEffect(() => {
		getContent();
	}, []);

	useEffect(() => {
		setDefaultValueForm();
	}, [content]);

	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.description = content?.description?.en;
		defaultValues.description_fr = content?.description?.fr;
		defaultValues.description_ar = content?.description?.ar;
		defaultValues.title = content?.title?.en;
		defaultValues.title_fr = content?.title?.fr;
		defaultValues.title_ar = content?.title?.ar;
		const bcontent = [];
		const arr = content?.blog?.map((val, i) => {
			bcontent.push({
				description: val?.description?.en,
				description_fr: val?.description?.fr,
				description_ar: val?.description?.ar,
			});
			return val?.img;
		});
		defaultValues.blog = bcontent;
		console.log('aarr', arr);
		await reset({ ...defaultValues });
		if (arr) {
			setBlogImg([...arr]);
		}
	};

	const handleBlogImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			toast.error(t('about_content.image_size_less_200_kb'));
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			blogImg[index] = img;
			setBlogImg([...blogImg]);
			setReRender(!reRender);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};
	const removeBlogItem = async (index) => {
		remove(index);
		setBlogImg(blogImg.filter((val, i) => i !== index));
	};
	const onSubmit = async (data) => {
		if (data?.blog?.length !== blogImg?.length) {
			toast.error(t('about_content.please_select_all_image'));
			return false;
		}
		data?.blog.map((val, i) => {
			val['img'] = blogImg[i];
		});
		let formData = { ...data };
		console.log(formData);
		const result = await addContentService(formData);
		if (result.data.status === 200) {
			toast.success(`${t('about_content.content_saved_successfully')} !`);
		} else {
			toast.error(result.data.error);
		}
	};
	const getContent = async () => {
		const result = await getContentService();
		if (result.data.status === 200) {
			setContent(result?.data?.data?.content?.content);
		} else {
			toast.error(result.data.error);
		}
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('about_content.content_management') },
					{ link: '#', name: t('data_observatory_content.data_observatory') },
				]}
				title={t('data_observatory_content.data_observatory_content_text')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-12">
							<div className="cmn-panel-wrap">
								<div className="card">
									<div className="card-header bg-card-headar">
										<div className="quotes-list-head">
											<h4>{t('data_observatory_content.right_panel_content')}</h4>
										</div>
									</div>
									<div className="card-body cmn-panel-box">
										{fields.map((item, index) => (
											<>
												<div className="card" key={index}>
													<div className="card-header">
														<div className="row justify-content-between align-items-center">
															<div className="col-auto">
																<div className="list-head">{t('about_content.item')}</div>
															</div>
															<div className="col-auto">
																<div className="quotes-delete">
																	<button type="button" className="cmn-btn-tag" onClick={() => removeBlogItem(index)}>
																		<i className="fas fa-trash-alt"></i>
																	</button>
																</div>
															</div>
														</div>
													</div>
													<div className="card-body">
														<div className="row justify-content-center">
															<div className="col-lg-3 col-md-4 col-sm-4 col-12">
																<div className="description-img-box">
																	<div className="description-img">
																		<input type="file" id={`content_img${index}`} onChange={(e) => handleBlogImg(e, index)} />
																		<label htmlFor={`content_img${index}`}>
																			{!blogImg?.[index] && (
																				<div className="product-upload-text">
																					<i className="fa fa-download"></i>
																					<p>{`${t('admin_product_type.upload_image')} ...`}</p>
																				</div>
																			)}
																			{blogImg?.[index] && (
																				<div className="preview-img">
																					<img src={blogImg?.[index]} className="img-block" alt="product" />
																				</div>
																			)}
																		</label>
																	</div>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																<label className="admin-lbl">{`${t('about_content.description')}:`}</label>
																<div className="admim-input-wrap">
																	<Controller
																		name={`blog[${index}].description`}
																		control={control}
																		render={({ field: { onChange, onBlur, value, ref } }) => (
																			<CKEditor
																				editor={ClassicEditor}
																				data={content?.blog?.[index]?.description?.en}
																				onChange={(event, editor) => {
																					onChange(editor.getData());
																				}}
																			/>
																		)}
																		rules={{ required: t('about_content.description_is_required') }}
																	/>
																	{errors?.blog?.[index]?.description && <InputErrorMsg error={errors?.blog?.[index]?.description?.message} />}
																</div>
															</div>
															<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																<label className="admin-lbl">{`${t('about_content.Description_fr')}:`}</label>
																<div className="admim-input-wrap">
																	<Controller
																		name={`blog[${index}].description_fr`}
																		control={control}
																		render={({ field: { onChange, onBlur, value, ref } }) => (
																			<CKEditor
																				editor={ClassicEditor}
																				data={content?.blog?.[index]?.description?.fr}
																				onChange={(event, editor) => {
																					onChange(editor.getData());
																				}}
																			/>
																		)}
																		rules={{ required: t('about_content.description_fr_is_required') }}
																	/>
																	{errors?.blog?.[index]?.description_fr && (
																		<InputErrorMsg error={errors?.blog?.[index]?.description_fr?.message} />
																	)}
																</div>
															</div>
															<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																<label className="admin-lbl">{`${t('about_content.Description_ar')}:`}</label>
																<div className="admim-input-wrap">
																	<Controller
																		name={`blog[${index}].description_ar`}
																		control={control}
																		render={({ field: { onChange, onBlur, value, ref } }) => (
																			<CKEditor
																				editor={ClassicEditor}
																				data={content?.blog?.[index]?.description?.ar}
																				onChange={(event, editor) => {
																					onChange(editor.getData());
																				}}
																			/>
																		)}
																		rules={{ required: t('about_content.description_ar_is_required') }}
																	/>
																	{errors?.blog?.[index]?.description_ar && (
																		<InputErrorMsg error={errors?.blog?.[index]?.description_ar?.message} />
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
											</>
										))}

										<div className="cmn-btn-tag-wrap">
											<button
												type="button"
												className="cmn-btn-tag black-bg"
												onClick={() => append({ description: '', description_fr: '', description_ar: '', img: '' })}
											>
												{t('about_content.add_more_item')}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="cmn-btn-tag-wrap">
								<button type="submit" className="cmn-btn-tag">
									{t('admin_user.save')}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default DataObvervatoryContent;
