import { default as api } from 'config/adminApiConf.js';

export const ProductList = async () => {
	try {
		let result = await api.get(`/products/vendor-product/product-list`);
		return result;
	} catch (error) {
		return error;
	}
};

export const RegionList = async () => {
	try {
		let result = await api.get(`/products/vendor-product/region-list`);
		return result;
	} catch (error) {
		return error;
	}
};
export const ProductEdit = async (id, data) => {
	try {
		let result = await api.put(`/products/vendor-product/edit/${id}`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const Productdetail = async (id) => {
	try {
		let result = await api.get(`/products/vendor-product/detail/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};
export const MeasureUnitListService = async () => {
	try {
		let result = await api.get(`/measure-unit-list`);
		return result;
	} catch (error) {
		return error;
	}
};
