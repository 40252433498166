const increment = (by = 1) => {
	return {
		type: 'INCREMENT',
		by: by,
	};
};

const decrement = (by = 1) => {
	return {
		type: 'DECREMENT',
		by: by,
	};
};

const data = {
	increment,
	decrement,
};
export default data;
