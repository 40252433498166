import React, { useEffect, useState } from 'react';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import { addContentService, getContentService } from './HomeContent.service.js';
import { useTranslation } from 'react-i18next';

function HomeContent() {
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const [bannerImg, setBannerImg] = useState([]);
	const [TestiImg, setTestiImg] = useState([]);
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'banner',
	});
	const {
		fields: testiFields,
		append: testiAppend,
		remove: testiRemove,
	} = useFieldArray({
		control,
		name: 'testimonial',
	});
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [content, setContent] = useState({});
	const [reRender, setReRender] = useState(0);

	useEffect(() => {
		getContent();
	}, []);

	useEffect(() => {
		setDefaultValueForm();
	}, [content]);

	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.email = content?.contact_info?.email;
		defaultValues.ph_no = content?.contact_info?.ph_no;
		defaultValues.address = content?.contact_info?.address?.en;
		defaultValues.address_fr = content?.contact_info?.address?.fr;
		defaultValues.address_ar = content?.contact_info?.address?.ar;
		defaultValues.how_to_use_platform = content?.how_to_use_platform?.en;
		defaultValues.how_to_use_platform_fr = content?.how_to_use_platform?.fr;
		defaultValues.how_to_use_platform_ar = content?.how_to_use_platform?.ar;
		const testiArr = [];
		const tImage = content?.testimonial?.map((val, i) => {
			testiArr.push({
				description: val?.description?.en,
				description_fr: val?.description?.fr,
				description_ar: val?.description?.ar,
				name: val?.name,
			});
			return val?.img;
		});
		defaultValues.testimonial = testiArr;

		const bannerArr = [];
		const bImage = content?.banner?.map((val, i) => {
			bannerArr.push({
				description: val?.description?.en,
				description_fr: val?.description?.fr,
				description_ar: val?.description?.ar,
			});
			return val?.img;
		});
		defaultValues.banner = bannerArr;

		await reset({ ...defaultValues });

		if (bImage) {
			setBannerImg(bImage);
		}
		if (tImage) {
			setTestiImg(tImage);
		}
	};

	const getContent = async () => {
		const result = await getContentService();
		if (result.data.status === 200) {
			setContent(result?.data?.data?.content?.content);
			console.log(result?.data?.data?.content?.content);
		} else {
			toast.error(result.data.error);
		}
	};

	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};

	const handleBannerImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			toast.error(t('about_content.image_size_less_200_kb'));
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			bannerImg[index] = img;
			setBannerImg([...bannerImg]);
			setReRender(!reRender);
		}
	};

	const removeBannerItem = async (index) => {
		remove(index);
		setBannerImg(bannerImg.filter((val, i) => i !== index));
	};

	const handleTestiImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			toast.error(t('about_content.image_size_less_200_kb'));
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			TestiImg[index] = img;
			setTestiImg([...TestiImg]);
			setReRender(!reRender);
		}
	};

	const removeTestiItem = async (index) => {
		testiRemove(index);
		setTestiImg(TestiImg.filter((val, i) => i !== index));
	};
	const onSubmit = async (data) => {
		if (data?.banner?.length !== bannerImg?.length || data?.testimonial?.length !== TestiImg?.length) {
			toast.error(t('about_content.please_select_all_image'));
			return false;
		}
		data?.banner.map((val, i) => {
			val['img'] = bannerImg[i];
		});
		data?.testimonial.map((val, i) => {
			val['img'] = TestiImg[i];
		});
		let formData = { ...data };
		console.log(formData);
		const result = await addContentService(formData);
		if (result.data.status === 200) {
			toast.success(`${t('about_content.content_saved_successfully')} !`);
		} else {
			toast.error(result.data.error);
		}
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('about_content.content_management') },
					{ link: '#', name: t('home_content_page.home') },
				]}
				title={t('home_content_page.home_content')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="admin-white-box">
				<div className="row">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="col-12">
							<div className="cmn-panel-wrap">
								<div className="card">
									<div className="card-header bg-card-headar">
										<div className="quotes-list-head">
											<h4>{t('home_content_page.banner')}</h4>
										</div>
									</div>
									<div className="card-body cmn-panel-box">
										{fields.map((item, index) => (
											<>
												<div className="card" key={index}>
													<div className="card-header">
														<div className="row justify-content-between align-items-center">
															<div className="col-auto">
																<div className="list-head">{t('about_content.item')}</div>
															</div>
															<div className="col-auto">
																<div className="quotes-delete">
																	<button type="button" className="cmn-btn-tag" onClick={() => removeBannerItem(index)}>
																		<i className="fas fa-trash-alt"></i>
																	</button>
																</div>
															</div>
														</div>
													</div>
													<div className="card-body">
														<div className="row justify-content-center">
															<div className="col-lg-3 col-md-4 col-sm-4 col-12">
																<div className="description-img-box">
																	<div className="description-img">
																		<input type="file" id={`content_img${index}`} onChange={(e) => handleBannerImg(e, index)} />
																		<label htmlFor={`content_img${index}`}>
																			{!bannerImg?.[index] && (
																				<div className="product-upload-text">
																					<i className="fa fa-download"></i>
																					<p>{`${t('admin_product_type.upload_image')} ...`}</p>
																				</div>
																			)}
																			{bannerImg?.[index] && (
																				<div className="preview-img">
																					<img src={bannerImg?.[index]} className="img-block" alt="product" />
																				</div>
																			)}
																		</label>
																	</div>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																<label className="admin-lbl">{`${t('about_content.description')}:`}</label>
																<div className="admim-input-wrap">
																	<Controller
																		name={`banner[${index}].description`}
																		control={control}
																		render={({ field: { onChange, onBlur, value, ref } }) => (
																			<CKEditor
																				editor={ClassicEditor}
																				data={content?.banner?.[index]?.description?.en}
																				onChange={(event, editor) => {
																					onChange(editor.getData());
																				}}
																			/>
																		)}
																		rules={{ required: t('about_content.description_is_required') }}
																	/>
																	{errors?.banner?.[index]?.description && (
																		<InputErrorMsg error={errors?.banner?.[index]?.description?.message} />
																	)}
																</div>
															</div>
															<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																<label className="admin-lbl">{`${t('about_content.Description_fr')}:`}</label>
																<div className="admim-input-wrap">
																	<Controller
																		name={`banner[${index}].description_fr`}
																		control={control}
																		render={({ field: { onChange, onBlur, value, ref } }) => (
																			<CKEditor
																				editor={ClassicEditor}
																				data={content?.banner?.[index]?.description?.fr}
																				onChange={(event, editor) => {
																					onChange(editor.getData());
																				}}
																			/>
																		)}
																		rules={{ required: t('about_content.description_fr_is_required') }}
																	/>
																	{errors?.banner?.[index]?.description_fr && (
																		<InputErrorMsg error={errors?.banner?.[index]?.description_fr?.message} />
																	)}
																</div>
															</div>
															<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																<label className="admin-lbl">{`${t('about_content.Description_ar')}:`}</label>
																<div className="admim-input-wrap">
																	<Controller
																		name={`banner[${index}].description_ar`}
																		control={control}
																		render={({ field: { onChange, onBlur, value, ref } }) => (
																			<CKEditor
																				editor={ClassicEditor}
																				data={content?.banner?.[index]?.description?.ar}
																				onChange={(event, editor) => {
																					onChange(editor.getData());
																				}}
																			/>
																		)}
																		rules={{ required: t('about_content.description_ar_is_required') }}
																	/>
																	{errors?.banner?.[index]?.description_ar && (
																		<InputErrorMsg error={errors?.banner?.[index]?.description_ar?.message} />
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
											</>
										))}

										<div className="cmn-btn-tag-wrap">
											<button
												type="button"
												className="cmn-btn-tag black-bg"
												onClick={() => append({ description: '', description_fr: '', description_ar: '', img: '' })}
											>
												{t('about_content.add_more_item')}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="cmn-panel-wrap">
								<div className="card">
									<div className="card-header bg-card-headar">
										<div className="quotes-list-head">
											<h4>{t('how_to_use_hassad_platform')}</h4>
										</div>
									</div>
									<div className="card-body cmn-panel-box">
										<div className="row">
											<div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
												<label className="admin-lbl">{`${'How to use platform'}:`}</label>
												<div className="admim-input-wrap">
													<Controller
														name="how_to_use_platform"
														control={control}
														render={({ field: { onChange, onBlur, value, ref } }) => (
															<CKEditor
																editor={ClassicEditor}
																data={content?.how_to_use_platform?.en}
																onChange={(event, editor) => {
																	onChange(editor.getData());
																}}
															/>
														)}
														rules={{ required: 'How to use platform is required' }}
													/>
													{errors.how_to_use_platform && <InputErrorMsg error={errors.how_to_use_platform?.message} />}
												</div>
											</div>
											<div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
												<label className="admin-lbl">{`${'How to use platform (Fr)'}:`}</label>
												<div className="admim-input-wrap">
													<Controller
														name="how_to_use_platform_fr"
														control={control}
														render={({ field: { onChange, onBlur, value, ref } }) => (
															<CKEditor
																editor={ClassicEditor}
																data={content?.how_to_use_platform?.fr}
																onChange={(event, editor) => {
																	onChange(editor.getData());
																}}
															/>
														)}
														rules={{ required: 'How to use platform fr is required' }}
													/>
													{errors.how_to_use_platform_fr && <InputErrorMsg error={errors.how_to_use_platform_fr?.message} />}
												</div>
											</div>
											<div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
												<label className="admin-lbl">{`${'How to use platform (Ar)'}:`}</label>
												<div className="admim-input-wrap">
													<Controller
														name="how_to_use_platform_ar"
														control={control}
														render={({ field: { onChange, onBlur, value, ref } }) => (
															<CKEditor
																editor={ClassicEditor}
																data={content?.how_to_use_platform?.ar}
																onChange={(event, editor) => {
																	onChange(editor.getData());
																}}
															/>
														)}
														rules={{ required: 'How to use platform ar is required' }}
													/>
													{errors.how_to_use_platform_ar && <InputErrorMsg error={errors.how_to_use_platform_ar?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-12">
							<div className="cmn-panel-wrap">
								<div className="card">
									<div className="card-header bg-card-headar">
										<div className="quotes-list-head">
											<h4>{t('home_content_page.testimonial')}</h4>
										</div>
									</div>
									<div className="card-body cmn-panel-box">
										{testiFields.map((item, index) => (
											<>
												<div className="card" key={index}>
													<div className="card-header">
														<div className="row justify-content-between align-items-center">
															<div className="col-auto">
																<div className="list-head">{t('about_content.add_more_item')}</div>
															</div>
															<div className="col-auto">
																<div className="quotes-delete">
																	<button type="button" className="cmn-btn-tag" onClick={() => removeTestiItem(index)}>
																		<i className="fas fa-trash-alt"></i>
																	</button>
																</div>
															</div>
														</div>
													</div>
													<div className="card-body">
														<div className="row justify-content-center">
															<div className="col-lg-3 col-md-4 col-sm-4 col-12">
																<div className="description-img-box">
																	<div className="description-img">
																		<input type="file" id={`testi_img${index}`} onChange={(e) => handleTestiImg(e, index)} />
																		<label htmlFor={`testi_img${index}`}>
																			{!TestiImg?.[index] && (
																				<div className="product-upload-text">
																					<i className="fa fa-download"></i>
																					<p>{`${t('admin_product_type.upload_image')} ...`}</p>
																				</div>
																			)}
																			{TestiImg?.[index] && (
																				<div className="preview-img">
																					<img src={TestiImg?.[index]} className="img-block" alt="product" />
																				</div>
																			)}
																		</label>
																	</div>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-12">
																<div className="row">
																	<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																		<label className="admin-lbl">{t('home_content_page.name')}</label>
																		<div className="admim-input-wrap">
																			<input
																				type="text"
																				className="form-control admim-input-style"
																				placeholder={t('home_content_page.name')}
																				{...register(`testimonial[${index}].name`, { required: t('home_content_page.name_is_required') })}
																			/>
																			{errors?.testimonial?.[index]?.name && (
																				<InputErrorMsg error={errors?.testimonial?.[index]?.name?.message} />
																			)}
																		</div>
																	</div>
																</div>
															</div>

															<div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
																<label className="admin-lbl">{`${t('about_content.description')}:`}</label>
																<div className="admim-input-wrap">
																	<Controller
																		name={`testimonial[${index}].description`}
																		control={control}
																		render={({ field: { onChange, onBlur, value, ref } }) => (
																			<CKEditor
																				editor={ClassicEditor}
																				data={content?.testimonial?.[index]?.description?.en}
																				onChange={(event, editor) => {
																					onChange(editor.getData());
																				}}
																			/>
																		)}
																		rules={{ required: t('about_content.description_is_required') }}
																	/>
																	{errors?.testimonial?.[index]?.description && (
																		<InputErrorMsg error={errors?.testimonial?.[index]?.description?.message} />
																	)}
																</div>
															</div>
															<div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
																<label className="admin-lbl">{`${t('about_content.Description_fr')}:`}</label>
																<div className="admim-input-wrap">
																	<Controller
																		name={`testimonial[${index}].description_fr`}
																		control={control}
																		render={({ field: { onChange, onBlur, value, ref } }) => (
																			<CKEditor
																				editor={ClassicEditor}
																				data={content?.testimonial?.[index]?.description?.fr}
																				onChange={(event, editor) => {
																					onChange(editor.getData());
																				}}
																			/>
																		)}
																		rules={{ required: t('about_content.description_fr_is_required') }}
																	/>
																	{errors?.testimonial?.[index]?.description_fr && (
																		<InputErrorMsg error={errors?.testimonial?.[index]?.description_fr?.message} />
																	)}
																</div>
															</div>
															<div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
																<label className="admin-lbl">{`${t('about_content.Description_ar')}:`}</label>
																<div className="admim-input-wrap">
																	<Controller
																		name={`testimonial[${index}].description_ar`}
																		control={control}
																		render={({ field: { onChange, onBlur, value, ref } }) => (
																			<CKEditor
																				editor={ClassicEditor}
																				data={content?.testimonial?.[index]?.description?.ar}
																				onChange={(event, editor) => {
																					onChange(editor.getData());
																				}}
																			/>
																		)}
																		rules={{ required: t('about_content.description_ar_is_required') }}
																	/>
																	{errors?.testimonial?.[index]?.description_ar && (
																		<InputErrorMsg error={errors?.testimonial?.[index]?.description_ar?.message} />
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
											</>
										))}

										<div className="cmn-btn-tag-wrap">
											<button
												type="button"
												className="cmn-btn-tag black-bg"
												onClick={() => testiAppend({ description: '', description_fr: '', description_ar: '', img: '', name: '' })}
											>
												{t('about_content.add_more_item')}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-12">
							<div className="cmn-panel-wrap">
								<div className="card">
									<div className="card-header bg-card-headar">
										<div className="quotes-list-head">
											<h4>{t('home_content_page.contact_us')}</h4>
										</div>
									</div>
									<div className="card-body cmn-panel-box">
										<div className="row">
											<div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
												<label className="admin-lbl">{`${t('home_content_page.address')}:`}</label>
												<div className="admim-input-wrap">
													<Controller
														name="address"
														control={control}
														render={({ field: { onChange, onBlur, value, ref } }) => (
															<CKEditor
																editor={ClassicEditor}
																data={content?.contact_info?.address?.en}
																onChange={(event, editor) => {
																	onChange(editor.getData());
																}}
															/>
														)}
														rules={{ required: t('home_content_page.address_is_required') }}
													/>
													{errors.address && <InputErrorMsg error={errors.address?.message} />}
												</div>
											</div>
											<div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
												<label className="admin-lbl">{`${t('home_content_page.address_fr')}:`}</label>
												<div className="admim-input-wrap">
													<Controller
														name="address_fr"
														control={control}
														render={({ field: { onChange, onBlur, value, ref } }) => (
															<CKEditor
																editor={ClassicEditor}
																data={content?.contact_info?.address?.fr}
																onChange={(event, editor) => {
																	onChange(editor.getData());
																}}
															/>
														)}
														rules={{ required: t('home_content_page.address_fr_is_required') }}
													/>
													{errors.address_fr && <InputErrorMsg error={errors.address_fr?.message} />}
												</div>
											</div>
											<div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
												<label className="admin-lbl">{`${t('home_content_page.address_ar')}:`}</label>
												<div className="admim-input-wrap">
													<Controller
														name="address_ar"
														control={control}
														render={({ field: { onChange, onBlur, value, ref } }) => (
															<CKEditor
																editor={ClassicEditor}
																data={content?.contact_info?.address?.ar}
																onChange={(event, editor) => {
																	onChange(editor.getData());
																}}
															/>
														)}
														rules={{ required: t('home_content_page.address_ar_is_required') }}
													/>
													{errors.address_ar && <InputErrorMsg error={errors.address_ar?.message} />}
												</div>
											</div>
											<div className="col-lg-4 col-md-6 col-sm-12 col-12">
												<label className="admin-lbl">{t('home_content_page.email')}</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control admim-input-style"
														placeholder="Email"
														{...register('email', { required: t('fund_catagory.email_is_required') })}
													/>
													{errors.email && <InputErrorMsg error={errors.email?.message} />}
												</div>
											</div>
											<div className="col-lg-4 col-md-6 col-sm-12 col-12">
												<label className="admin-lbl">{`${t('home_content_page.phone_no')}.`}</label>
												<div className="admim-input-wrap">
													<input
														type="text"
														className="form-control admim-input-style"
														placeholder="Phone No."
														{...register('ph_no', { required: t('fund_catagory.phone_no_is_required') })}
													/>
													{errors.ph_no && <InputErrorMsg error={errors.ph_no?.message} />}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-12">
							<div className="cmn-btn-tag-wrap">
								<button type="submit" className="cmn-btn-tag">
									{t('admin_user.save')}
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default HomeContent;
