import { default as commonApi } from 'config/commonApiConf';

export const getCartService = async () => {
	try {
		let result = await commonApi.get('/front/customer/get-cart');
		return result;
	} catch (error) {
		return error;
	}
};

export const getCountryService = async () => {
	try {
		let result = await commonApi.get('/front/country-list');
		return result;
	} catch (error) {
		return error;
	}
};

export const getRegionService = async () => {
	try {
		let result = await commonApi.get('/front/region-list');
		return result;
	} catch (error) {
		return error;
	}
};
export const createOrderService = async (data) => {
	try {
		let result = await commonApi.post(`/front/customer/create-order`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const getUserDtlService = async () => {
	try {
		let result = await commonApi.get(`/front/customer/get-customer-dtl`);
		return result;
	} catch (error) {
		return error;
	}
};
