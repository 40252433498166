import { default as api } from 'config/adminApiConf.js';

export const ProductList = async () => {
	try {
		let result = await api.get(`/products/vendor-product/product-list`);
		return result;
	} catch (error) {
		return error;
	}
};

export const RegionList = async () => {
	try {
		let result = await api.get(`/products/vendor-product/region-list`);
		return result;
	} catch (error) {
		return error;
	}
};
export const MeasureUnitListService = async () => {
	try {
		let result = await api.get(`/measure-unit-list`);
		return result;
	} catch (error) {
		return error;
	}
};
export const ProductAdd = async (data) => {
	try {
		let result = await api.post(`/products/vendor-product/create`, data);
		return result;
	} catch (error) {
		return error;
	}
};
export const catList = async () => {
	try {
		let result = await api.get('/products/cat-list');
		return result;
	} catch (error) {
		return error;
	}
};
