import React from 'react';

import contact_home_banner from 'assets/images/contact-home-bnr.jpg';
import location from 'assets/images/location.png';
import phone_call from 'assets/images/phone-call.png';
import mail from 'assets/images/mail.png';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import { contactUsService } from './ContactUs.service.js';
import { toast } from 'react-toastify';
import allActions from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import './ContactUs.scss';
import './ContactUs.responsive.scss';
import { Link } from 'react-router-dom';

function ContactUs() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const homeContent = useSelector((state) => state?.content?.home);
	const lang = useSelector((state) => state?.lang);

	const onSubmit = async (data) => {
		dispatch(allActions.loadingAction.showLoading());
		let result = await contactUsService(data);
		dispatch(allActions.loadingAction.hideLoading());
		if (result.data.status === 200) {
			reset();
			toast.success('Thank you for your message !');
		} else {
			toast.error(result.data.error);
		}
	};

	return (
		<>
			<section className="sendus-massege-sec">
				<div className="sendus-massege-wrap d-flex flex-wrap">
					<div className="sendus-massege-lft">
						<div className="sendus-massege-lft-box">
							<div className="sendus-massege-head">
								<h4>{t('send_us_message')}</h4>
							</div>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="row">
									<div className="col-lg-6 col-md-6 col-sm-12 col-12">
										<div className="input-wrap">
											<input
												type="text"
												className="form-control input-underline"
												placeholder={t('your_name')}
												{...register('name', { required: 'Name is required' })}
											/>
											{errors.name && <InputErrorMsg error={errors.name?.message} />}
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-12 col-12">
										<div className="input-wrap">
											<input
												type="text"
												className="form-control input-underline"
												placeholder={t('phone_no')}
												{...register('ph_no', { required: 'Phone Number is required' })}
											/>
											{errors.ph_no && <InputErrorMsg error={errors.ph_no?.message} />}
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-12 col-12">
										<div className="input-wrap">
											<input
												type="text"
												className="form-control input-underline"
												placeholder={t('email_address')}
												{...register('email', { required: 'Email is required' })}
											/>
											{errors.email && <InputErrorMsg error={errors.email?.message} />}
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-12 col-12">
										<div className="input-wrap">
											<input
												type="text"
												className="form-control input-underline"
												placeholder={t('subject')}
												{...register('subject', { required: 'Subject is required' })}
											/>
											{errors.subject && <InputErrorMsg error={errors.subject?.message} />}
										</div>
									</div>
									<div className="col-12">
										<div className="txtare-wrap">
											<textarea
												type="text"
												className="form-control txtare-underline"
												placeholder={t('description')}
												defaultValue={''}
												{...register('message', { required: 'Description is required' })}
											/>
											{errors.message && <InputErrorMsg error={errors.message?.message} />}
										</div>
									</div>
									<div className="send-msg-btn-wrap">
										<button type="submit" className="send-msg-btn">
											{t('send_a_message')}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div
						className="sendus-massege-rgt d-flex align-items-center justify-content-center"
						style={{ background: `url(${contact_home_banner}) no-repeat center center` }}
					>
						<div className="sendus-massege-inner sendus-massege-head">
							<h4>{t('contact_us')}</h4>
							<ul className="home-contact">
								<li className="d-flex align-items-center">
									<span className="home-contact-mg">
										<img className="img-block" src={location} alt="" />
									</span>
									<span
										className="home-contact-desc"
										dangerouslySetInnerHTML={{
											__html:
												homeContent?.contact_info?.address?.[lang] ??
												"center d'innovation agrocole<br /> Institut supérieur d’enseignement technologique <br /> Mauritania",
										}}
									/>
								</li>
								<li>
									<Link to="#">
										<span className="home-contact-mg">
											<img className="img-block" src={phone_call} alt="" />
										</span>
										<span className="home-contact-desc">{homeContent?.contact_info?.ph_no ?? '+222 30 00 00'}</span>
									</Link>
								</li>
								<li>
									<Link to="#">
										<span className="home-contact-mg">
											<img className="img-block" src={mail} alt="" />
										</span>
										<span className="home-contact-desc">{homeContent?.contact_info?.email ?? 'email@email.com'}</span>
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default ContactUs;
