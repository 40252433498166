import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import allActions from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import inner_banner from 'assets/images/inner-bannar.jpg';
import './Checkout.scss';
import { getCartService, getCountryService, getRegionService, createOrderService, getUserDtlService } from './Checkout.service.js';
import { useForm } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { t } from 'i18next';

function Checkout() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const dispatch = useDispatch();
	const [cartData, setCartData] = useState([]);
	const [countryList, SetCountryList] = useState([]);
	const [regionList, SetRegionList] = useState([]);
	const [userDtl, setUserDtl] = useState({});

	const lang = useSelector((state) => state.lang);
	const auth = useSelector((state) => state.auth);

	const navigate = useNavigate();
	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());
		getUserDtl();
		getCountry();
		getRegion();
		getCart();
	}, []);

	useEffect(() => {
		setDefaultValueForm();
	}, [userDtl]);

	const getCart = async () => {
		let cart = await getCartService();
		if (cart.data.status === 200) {
			console.log('cart data : ', cart.data.data.cart);
			setCartData(cart.data.data.cart);
			setTimeout(() => {
				dispatch(allActions.loadingAction.hideLoading());
			}, 1000);
		}
	};
	const getCountry = async () => {
		let result = await getCountryService();
		console.log(result);
		if (result.data.status === 200) {
			let country = result.data.data.country;
			let list = country.map((res, i) => {
				return { value: res.id, label: res.name };
			});
			SetCountryList(list);
		}
	};
	const getRegion = async () => {
		let result = await getRegionService();
		console.log(result);
		if (result.data.status === 200) {
			let region = result.data.data.region;
			SetRegionList(region);
		}
	};
	const getUserDtl = async () => {
		let result = await getUserDtlService();
		if (result.data.status === 200) {
			setUserDtl(result.data.data.user);
		} else {
			toast.error(result.data.error);
			window.scrollTo(0, 0);
		}
	};
	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.first_name = userDtl?.first_name;
		defaultValues.last_name = userDtl?.last_name;
		defaultValues.email = userDtl?.email;
		defaultValues.company_name = userDtl?.get_user_details?.company_name;
		defaultValues.company_vat = userDtl?.get_user_details?.company_vat;
		defaultValues.ph_no = userDtl?.ph_no;
		defaultValues.address = userDtl?.get_user_details?.address;
		defaultValues.zip_code = userDtl?.get_user_details?.zip_code;
		defaultValues.city = userDtl?.get_user_details?.city;
		defaultValues.region = userDtl?.get_user_details?.region;
		defaultValues.country = { value: userDtl?.get_user_details?.get_country?.id, label: userDtl?.get_user_details?.get_country?.name };
		await reset({ ...defaultValues });
	};
	const onSubmit = async (data) => {
		dispatch(allActions.loadingAction.showLoading());
		let formData = { ...data };
		console.log('formdata : ', formData);

		let result = await createOrderService(formData);
		if (result.data.status === 200) {
			dispatch(allActions.cartAction.emptyCart());
			navigate('/order-success');
		} else {
			dispatch(allActions.loadingAction.hideLoading());
			toast.error(result.data.error);
			window.scrollTo(0, 0);
		}
	};
	var total = 0;
	return (
		<>
			<Header />
			<section className="inner-banner-sec d-flex align-items-center justify-content-center" style={{ background: `url(${inner_banner}) no-repeat` }}>
				<div className="inner-banner-head">
					<h3>{t('cart_page.checkout')}</h3>
				</div>
			</section>
			<section className="about-content-sec">
				<div className="container-fluid left-right-40">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-lg-8 col-md-8 col-sm-12 col-12">
								<div className="box-heading">{t('checkout_page.shipping_details')}</div>
								<div className="row">
									<div className="col-lg-6 col-md-6 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_user.first_name')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('admin_user.first_name')}
												name={t('checkout_page.first_name')}
												{...register('first_name', { required: t('add_user_data.first_name_is_required') })}
											/>
											{errors.first_name && <InputErrorMsg error={errors.first_name?.message} />}
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_user.last_name')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('admin_user.last_name')}
												name="last_name"
												{...register('last_name', { required: t('add_user_data.last_name_is_required') })}
											/>
											{errors.last_name && <InputErrorMsg error={errors.last_name?.message} />}
										</div>
									</div>

									<div className="col-lg-6 col-md-6 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_user.region')}</label>
										<div className="admim-select-wrap">
											<select
												className="form-control selectArrowBlk admim-select-style"
												{...register('region', { required: t('admin_dashboard_menu.region_is_required') })}
											>
												<option value="">{`--${t('funding_details.select')}--`}</option>
												{regionList?.map((value, index) => {
													return (
														<option key={index} value={value.id}>
															{value.name}
														</option>
													);
												})}
											</select>
											{errors.region && <InputErrorMsg error={errors.region?.message} />}
										</div>
									</div>

									<div className="col-lg-6 col-md-6 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_user.city')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('admin_user.city')}
												{...register('city', { required: t('checkout_page.city_is_required') })}
											/>
											{errors.city && <InputErrorMsg error={errors.city?.message} />}
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_user.email')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('checkout_page.enter_your_email')}
												{...register('email', {
													required: t('add_user_data.email_is_required'),
													pattern: {
														value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
														message: t('checkout_page.invalid_email_address'),
													},
												})}
											/>
											{errors.email && <InputErrorMsg error={errors.email?.message} />}
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_user.phone_number')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('admin_user.your_phone_number')}
												{...register('ph_no', { required: t('bank.phone_number_is_required') })}
											/>
											{errors.ph_no && <InputErrorMsg error={errors.ph_no?.message} />}
										</div>
									</div>
								</div>
								<div className="col-12">
									<label className="admin-lbl">{t('admin_user.address')}</label>
									<div className="admim-input-wrap">
										<input
											type="text"
											className="form-control admim-input-style"
											placeholder={t('admin_user.enter_your_address')}
											{...register('address', { required: t('home_content_page.address_is_required') })}
										/>
										{errors.address && <InputErrorMsg error={errors.address?.message} />}
									</div>
								</div>
								<div className="col-12">
									<label className="admin-lbl">{t('checkout_page.order_notes_optional')}</label>
									<div className="admim-txtare-wrap">
										<textarea
											className="form-control admim-txtare-style"
											placeholder={t('checkout_page.order_note')}
											rows="4"
											{...register('order_notes')}
										></textarea>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-12">
								<div className="sticky-od">
									<div className="box-style-wrap">
										<div className="box-heading">{t('admin_order.order_details')}</div>
										<div className="table-responsive od-details">
											<table className="table">
												<thead>
													<tr>
														<th>{t('admin_dashboard_menu.product')}</th>
														<th className="last-box">{t('admin_order.total')}</th>
													</tr>
												</thead>
												<tbody>
													{cartData.map((val, i) => {
														total += val?.get_vendor_product?.price * val?.qty;
														return (
															<tr key={i}>
																<td>
																	{val?.get_vendor_product?.name?.[lang]}{' '}
																	<strong>
																		{val?.qty} {val?.get_vendor_product?.get_measure_unit?.name?.[lang]}
																	</strong>
																</td>
																<td className="last-box">MRU {val?.get_vendor_product?.price * val?.qty}</td>
															</tr>
														);
													})}
												</tbody>
												<tfoot>
													<tr>
														<td>
															<strong>{t('admin_order.total')} :</strong>
														</td>
														<td className="last-box">
															<strong>MRU {total}</strong>
														</td>
													</tr>
												</tfoot>
											</table>
										</div>
									</div>
									<div className="box-style-wrap">
										<div className="box-heading">{t('checkout_page.payment_details')}</div>
										<div className="radio payment-radio">
											<input
												type="radio"
												id="payment1"
												value={'cash'}
												{...register('payment_method', { required: t('checkout_page.payment_method_is_required') })}
											/>
											<label htmlFor="payment1">{t('funding_details.cash')}</label>
										</div>
										<div className="radio payment-radio">
											<input
												type="radio"
												id="payment2"
												value={'bankili'}
												{...register('payment_method', { required: t('checkout_page.payment_method_is_required') })}
											/>
											<label htmlFor="payment2">{t('funding_details.bankili')}</label>
										</div>
										<div className="radio payment-radio">
											<input
												type="radio"
												id="payment3"
												value={'masrivi'}
												{...register('payment_method', { required: t('checkout_page.payment_method_is_required') })}
											/>
											<label htmlFor="payment3">{t('funding_details.masrivi')}</label>
										</div>
										<div className="radio payment-radio">
											<input
												type="radio"
												id="payment4"
												value={'saddad'}
												{...register('payment_method', { required: t('checkout_page.payment_method_is_required') })}
											/>
											<label htmlFor="payment4">{t('funding_details.saddad')}</label>
										</div>
										{errors.payment_method && <InputErrorMsg error={errors.payment_method?.message} />}
										<div className="box-heading mt-2">{t('checkout_page.for_online_payment')}</div>
										<p>
											{t('checkout_page.use_this_phone_numner')} : <strong className="color-green">43083217</strong>
										</p>
										<button type="submit" className="checkout-btn mt-2 border-0">
											{t('checkout_page.place_order')}
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Checkout;
