import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { Link } from 'react-router-dom';
import Alert from 'components/Alert';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { listRaiseFundService, requestPayoutService, getPaymentReceiptService, confirmPaymentService } from './ListFundRaise.service.js';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function ListFundRaise() {
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [listFund, setListFund] = useState([]);
	const [listFundMeta, setListFundMeta] = useState({});
	const [page, setPage] = useState(1);
	const { t } = useTranslation();

	const [paymentReceipt, setPaymentReceipt] = useState({});
	const [raiseFundId, setRaiseFundId] = useState(0);
	const lang = useSelector((state) => state.lang);

	useEffect(() => {
		getFundRaiseList();
	}, []);

	const getFundRaiseList = async () => {
		let result = await listRaiseFundService(page);

		if (result.data.status === 200) {
			setListFund(result.data.data.records);
			setListFundMeta(result.data.data.meta);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}
		window.scrollTo(0, 0);
	};

	const requestPayout = (raiseFundId) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-confirmation-box">
						<h1>{t('admin_user.are_you_sure')}</h1>
						<p>{t('admin_user.you_want_to_delete_this')}</p>
						<button onClick={onClose} className="no-btn">
							{t('admin_user.no')}
						</button>
						<button
							className="yes-btn"
							onClick={async () => {
								let formdata = {
									raise_fund_id: raiseFundId,
								};

								let result = await requestPayoutService(formdata);
								if (result.data.status === 200) {
									await getFundRaiseList();
									toast.success(`{${t('fund_raise.payout_requested')}} !`);
								} else {
									toast.error(result.data.error);
									window.scrollTo(0, 0);
								}
								onClose();
							}}
						>
							{t('admin_user.yes')}
						</button>
					</div>
				);
			},
		});
	};

	const showPaymentReceipt = async (id) => {
		setRaiseFundId(id);
		let result = await getPaymentReceiptService(id);

		if (result.data.status === 200) {
			console.log('payment receipt ', result.data.data.payment_receipt);
			setPaymentReceipt(result.data.data.payment_receipt);
		}
	};

	const confirmPayment = async () => {
		let formData = {
			raise_fund_id: raiseFundId,
		};
		let result = await confirmPaymentService(formData);
		if (result.data.status === 200) {
			await getFundRaiseList();
			document.querySelector('#confirmPaymentModalClose').click();
			toast.success(`{${t('admin_order.payment_confirmed')}} !`);
		} else {
			toast.error(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const columns = [
		{
			name: t('fund_raise.campaign_title'),
			selector: (row) => row.campaign_title?.[lang],
			sortable: true,
		},
		{
			name: t('fund_raise.created_date'),
			selector: (row) => moment(row.created_at).format('DD-MM-YYYY'),
			sortable: true,
		},
		{
			name: t('fund_raise.end_date'),
			selector: (row) => moment(row.campaign_end_date).format('DD-MM-YYYY'),
			sortable: true,
		},
		{
			name: t('fund_raise.budget_needed_mru'),
			selector: (row) => row.budget_needed,
			sortable: true,
		},
		{
			name: t('fund_raise.total_fund_raised_mru'),
			selector: (row) => row.total_fund,
			sortable: true,
		},
		{
			name: t('admin_user.status'),
			selector: (row) => {
				let status = '';
				if (row.status === 0) {
					status = <span class="badge bg-danger">{t('admin_user.inactive')}</span>;
				}
				if (row.status === 1) {
					status = <span class="badge bg-success">{t('admin_user.active')}</span>;
				}
				return status;
			},
			sortable: true,
		},
		{
			name: t('payout_order.payout_status'),
			selector: (row) => {
				if (row.payout_status === 0) {
					return <span className="badge bg-warning text-dark">{t('payout_order.not_requested')}</span>;
				}
				if (row.payout_status === 1) {
					return <span className="badge bg-primary">{t('payout_order.requested')}</span>;
				}
				if (row.payout_status === 2) {
					return <span className="badge bg-success">{t('payout_order.payment_cleared')}</span>;
				}
				if (row.payout_status === 3) {
					return <span className="badge bg-success">{t('payout_order.confirmed')}</span>;
				}
			},
			sortable: false,
		},
		{
			name: t('payout_order.action'),
			cell: (row) => (
				<div className="dropdown">
					<div className="actionList " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg
							style={{ cursor: 'pointer' }}
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-sliders dropdown-toggle"
							data-toggle="dropdown"
							role="button"
							aria-expanded="false"
						>
							<line x1={4} y1={21} x2={4} y2={14} />
							<line x1={4} y1={10} x2={4} y2={3} />
							<line x1={12} y1={21} x2={12} y2={12} />
							<line x1={12} y1={8} x2={12} y2={3} />
							<line x1={20} y1={21} x2={20} y2={16} />
							<line x1={20} y1={12} x2={20} y2={3} />
							<line x1={1} y1={14} x2={7} y2={14} />
							<line x1={9} y1={8} x2={15} y2={8} />
							<line x1={17} y1={16} x2={23} y2={16} />
						</svg>
					</div>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						{row.status === 1 && new Date() > new Date(row?.campaign_end_date) && row?.payout_status === 0 && (
							<Link
								to="#"
								className="dropdown-item"
								onClick={() => {
									requestPayout(row?.id);
								}}
							>
								{t('fund_raise.request_payout')}
							</Link>
						)}
						{row?.payout_status === 2 && (
							<>
								<Link
									to="#"
									className="dropdown-item"
									data-bs-toggle="modal"
									data-bs-target="#confirmPaymentModal"
									onClick={() => {
										showPaymentReceipt(row?.id);
									}}
								>
									{t('donation_list.confirm_payment')}
								</Link>
							</>
						)}
					</div>
				</div>
			),
		},
	];

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('fund_raise.fund_raise') },
					{ link: '#', name: t('admin_user.list') },
				]}
				title={t('fund_raise.list_fund_raise')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="cmn-a-tag-wrap mb-3">
				<Link className="cmn-a-tag" to="/admin/fund-raiser/add-fund">
					{t('fund_raise.add_fund_raise')}
				</Link>
			</div>
			<div className="datatable-wrap">
				<DataTable
					columns={columns}
					data={listFund}
					striped
					pagination
					paginationServer
					paginationTotalRows={listFundMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					onChangePage={(page) => setPage(page)}
					noDataComponent="There are no records to display"
				/>
			</div>

			<div
				className="modal fade cmn-modal"
				id="confirmPaymentModal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="confirmPaymentModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="confirmPaymentModalLabel">
								{t('donation_list.confirm_payment')}
							</h5>
							<button type="button" className="btn-close" id="confirmPaymentModalClose" data-bs-dismiss="modal" aria-label="Close">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<div className="payment-receipt">
								<span className="payment-receipt-img">
									<img className="img-block" src={paymentReceipt?.image} alt="payment reciept" />
								</span>
								<span className="payment-btn-wrap cmn-btn-tag-wrap">
									<button type="button" className="cmn-btn-tag" onClick={confirmPayment}>
										{t('donation_list.confirm_payment')}
									</button>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ListFundRaise;
