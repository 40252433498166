import { default as api } from 'config/adminApiConf.js';

export const addRequestFormService = async (data) => {
	try {
		let result = await api.post(`/request-form/create`, data);
		return result;
	} catch (error) {
		return error;
	}
};
export const listRequestType = async (data) => {
	try {
		let result = await api.get(`/request-type`, data);
		return result;
	} catch (error) {
		return error;
	}
};
