import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { usersList, userDelete, userStatusChange } from './Users.service.js';
import './Users.scss';
import { Navigate, Link } from 'react-router-dom';
import Alert from 'components/Alert';
import { confirmAlert } from 'react-confirm-alert';
import NoImage from 'assets/images/avatar.png';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

function Users() {
	const [users, setUsers] = useState([]);
	const [usersMeta, setUsersMeta] = useState({});
	const [page, setPage] = useState(1);
	const [redirect, setRedirect] = useState(0);
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [searchInput, setSearchInput] = useState('');
	const { t } = useTranslation();

	useEffect(() => {
		getUser();
	}, [page]);

	const getUser = async (search = '') => {
		let userList = await usersList(page, search);
		if (userList.data.status === 200) {
			setUsers(userList.data.data.records);
			setUsersMeta(userList.data.data.meta);
		} else if (userList.data.status === 401) {
			setRedirect(1);
		} else {
			SetAlertType('error');
			SetAlertMessage(userList.data.error);
			window.scrollTo(0, 0);
		}
	};
	const changeStatus = async (id, status) => {
		let result = await userStatusChange(id, status);
		if (result.data.status === 200) {
			var msg = '';
			if (status == 1) {
				msg = `${t('user.user_activated')} !`;
			} else {
				msg = `${t('user.user_deactivated')} !`;
			}
			getUser();
			SetAlertType('success');
			SetAlertMessage(msg);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}
		window.scrollTo(0, 0);
	};

	const deleteUser = async (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-confirmation-box">
						<h1>{t('admin_user.are_you_sure')}</h1>
						<p>{t('admin_user.you_want_to_delete_this')}</p>
						<button onClick={onClose} className="no-btn">
							{t('admin_user.no')}
						</button>
						<button
							className="yes-btn"
							onClick={async () => {
								let result = await userDelete(id);
								if (result.data.status === 200) {
									getUser();
									SetAlertType('success');
									SetAlertMessage(`${t('user_list.user_deleted_successfully')} !`);
								} else {
									SetAlertType('error');
									SetAlertMessage(result.data.error);
								}
								onClose();
								window.scrollTo(0, 0);
							}}
						>
							{t('admin_user.yes_delete_it')}
						</button>
					</div>
				);
			},
		});
	};

	const handleSearch = debounce(async (value) => {
		getUser(value);
	}, 500);

	const columns = [
		{
			name: t('admin_user.profile_image'),
			cell: (row) => (
				<span className="admin-user-img">
					<img alt={row.first_name} src={row.get_user_details?.avatar || NoImage} />
				</span>
			),
		},
		{
			name: t('admin_user.first_name'),
			selector: (row) => row.first_name,
			sortable: true,
		},
		{
			name: t('admin_user.last_name'),
			selector: (row) => row.last_name,
			sortable: true,
		},
		{
			name: t('admin_user.email'),
			selector: (row) => row.email,
			sortable: true,
		},
		{
			name: t('admin_user.phone_no'),
			selector: (row) => row.ph_no,
			sortable: true,
		},
		{
			name: t('admin_user.role'),
			selector: (row) => row.get_role.name,
			sortable: true,
		},
		{
			name: t('admin_user.status'),
			cell: (row) =>
				row.status == 0 ? <span className="badge bg-danger">{t('admin_user.inactive')}</span> : <span className="badge bg-success">{t('admin_user.active')}</span>,
		},
		{
			name: t('admin_user.action'),
			cell: (row) => (
				<div className="dropdown">
					<div className="actionList " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg
							style={{ cursor: 'pointer' }}
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-sliders dropdown-toggle"
							data-toggle="dropdown"
							role="button"
							aria-expanded="false"
						>
							<line x1={4} y1={21} x2={4} y2={14} />
							<line x1={4} y1={10} x2={4} y2={3} />
							<line x1={12} y1={21} x2={12} y2={12} />
							<line x1={12} y1={8} x2={12} y2={3} />
							<line x1={20} y1={21} x2={20} y2={16} />
							<line x1={20} y1={12} x2={20} y2={3} />
							<line x1={1} y1={14} x2={7} y2={14} />
							<line x1={9} y1={8} x2={15} y2={8} />
							<line x1={17} y1={16} x2={23} y2={16} />
						</svg>
					</div>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						<Link to={'/admin/user/edit/' + btoa(row.id)} className="dropdown-item">
							{t('admin_user.edit')}
						</Link>
						<Link
							to="#"
							className="dropdown-item"
							onClick={() => {
								deleteUser(row.id);
							}}
						>
							{t('admin_user.delete')}
						</Link>
						{row.status === 0 ? (
							<Link
								to="#"
								className="dropdown-item"
								onClick={() => {
									changeStatus(row.id, 1);
								}}
							>
								{t('admin_user.active_user')}
							</Link>
						) : (
							<Link
								to="#"
								className="dropdown-item"
								onClick={() => {
									changeStatus(row.id, 0);
								}}
							>
								{t('user.deactive_user')}
							</Link>
						)}
					</div>
				</div>
			),
		},
	];
	if (redirect) {
		return <Navigate to="/" replace />;
	}
	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('admin_user.users') },
					{ link: '#', name: t('admin_user.list') },
				]}
				title={t('admin_user.users')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="row mb-3 justify-content-between">
				<div className="col-lg-6">
					<div className="cmn-a-tag-wrap">
						<Link to={'/admin/users/add'} className="cmn-a-tag">
							{t('admin_user.add_user')}
						</Link>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="admim-input-wrap">
						<input
							type="text"
							className="form-control admim-input-style"
							placeholder={t('admin_user.search')}
							onChange={(e) => {
								handleSearch(e.target.value);
							}}
						/>
					</div>
				</div>
			</div>
			<div className="datatable-wrap">
				<DataTable
					columns={columns}
					data={users}
					striped
					pagination
					paginationServer
					paginationTotalRows={usersMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					onChangePage={(page) => setPage(page)}
				/>
			</div>
		</>
	);
}

export default Users;
