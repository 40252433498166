import React from 'react';
import './Loader.scss';
function Loader() {
	return (
		<>
			<div className="loader-wrap" id="loading_container">
				<div className="mesh-loader-wrap">
					<div className="mesh-loader">
						<div className="set-one">
							<div className="circle" />
							<div className="circle" />
						</div>
						<div className="set-two">
							<div className="circle" />
							<div className="circle" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Loader;
