import React, { useEffect, useState, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import Select from 'react-select';
import Breadcrumb from 'components/Admin/Breadcrumb';
import Alert from 'components/Alert';
import { useParams, Link } from 'react-router-dom';
import { subCatList, catList, productDetail, productEdit } from './EditProduct.service.js';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function EditProduct() {
	const params = useParams();
	const id = atob(params.id);
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		resetField,
	} = useForm();
	const [prodImg, setProdImg] = useState('');
	const [category, setCategory] = useState([]);
	const [subCategory, setSubCategory] = useState([]);
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [productDtl, setProductDtl] = useState({});
	const lang = useSelector((state) => state.lang);
	const { t } = useTranslation();

	useEffect(() => {
		getCategoryList();
		getProdDtl();
	}, [lang]);

	const getProdDtl = async () => {
		let result = await productDetail(id);

		if (result.data.status === 200) {
			let product = result.data.data.product;
			setProductDtl(product);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.name = productDtl?.name?.en;
		defaultValues.name_fr = productDtl?.name?.fr;
		defaultValues.name_ar = productDtl?.name?.ar;
		defaultValues.category = {
			value: productDtl?.get_category?.id,
			label: productDtl?.get_category?.name,
		};
		defaultValues.sub_category = {
			value: productDtl?.get_sub_category?.id,
			label: productDtl?.get_sub_category?.name,
		};
		await reset({ ...defaultValues });

		await setProdImg(productDtl?.image);
	};
	useMemo(() => {
		setDefaultValueForm();
	}, [productDtl]);

	const getCategoryList = async () => {
		let list = await catList();
		if (list.data.status === 200) {
			let cat = list.data.data.category.map((res, i) => {
				return { value: res.id, label: res.name_lang?.[lang] };
			});
			setCategory(cat);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};
	const getSubCategoryList = async (cat_id) => {
		let list = await subCatList(cat_id);
		console.log(list);
		if (list.data.status === 200) {
			let cat = list.data.data.subcategory.map((res, i) => {
				return { value: res.id, label: res.name_lang?.[lang] };
			});
			setSubCategory(cat);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};

	const handleFile = async (e) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			SetAlertType('error');
			SetAlertMessage(t('about_content.image_size_less_200_kb'));
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setProdImg(img);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};
	const categoryChange = async (e) => {
		getSubCategoryList(e.value);
		resetField('sub_category', {
			defaultValue: { value: '', label: 'Select...' },
		});
	};
	const onSubmit = async (data) => {
		console.log(data);
		let formData = {
			name: data.name,
			name_fr: data.name_fr,
			name_ar: data.name_ar,
			category: data.category.value,
			sub_category: data.sub_category.value,
			product_image: prodImg,
		};
		let result = await productEdit(id, formData);
		if (result.data.status === 200) {
			SetAlertType('success');
			SetAlertMessage(`${t('vendor.product_updated_successfully')}`);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}
		window.scrollTo(0, 0);
	};
	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('admin_product_type.product') },
					{ link: '#', name: t('admin_product_type.edit') },
				]}
				title="Edit Product"
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-12">
							<div className="product-img-wrap">
								<div className="product-img-upload">
									<input type="file" id="product_image" onChange={handleFile} />
									<label htmlFor="product_image">
										{!prodImg && (
											<div className="product-upload-text">
												<i className="fa fa-download"></i>
												<p>{`${t('admin_product_type.upload_image')}...`}</p>
											</div>
										)}
										{prodImg && (
											<div className="preview-img">
												<img src={prodImg} className="img-block" />
											</div>
										)}
									</label>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_product_type.name')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('admin_product_type.name')}
									{...register('name', { required: t('home_content_page.name_is_required') })}
								/>
								{errors.name && <InputErrorMsg error={errors.name?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_product_type.name_fr')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('admin_product_type.name_fr')}
									{...register('name_fr', {
										required: t('vendor.name_fr_is_required'),
									})}
								/>
								{errors.name_fr && <InputErrorMsg error={errors.name_fr?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_product_type.name_ar')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('admin_product_type.name_ar')}
									{...register('name_ar', {
										required: t('vendor.name_ar_is_required'),
									})}
								/>
								{errors.name_ar && <InputErrorMsg error={errors.name_ar?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_product_type.category')}</label>
							<div className="admim-select-wrap">
								<Controller
									name="category"
									control={control}
									render={({ field }) => (
										<Select
											{...field}
											options={category}
											onChange={(e) => {
												field.onChange(e);
												categoryChange(e);
											}}
										/>
									)}
									rules={{ required: t('admin_dashboard_menu.category_is_required') }}
								/>
								{errors.category && <InputErrorMsg error={errors.category?.message} />}
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-6 col-12">
							<label className="admin-lbl">{t('admin_product_type.sub_category')}</label>
							<div className="admim-select-wrap">
								<Controller
									name="sub_category"
									control={control}
									render={({ field }) => <Select {...field} options={subCategory} />}
									rules={{ required: t('vendor_product.sub_category_is_required') }}
								/>
								{errors.sub_category && <InputErrorMsg error={errors.sub_category?.message} />}
							</div>
						</div>

						<div className="col-12">
							<div className="cmn-btn-tag-wrap">
								<button type="submit" className="cmn-btn-tag">
									{t('admin_product_type.save')}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default EditProduct;
