import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { myDonationService } from './FrontMyDonation.service.js';
import { toast } from 'react-toastify';
import { clearPaymentService } from './FrontMyDonation.service.js';
import Header from 'components/Header';
import Footer from 'components/Footer';
import bannerImg from 'assets/images/forum.jpg';
import { useTranslation } from 'react-i18next';

function FrontMyDonation() {
	const [donation, setDonation] = useState([]);
	const [donationMeta, setDonationMeta] = useState({});
	const [page, setPage] = useState(1);
	const [receiptImg, setReceiptImg] = useState('');
	const [donationId, setDonationId] = useState('');
	const { t } = useTranslation();

	useEffect(() => {
		getDonationList();
	}, []);

	const handleFile = async (e) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			toast.error(t('about_content.image_size_less_200_kb'));
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setReceiptImg(img);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};
	const getDonationList = async () => {
		const list = await myDonationService(page);
		if (list.data.status === 200) {
			setDonation(list.data.data.records);
			setDonationMeta(list.data.data.meta);
		} else {
			toast.error(list.data.error);
		}
	};

	const handlePayment = async (donationId) => {
		setReceiptImg('');
		setDonationId(donationId);
	};

	const uploadPaymentReceipt = async () => {
		if (!receiptImg) {
			toast.error(t('payout_order.please_upload_the_reciept'));
			return false;
		}
		const fund_donation_id = donationId;
		const image = receiptImg;
		const formData = {
			fund_donation_id,
			image,
		};
		let result = await clearPaymentService(formData);
		console.log('result', formData, result);
		if (result.data.status === 200) {
			await getDonationList();
			document.querySelector('.btn-close').click();
			toast.success(`${t('payout_order.payement_cleared_successfully')} !`);
		} else {
			toast.error(result.data.error);
		}
	};

	const columns = [
		{
			name: t('admin_my_donation.campaign_titile'),
			selector: (row) => row?.get_raise_fund?.campaign_title?.en,
			sortable: true,
		},
		{
			name: t('admin_my_donation.donation_amount'),
			selector: (row) => row?.amount,
			sortable: true,
		},
		{
			name: t('admin_my_donation.payment_method'),
			selector: (row) => {
				let method = '';
				if (row.payment_method === 1) {
					method = t('funding_details.bankili');
				} else if (row.payment_method === 2) {
					method = t('funding_details.masrivi');
				} else if (row.payment_method === 3) {
					method = t('funding_details.saddad');
				} else {
					method = t('funding_details.cash');
				}
				return method;
			},
			sortable: true,
		},
		{
			name: t('admin_my_donation.donation_date'),
			selector: (row) => moment(row.created_at).format('DD-MM-YYYY'),
			sortable: true,
		},
		{
			name: t('admin_my_donation.status'),
			selector: (row) => {
				let status = '';
				if (row.status === 0) {
					status = <span class="badge bg-warning text-dark">{t('admin_my_donation.pending')}</span>;
				}
				if (row.status === 1) {
					status = <span class="badge bg-primary">{t('admin_my_donation.payment_cleared')}</span>;
				}
				if (row.status === 2) {
					status = <span class="badge bg-success">{t('admin_my_donation.accepted')}</span>;
				}
				return status;
			},
			sortable: true,
		},
		{
			name: t('admin_my_donation.action'),
			cell: (row) => (
				<div className="dropdown">
					<div className="actionList " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg
							style={{ cursor: 'pointer' }}
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-sliders dropdown-toggle"
							data-toggle="dropdown"
							role="button"
							aria-expanded="false"
						>
							<line x1={4} y1={21} x2={4} y2={14} />
							<line x1={4} y1={10} x2={4} y2={3} />
							<line x1={12} y1={21} x2={12} y2={12} />
							<line x1={12} y1={8} x2={12} y2={3} />
							<line x1={20} y1={21} x2={20} y2={16} />
							<line x1={20} y1={12} x2={20} y2={3} />
							<line x1={1} y1={14} x2={7} y2={14} />
							<line x1={9} y1={8} x2={15} y2={8} />
							<line x1={17} y1={16} x2={23} y2={16} />
						</svg>
					</div>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						{row.status === 0 && (
							<>
								<Link
									to="#"
									className="dropdown-item"
									data-bs-toggle="modal"
									data-bs-target="#paymentModal"
									onClick={() => {
										handlePayment(row.id);
									}}
								>
									{t('payout_order.clear_payment')}
								</Link>
							</>
						)}
					</div>
				</div>
			),
		},
	];

	return (
		<>
			<Header />
			<section className="inner-banner-sec d-flex align-items-center justify-content-center" style={{ background: `url(${bannerImg}) no-repeat` }}>
				<div className="inner-banner-head">
					<h3>{'My Donation'}</h3>
				</div>
			</section>
			<section className="catagory-list-sec sec-gap">
				<div className="container-fluid left-right-40">
					<div className="row">
						<div className="col-12">
							<div className="datatable-wrap">
								<DataTable
									columns={columns}
									data={donation}
									striped
									pagination
									paginationServer
									paginationTotalRows={donationMeta.totalRecords}
									paginationPerPage={15}
									paginationComponentOptions={{
										noRowsPerPage: true,
									}}
									onChangePage={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />

			{/* Payment Modal  */}

			<div
				className="modal fade cmn-modal"
				id="paymentModal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="paymentModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="paymentModalLabel">
								{t('payout_order.payment_receipt')}
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-12">
									<div className="product-img-wrap">
										<div className="product-img-upload">
											<input type="file" id="receiptImg" onChange={handleFile} />
											<label htmlFor="receiptImg">
												{!receiptImg && (
													<div className="product-upload-text">
														<i className="fa fa-download"></i>
														<p>{`${t('payout_order.upload_receipt')}...`}</p>
													</div>
												)}
												{receiptImg && (
													<div className="preview-img">
														<img src={receiptImg} className="img-block" alt="product" />
													</div>
												)}
											</label>
										</div>
									</div>
								</div>
								<div className="col-12 cmn-btn-tag-wrap">
									<button className="cmn-btn-tag" type="button" onClick={uploadPaymentReceipt}>
										{t('payout_order.submit')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default FrontMyDonation;
