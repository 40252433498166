import React, { useEffect, useState } from 'react';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';
import { addContentService, getContentService } from './AboutUsContent.service.js';
import './AboutUsContent.scss';
import { useTranslation } from 'react-i18next';

function AboutUsContent() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'quotes_user',
	});
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [aboutImg, setAboutImg] = useState('');
	const [quoteImg, setQuoteImg] = useState([]);
	const [content, setContent] = useState({});
	const [reRender, setReRender] = useState(0);
	const { t } = useTranslation();

	useEffect(() => {
		getContent();
	}, []);

	useEffect(() => {
		setDefaultValueForm();
	}, [content]);

	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.description = content?.description?.en;
		defaultValues.description_fr = content?.description?.fr;
		defaultValues.description_ar = content?.description?.ar;
		defaultValues.title = content?.title?.en;
		defaultValues.title_fr = content?.title?.fr;
		defaultValues.title_ar = content?.title?.ar;
		defaultValues.our_service = content?.our_service?.en;
		defaultValues.our_service_fr = content?.our_service?.fr;
		defaultValues.our_service_ar = content?.our_service?.ar;
		const quser = [];
		const arr = content?.quotes_user?.map((val, i) => {
			quser.push({
				description: val?.description?.en,
				description_fr: val?.description?.fr,
				description_ar: val?.description?.ar,
			});
			return val?.img;
		});
		defaultValues.quotes_user = quser;
		await reset({ ...defaultValues });
		if (arr) {
			setQuoteImg(arr);
		}
		setAboutImg(content?.about_img);
	};

	const handleAboutImg = async (e) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			toast.error(t('about_content.image_size_less_200_kb'));
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setAboutImg(img);
		}
	};

	const handleQuoteImg = async (e, index) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			toast.error(t('about_content.image_size_less_200_kb'));
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			quoteImg[index] = img;
			setQuoteImg([...quoteImg]);
			setReRender(!reRender);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};
	const onSubmit = async (data) => {
		if (data?.quotes_user?.length !== quoteImg?.length || !aboutImg) {
			toast.error(t('about_content.please_select_all_image'));
			return false;
		}
		data?.quotes_user.map((val, i) => {
			val['img'] = quoteImg[i];
		});
		let formData = { ...data, ...{ about_img: aboutImg } };
		console.log(formData);
		const result = await addContentService(formData);
		if (result.data.status === 200) {
			toast.success(`${t('about_content.content_saved_successfully')} !`);
		} else {
			toast.error(result.data.error);
		}
	};

	const removeQuoteItem = async (index) => {
		remove(index);
		setQuoteImg(quoteImg.filter((val, i) => i !== index));
	};

	const getContent = async () => {
		const result = await getContentService();
		if (result.data.status === 200) {
			setContent(result.data.data.content.content);
		} else {
			toast.error(result.data.error);
		}
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('about_content.content_management') },
					{ link: '#', name: t('about_content.about_us') },
				]}
				title={t('about_content.about_us_Content')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-12">
							<div className="description-img-box">
								<div className="description-img">
									<input type="file" id="content_img" onChange={handleAboutImg} />
									<label htmlFor="content_img">
										{!aboutImg && (
											<div className="product-upload-text">
												<i className="fa fa-download"></i>
												<p>{`${t('admin_product_type.upload_image')} ...`}</p>
											</div>
										)}
										{aboutImg && (
											<div className="preview-img">
												<img src={aboutImg} className="img-block" alt="product" />
											</div>
										)}
									</label>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-12 col-12">
							<label className="admin-lbl">{t('about_content.title')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('about_content.title')}
									{...register('title', { required: t('about_content.title_is_required') })}
								/>
								{errors.title && <InputErrorMsg error={errors.title?.message} />}
							</div>
							<div className="description-content">
								<label className="admin-lbl">{`${t('about_content.description')}`}</label>
								<div className="admim-input-wrap">
									<Controller
										name="description"
										control={control}
										render={({ field: { onChange, onBlur, value, ref } }) => (
											<CKEditor
												editor={ClassicEditor}
												data={content?.description?.en}
												onChange={(event, editor) => {
													onChange(editor.getData());
												}}
											/>
										)}
										rules={{ required: t('about_content.description_is_required') }}
									/>
									{errors.description && <InputErrorMsg error={errors.description?.message} />}
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-12 col-12">
							<label className="admin-lbl">{`${t('about_content.title_fr')}`}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={`${t('about_content.title_fr')}`}
									{...register('title_fr', { required: t('about_content.title_fr_is_required') })}
								/>
								{errors.title_fr && <InputErrorMsg error={errors.title_fr?.message} />}
							</div>
							<div className="description-content">
								<label className="admin-lbl">{`${t('about_content.Description_fr')}`}</label>
								<div className="admim-input-wrap">
									<Controller
										name="description_fr"
										control={control}
										render={({ field: { onChange, onBlur, value, ref } }) => (
											<CKEditor
												editor={ClassicEditor}
												data={content?.description?.fr}
												onChange={(event, editor) => {
													onChange(editor.getData());
												}}
											/>
										)}
										rules={{ required: t('about_content.description_fr_is_required') }}
									/>
									{errors.description_fr && <InputErrorMsg error={errors.description_fr?.message} />}
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-12 col-12">
							<label className="admin-lbl">{`${t('about_content.title_ar')}`}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={`${t('about_content.title_ar')}`}
									{...register('title_ar', { required: t('about_content.title_ar_is_required') })}
								/>
								{errors.title_ar && <InputErrorMsg error={errors.title_ar?.message} />}
							</div>
							<div className="description-content">
								<label className="admin-lbl">{`${t('about_content.Description_ar')}`}</label>
								<div className="admim-input-wrap">
									<Controller
										name="description_ar"
										control={control}
										render={({ field: { onChange, onBlur, value, ref } }) => (
											<CKEditor
												editor={ClassicEditor}
												data={content?.description?.ar}
												onChange={(event, editor) => {
													onChange(editor.getData());
												}}
											/>
										)}
										rules={{ required: t('about_content.description_ar_is_required') }}
									/>
									{errors.description_ar && <InputErrorMsg error={errors.description_ar?.message} />}
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-12 col-12">
							<div className="description-content">
								<label className="admin-lbl">{`${t('Our service')}`}</label>
								<div className="admim-input-wrap">
									<Controller
										name="our_service"
										control={control}
										render={({ field: { onChange, onBlur, value, ref } }) => (
											<CKEditor
												editor={ClassicEditor}
												data={content?.our_service?.ar}
												onChange={(event, editor) => {
													onChange(editor.getData());
												}}
											/>
										)}
										rules={{ required: 'Our service is required' }}
									/>
									{errors.our_service && <InputErrorMsg error={errors.our_service?.message} />}
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-12 col-12">
							<div className="description-content">
								<label className="admin-lbl">{`${t('Our service (Fr)')}`}</label>
								<div className="admim-input-wrap">
									<Controller
										name="our_service_fr"
										control={control}
										render={({ field: { onChange, onBlur, value, ref } }) => (
											<CKEditor
												editor={ClassicEditor}
												data={content?.our_service?.ar}
												onChange={(event, editor) => {
													onChange(editor.getData());
												}}
											/>
										)}
										rules={{ required: 'Our service fr is required' }}
									/>
									{errors.our_service_fr && <InputErrorMsg error={errors.our_service_fr?.message} />}
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-12 col-12">
							<div className="description-content">
								<label className="admin-lbl">{`${t('Our service (Ar)')}`}</label>
								<div className="admim-input-wrap">
									<Controller
										name="our_service_ar"
										control={control}
										render={({ field: { onChange, onBlur, value, ref } }) => (
											<CKEditor
												editor={ClassicEditor}
												data={content?.our_service?.ar}
												onChange={(event, editor) => {
													onChange(editor.getData());
												}}
											/>
										)}
										rules={{ required: 'Our service ar is required' }}
									/>
									{errors.our_service_ar && <InputErrorMsg error={errors.our_service_ar?.message} />}
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="cmn-panel-wrap">
								<div className="card">
									<div className="card-header bg-card-headar">
										<div className="quotes-list-head">
											<h4>{t('about_content.quotes_from_user')}</h4>
										</div>
									</div>
									<div className="card-body cmn-panel-box">
										{fields.map((item, index) => (
											<>
												<div className="card">
													<div className="card-header">
														<div className="row justify-content-between align-items-center">
															<div className="col-auto">
																<div className="list-head">{t('about_content.item')}</div>
															</div>
															<div className="col-auto">
																<div className="quotes-delete">
																	<button type="button" className="cmn-btn-tag" onClick={() => removeQuoteItem(index)}>
																		<i className="fas fa-trash-alt"></i>
																	</button>
																</div>
															</div>
														</div>
													</div>
													<div className="card-body">
														<div className="row justify-content-center">
															<div className="col-lg-3 col-md-4 col-sm-4 col-12">
																<div className="description-img-box">
																	<div className="description-img">
																		<input type="file" id={`content_img${index}`} onChange={(e) => handleQuoteImg(e, index)} />
																		<label htmlFor={`content_img${index}`}>
																			{!quoteImg?.[index] && (
																				<div className="product-upload-text">
																					<i className="fa fa-download"></i>
																					<p>{`${t('admin_product_type.upload_image')} ...`}</p>
																				</div>
																			)}
																			{quoteImg?.[index] && (
																				<div className="preview-img">
																					<img src={quoteImg?.[index]} className="img-block" alt="product" />
																				</div>
																			)}
																		</label>
																	</div>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																<label className="admin-lbl">{`${t('about_content.description')}:`}</label>
																<div className="admim-input-wrap">
																	<Controller
																		name={`quotes_user[${index}].description`}
																		control={control}
																		render={({ field: { onChange, onBlur, value, ref } }) => (
																			<CKEditor
																				editor={ClassicEditor}
																				data={content?.quotes_user?.[index]?.description?.en}
																				onChange={(event, editor) => {
																					onChange(editor.getData());
																				}}
																			/>
																		)}
																		rules={{ required: t('about_content.description_is_required') }}
																	/>
																	{errors?.quotes_user?.[index]?.description && (
																		<InputErrorMsg error={errors?.quotes_user?.[index]?.description?.message} />
																	)}
																</div>
															</div>
															<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																<label className="admin-lbl">{`${t('about_content.Description_fr')}:`}</label>
																<div className="admim-input-wrap">
																	<Controller
																		name={`quotes_user[${index}].description_fr`}
																		control={control}
																		render={({ field: { onChange, onBlur, value, ref } }) => (
																			<CKEditor
																				editor={ClassicEditor}
																				data={content?.quotes_user?.[index]?.description?.fr}
																				onChange={(event, editor) => {
																					onChange(editor.getData());
																				}}
																			/>
																		)}
																		rules={{ required: t('about_content.description_fr_is_required') }}
																	/>
																	{errors?.quotes_user?.[index]?.description_fr && (
																		<InputErrorMsg error={errors?.quotes_user?.[index]?.description_fr?.message} />
																	)}
																</div>
															</div>
															<div className="col-lg-4 col-md-4 col-sm-6 col-12">
																<label className="admin-lbl">{`${t('about_content.Description_ar')}:`}</label>
																<div className="admim-input-wrap">
																	<Controller
																		name={`quotes_user[${index}].description_ar`}
																		control={control}
																		render={({ field: { onChange, onBlur, value, ref } }) => (
																			<CKEditor
																				editor={ClassicEditor}
																				data={content?.quotes_user?.[index]?.description?.ar}
																				onChange={(event, editor) => {
																					onChange(editor.getData());
																				}}
																			/>
																		)}
																		rules={{ required: t('about_content.description_ar_is_required') }}
																	/>
																	{errors?.quotes_user?.[index]?.description_ar && (
																		<InputErrorMsg error={errors?.quotes_user?.[index]?.description_ar?.message} />
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
											</>
										))}

										<div className="cmn-btn-tag-wrap">
											<button
												type="button"
												className="cmn-btn-tag black-bg"
												onClick={() => append({ description: '', description_fr: '', description_ar: '', img: '' })}
											>
												{t('about_content.add_more_quotes')}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="cmn-btn-tag-wrap">
								<button type="submit" className="cmn-btn-tag">
									{t('admin_user.save')}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default AboutUsContent;
