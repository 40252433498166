import { default as api } from 'config/adminApiConf.js';

export const getBankDtlService = async () => {
	try {
		let result = await api.get('/get-bank-details');
		return result;
	} catch (error) {
		return error;
	}
};

export const saveBankDtlService = async (data) => {
	try {
		let result = await api.put('/edit-bank-details', data);
		return result;
	} catch (error) {
		return error;
	}
};
