import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { getBankDtlService, saveBankDtlService } from './EditBankDetail.service.js';
import { useTranslation } from 'react-i18next';

const EditBankDetail = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [bankDetails, setBankDetails] = useState({});
	const { t } = useTranslation();

	useEffect(() => {
		getBankDtl();
	}, []);
	useEffect(() => {
		setDefaultValueForm();
	}, [bankDetails]);

	const setDefaultValueForm = async () => {
		let defaultValues = {};

		defaultValues.branch_name = bankDetails?.branch_name;
		defaultValues.account_holder_name = bankDetails?.account_holder_name;
		defaultValues.account_no = bankDetails?.account_no;
		defaultValues.ifsc_code = bankDetails?.ifsc_code;
		defaultValues.mobile_banking_name = bankDetails?.mobile_banking_name;
		defaultValues.phone_no = bankDetails?.phone_no;

		await reset({ ...defaultValues });
	};

	const onSubmit = async (data) => {
		let result = await saveBankDtlService(data);
		if (result.data.status === 200) {
			SetAlertType('success');
			SetAlertMessage(`${t('bank.bank_details_saved')} !`);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}
		window.scrollTo(0, 0);
	};

	const getBankDtl = async () => {
		let result = await getBankDtlService();
		if (result.data.status === 200) {
			setBankDetails(result?.data?.data?.bank_details);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('admin_dashboard_menu.bank_details') },
					{ link: '#', name: t('admin_user.edit') },
				]}
				title={t('admin_dashboard_menu.bank_details')}
			/>
			<div className="my-profile-wrap">
				<div className="my-profile-box">
					{alertType && <Alert type={alertType} message={alertMessage} />}
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('bank.full_name')}</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control admim-input-style"
										placeholder={t('bank.full_name')}
										{...register('account_holder_name', { required: t('home_content_page.name_is_required') })}
									/>
									{errors.account_holder_name && <InputErrorMsg error={errors.account_holder_name?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('bank.mobile_banking_solution')}</label>
								<select className="form-select" {...register('mobile_banking_name', { required: t('bank.mobile_banking_solution_is_required') })}>
									<option value="">{t('bank.select_mobile_banking_solution')}</option>
									<option value={1}>{'Bankili'}</option>
									<option value={2}>{'Masrivi'}</option>
									<option value={3}>{'Saddad'}</option>
								</select>
								{errors.mobile_banking_name && <InputErrorMsg error={errors.mobile_banking_name?.message} />}
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.phone_number')}</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control admim-input-style"
										placeholder={t('admin_user.phone_number')}
										{...register('phone_no', { required: t('bank.phone_number_is_required') })}
									/>
									{errors.phone_no && <InputErrorMsg error={errors.phone_no?.message} />}
								</div>
							</div>
							<div className="col-12">
								<div className="cmn-btn-wrap text-center">
									<button type="submit" className="cmn-btn">
										{t('admin_user.save')}
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default EditBankDetail;
