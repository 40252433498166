import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Breadcrumb from 'components/Admin/Breadcrumb';
import Alert from 'components/Alert';
import moment from 'moment';
import { listEventRegisterService } from './ListEventRegister.service.js';
import './ListEventRegister.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

function ListEvent() {
	const [eventRegister, setEventRegister] = useState([]);
	const [eventRegisterMeta, setEventRegisterMeta] = useState({});
	const [page, setPage] = useState(1);
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const { t } = useTranslation();

	const auth = useSelector((state) => state.auth);
	const lang = useSelector((state) => state.lang);

	useEffect(() => {
		getEventList();
	}, [page, lang]);

	const getEventList = async (search = '') => {
		const list = await listEventRegisterService(page, search, lang);
		console.log(list);
		if (list.data.status === 200) {
			setEventRegister(list.data.data.records);
			setEventRegisterMeta(list.data.data.meta);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};
	const columns = [
		{
			name: t('fund_catagory.event_title'),
			selector: (row) => row.get_event.title?.en,
			sortable: true,
		},
		{
			name: t('admin_profile.name'),
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: t('admin_profile.email'),
			selector: (row) => row.email,
			sortable: false,
		},
		{
			name: t('home_content_page.phone_no'),
			selector: (row) => row.phone_no,
			sortable: false,
		},
		{
			name: t('admin_category.created_at'),
			selector: (row) => moment(row.created_at).format('DD-MM-YYYY'),
			sortable: true,
		},
	];

	const handleSearch = debounce(async (value) => {
		getEventList(value);
	}, 500);

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('event.register_user_event') },
					{ link: '#', name: t('admin_order.list') },
				]}
				title={t('event.register_user_event_list')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}

			<div className="row mb-3 justify-content-end">
				<div className="col-lg-4">
					<div className="admim-input-wrap">
						<input
							type="text"
							className="form-control admim-input-style"
							placeholder={t('admin_user.search')}
							onChange={(e) => {
								handleSearch(e.target.value);
							}}
						/>
					</div>
				</div>
			</div>
			<div className="datatable-wrap">
				<DataTable
					columns={columns}
					data={eventRegister}
					striped
					pagination
					paginationServer
					paginationTotalRows={eventRegisterMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					onChangePage={(page) => setPage(page)}
				/>
			</div>
		</>
	);
}

export default ListEvent;
