import { default as adminApi } from 'config/adminApiConf.js';

export const listOrder = async (page) => {
	try {
		let result = await adminApi.get(`/delivery-service/order-list?page=${page}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const addShippingChargeService = async (data) => {
	try {
		let result = await adminApi.post(`/delivery-service/add-delivery-charge`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const productDeliveredService = async (data) => {
	try {
		let result = await adminApi.post(`/delivery-service/product-delivered`, data);
		return result;
	} catch (error) {
		return error;
	}
};
