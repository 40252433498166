import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NoImage from 'assets/images/avatar.png';
import { useForm } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import Alert from 'components/Alert';
import { listCountry, getUserDtl, listRole, editUser, listRegion } from './EditUser.service.js';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { useTranslation } from 'react-i18next';

function EditUser() {
	const params = useParams();
	const id = atob(params.id);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [countryList, SetCountryList] = useState([]);
	const [roleList, setRoleList] = useState([]);
	const [userDtl, setUserDtl] = useState({ get_user_details: { get_country: '' } });
	const [avatar, setAvatar] = useState('');
	const [regionList, SetRegionList] = useState([]);
	const { t } = useTranslation();

	useEffect(() => {
		getCountry();
		getRole();
		getRegion();
		getUserDetail();
	}, []);

	useEffect(() => {
		setDefaultValueForm();
	}, [userDtl]);

	const getCountry = async () => {
		let result = await listCountry();
		console.log(result);
		if (result.data.status === 200) {
			let country = result.data.data.country;
			let list = country.map((res, i) => {
				return { value: res.id, label: res.name };
			});
			SetCountryList(list);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};
	const getRole = async () => {
		let result = await listRole();
		console.log(result);
		if (result.data.status === 200) {
			let role = result.data.data.role;
			setRoleList(role);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};
	const getRegion = async () => {
		let result = await listRegion();
		console.log(result);
		if (result.data.status === 200) {
			let region = result.data.data.region;
			SetRegionList(region);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};
	const getUserDetail = async () => {
		let result = await getUserDtl(id);

		if (result.data.status === 200) {
			let user = result.data.data.user;
			console.log('user ', user);
			setUserDtl(user);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};
	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.first_name = userDtl?.first_name;
		defaultValues.last_name = userDtl?.last_name;
		defaultValues.role = userDtl?.role;
		defaultValues.email = userDtl?.email;
		defaultValues.company_name = userDtl?.get_user_details?.company_name;
		defaultValues.company_vat = userDtl?.get_user_details?.company_vat;
		defaultValues.ph_no = userDtl?.ph_no;
		defaultValues.address = userDtl?.get_user_details?.address;
		defaultValues.zip_code = userDtl?.get_user_details?.zip_code;
		defaultValues.city = userDtl?.get_user_details?.city;
		defaultValues.region = userDtl?.get_user_details?.region;
		defaultValues.country = { value: userDtl?.get_user_details?.get_country?.id, label: userDtl?.get_user_details?.get_country?.name };
		await reset({ ...defaultValues });

		await setAvatar(userDtl?.get_user_details?.avatar);
	};
	const handleFile = async (e) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			SetAlertType('error');
			SetAlertMessage(t('about_content.image_size_less_200_kb'));
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setAvatar(img);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};
	const onSubmit = async (data) => {
		let submitData = { ...data, avatar };

		let result = await editUser(submitData, id);
		if (result.data.status === 200) {
			SetAlertType('success');
			SetAlertMessage(`{${t('profile_edit.user_update_successful')}} !`);
			getUserDetail();
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}
		window.scrollTo(0, 0);
	};
	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('admin_user.users') },
					{ link: '#', name: t('admin_user.edit') },
				]}
				title={t('profile_edit.edit_user')}
			/>
			<div className="my-profile-wrap">
				<div className="my-profile-box">
					{alertType && <Alert type={alertType} message={alertMessage} />}
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="profile-img-edit">
							<div className="profile-edit-btn">
								<input id="profileEdit" type="file" name="avatar" accept={t('add_user_data.png_jpg_jpeg')} onChange={handleFile} />
								<label htmlFor="profileEdit"></label>
							</div>
							<div className="profileimg-view">
								<img id="imgPrev" className="img-block" src={avatar ? avatar : NoImage} alt="Avatar" />
							</div>
						</div>
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.first_name')}</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control admim-input-style"
										placeholder={t('admin_user.first_name')}
										{...register('first_name', { required: t('add_user_data.first_name_is_required') })}
									/>
									{errors.first_name && <InputErrorMsg error={errors.first_name?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.last_name')}</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control admim-input-style"
										placeholder={t('admin_user.last_name')}
										{...register('last_name', { required: t('add_user_data.last_name_is_required') })}
									/>
									{errors.last_name && <InputErrorMsg error={errors.last_name?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.role')}</label>
								<div className="admim-select-wrap">
									<select className="form-control selectArrowBlk admim-select-style" {...register('role', { required: t('add_user_data.role_is_required') })}>
										<option value="">{`..{${t('funding_details.select')}}..`}</option>
										{roleList.map((role, i) => {
											return (
												<>
													<option key={i} value={role.id}>
														{role.name}
													</option>
												</>
											);
										})}
									</select>
									{errors.role && <InputErrorMsg error={errors.role?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.email')}</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control admim-input-style"
										placeholder={t('admin_user.email')}
										{...register('email', { required: t('add_user_data.email_is_reqired') })}
									/>
									{errors.email && <InputErrorMsg error={errors.email?.message} />}
								</div>
							</div>
							<div className="col-12">
								<label className="admin-lbl">{t('admin_user.company_name')}</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control admim-input-style"
										placeholder={t('admin_user.enter_your_company_name')}
										{...register('company_name')}
									/>
									{errors.company_name && <InputErrorMsg error={errors.company_name?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.company_VAT')}</label>
								<div className="admim-input-wrap">
									<input type="text" className="form-control admim-input-style" placeholder={t('admin_user.company_VAT_number')} {...register('company_vat')} />
									{errors.company_vat && <InputErrorMsg error={errors.company_vat?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.phone_number')}</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control admim-input-style"
										placeholder={t('admin_user.your_phone_number')}
										{...register('ph_no', { required: t('bank.phone_number_is_required') })}
									/>
									{errors.ph_no && <InputErrorMsg error={errors.ph_no?.message} />}
								</div>
							</div>
							<div className="col-12">
								<label className="admin-lbl">{t('admin_user.address')}</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control admim-input-style"
										placeholder={t('admin_user.enter_your_address')}
										{...register('address', { required: t('add_user_data.address_is_required') })}
									/>
									{errors.address && <InputErrorMsg error={errors.address?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.zip_code')}</label>
								<div className="admim-input-wrap">
									<input type="text" className="form-control admim-input-style" placeholder={t('admin_user.zip_code')} {...register('zip_code')} />
									{errors.zip_code && <InputErrorMsg error={errors.zip_code?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.region')}</label>
								<div className="admim-select-wrap">
									<select
										className="form-control selectArrowBlk admim-select-style"
										{...register('region', { required: t('admin_dashboard_menu.region_is_required') })}
									>
										<option value="">{`..{${t('funding_details.select')}}..`}</option>
										{regionList?.map((value, index) => {
											return (
												<>
													<option value={value.id}>{value.name}</option>
												</>
											);
										})}
									</select>
									{errors.region && <InputErrorMsg error={errors.region?.message} />}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-12">
								<label className="admin-lbl">{t('admin_user.city')}</label>
								<div className="admim-input-wrap">
									<input
										type="text"
										className="form-control admim-input-style"
										placeholder={t('admin_user.city')}
										{...register('city', { required: t('add_user_data.city_is_required') })}
									/>
									{errors.city && <InputErrorMsg error={errors.city?.message} />}
								</div>
							</div>

							<div className="col-12">
								<div className="cmn-btn-wrap text-center">
									<button type="submit" className="cmn-btn">
										{t('admin_user.save')}
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default EditUser;
