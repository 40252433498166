import { default as api } from 'config/adminApiConf.js';

export const listRequestFormService = async (page) => {
	try {
		let result = await api.get(`/request-form/list?page=${page}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const deleteRequestFormService = async (id) => {
	try {
		let result = await api.delete(`/request-form/delete/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};
export const publishRequestFormService = async (id) => {
	try {
		let result = await api.get(`/request-form/publish/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};
