import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import Breadcrumb from 'components/Admin/Breadcrumb';
import Alert from 'components/Alert';
import { toast } from 'react-toastify';
import { addForumService } from './AddForum.service.js';
import { useTranslation } from 'react-i18next';

function AddForum() {
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	const onSubmit = async (data) => {
		const result = await addForumService(data);
		if (result.data.status === 200) {
			toast.success(`${t('forum_admin.forum_added_successfully')} !`);
		} else {
			toast.error(result.data.error);
		}
		reset();
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('admin_dashboard_menu.forum') },
					{ link: '#', name: t('admin_product_type.add') },
				]}
				title={t('forum_admin.add_forum')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}

			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row g-3">
						<div className="col-12">
							<label className="admin-lbl">{t('about_content.title')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('about_content.title')}
									{...register('title', { required: t('about_content.title_is_required') })}
								/>
								{errors.title && <InputErrorMsg error={errors.title?.message} />}
							</div>
						</div>
						<div className="col-12">
							<label className="admin-lbl">{t('about_content.description')}</label>
							<div className="admim-input-wrap">
								<textarea
									className="form-control resize-none"
									placeholder={t('about_content.description')}
									rows="5"
									{...register('description', {
										required: t('about_content.description_is_required'),
									})}
								></textarea>
								{errors.description && <InputErrorMsg error={errors.description?.message} />}
							</div>
						</div>
						<div className="col-12 cmn-btn-tag-wrap mt-3">
							<button className="cmn-btn-tag">{t('payout_order.submit')}</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default AddForum;
