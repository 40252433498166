import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { Link } from 'react-router-dom';
import Alert from 'components/Alert';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm } from 'react-hook-form';
import { addCategory, catList, categoryDtl, editCategory, delCategory } from './FundCategory.service.js';
import './FundCategory.scss';
import { useTranslation } from 'react-i18next';

function FundCategory() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const {
		register: register2,
		handleSubmit: handleSubmit2,
		formState: { errors: errors2 },
		reset: reset2,
	} = useForm();
	const [category, setCategory] = useState([]);
	const [categoryMeta, setCategoryMeta] = useState({});
	const [page, setPage] = useState(1);
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [categoryDetail, SetCategoryDetail] = useState('');
	const [catId, setCatId] = useState(0);
	const { t } = useTranslation();

	useEffect(() => {
		getCategoryList();
	}, [page]);

	const getCategoryList = async () => {
		let list = await catList(page);
		if (list.data.status === 200) {
			setCategory(list.data.data.records);
			setCategoryMeta(list.data.data.meta);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};
	const onSubmit = async (data) => {
		console.log(data);
		let result = await addCategory(data);
		if (result.data.status === 200) {
			SetAlertType('success');
			SetAlertMessage(`{${t('fund_catagory.category_added_successfully')}} !`);
			getCategoryList();
			reset();
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}

		document.querySelector('.btn-close').click();
		window.scrollTo(0, 0);
	};

	const onSubmit2 = async (data) => {
		let result = await editCategory(catId, data);
		if (result.data.status === 200) {
			SetAlertType('success');
			SetAlertMessage(`{${t('fund_catagory.category_updated_successfully')}} !`);
			getCategoryList();
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}

		document.querySelector('#ediModalClose').click();
		window.scrollTo(0, 0);
	};

	const getCategoryDtl = async (id) => {
		let result = await categoryDtl(id);
		if (result.data.status === 200) {
			SetCategoryDetail(result.data.data.category);
			setCatId(result.data.data.category.id);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.name = categoryDetail.name?.en;
		defaultValues.name_fr = categoryDetail.name?.fr;
		defaultValues.name_ar = categoryDetail.name?.ar;

		await reset2({ ...defaultValues });
	};
	useMemo(() => {
		setDefaultValueForm();
	}, [categoryDetail]);

	const deleteCat = async (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-confirmation-box">
						<h1>{t('admin_user.are_you_sure')}</h1>
						<p>{t('admin_user.you_want_to_delete_this')}</p>
						<button onClick={onClose} className="no-btn">
							{t('admin_user.no')}
						</button>
						<button
							className="yes-btn"
							onClick={async () => {
								let result = await delCategory(id);
								if (result.data.status === 200) {
									getCategoryList();
									SetAlertType('success');
									SetAlertMessage(`{${t('fund_catagory.fund_category_deleted_successfully')}} !`);
								} else {
									SetAlertType('error');
									SetAlertMessage(result.data.error);
								}
								onClose();
								window.scrollTo(0, 0);
							}}
						>
							{t('admin_user.yes_delete_it')}
						</button>
					</div>
				);
			},
		});
	};

	const columns = [
		{
			name: t('admin_category.category_name'),
			selector: (row) => row?.name?.en,
			sortable: true,
		},
		{
			name: t('admin_category.created_at'),
			selector: (row) => moment(row.created_at).format('DD-MM-YYYY'),
			sortable: true,
		},
		{
			name: t('admin_category.action'),
			cell: (row) => (
				<div className="dropdown">
					<div className="actionList " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg
							style={{ cursor: 'pointer' }}
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-sliders dropdown-toggle"
							data-toggle="dropdown"
							role="button"
							aria-expanded="false"
						>
							<line x1={4} y1={21} x2={4} y2={14} />
							<line x1={4} y1={10} x2={4} y2={3} />
							<line x1={12} y1={21} x2={12} y2={12} />
							<line x1={12} y1={8} x2={12} y2={3} />
							<line x1={20} y1={21} x2={20} y2={16} />
							<line x1={20} y1={12} x2={20} y2={3} />
							<line x1={1} y1={14} x2={7} y2={14} />
							<line x1={9} y1={8} x2={15} y2={8} />
							<line x1={17} y1={16} x2={23} y2={16} />
						</svg>
					</div>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						<Link
							to={'#'}
							onClick={() => {
								getCategoryDtl(row.id);
							}}
							className="dropdown-item"
							data-bs-toggle="modal"
							data-bs-target="#catEditModal"
						>
							{t('admin_category.edit')}
						</Link>
						<Link
							to="#"
							className="dropdown-item"
							onClick={() => {
								deleteCat(row.id);
							}}
						>
							{t('admin_category.delete')}
						</Link>
					</div>
				</div>
			),
		},
	];

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('admin_fund_catagory.fund_category') },
					{ link: '#', name: t('admin_order.list') },
				]}
				title={t('admin_fund_catagory.fund_category')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="cmn-btn-tag-wrap mb-3">
				<button type="button" className="cmn-btn-tag" data-bs-toggle="modal" data-bs-target="#exampleModal">
					{t('admin_fund_catagory.add_fund_category')}
				</button>
			</div>
			<div className="datatable-wrap">
				<DataTable
					columns={columns}
					data={category}
					striped
					pagination
					paginationServer
					paginationTotalRows={categoryMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					onChangePage={(page) => setPage(page)}
				/>
			</div>

			{/* Add Category Modal */}
			<div className="modal fade cmn-modal" id="exampleModal" tabIndex={-1} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">
								{t('admin_fund_catagory.add_fund_category')}
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="row">
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_category.category_name')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('admin_category.category_name')}
												{...register('name', { required: t('fund_catagory.category_name_is_required') })}
											/>
											{errors.name && <InputErrorMsg error={errors.name?.message} />}
										</div>
									</div>
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_category.category_name_fr')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('admin_category.category_name_fr')}
												{...register('name_fr', { required: t('fund_catagory.category_name_fr_is_required') })}
											/>
											{errors.name_fr && <InputErrorMsg error={errors.name_fr?.message} />}
										</div>
									</div>
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_category.category_name_ar')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('admin_category.category_name_ar')}
												{...register('name_ar', { required: t('fund_catagory.category_name_ar_is_required') })}
											/>
											{errors.name_ar && <InputErrorMsg error={errors.name_ar?.message} />}
										</div>
									</div>
									<div className="col-12">
										<div className="cmn-btn-tag-wrap">
											<button type="submit" className="cmn-btn-tag">
												{t('admin_category.save')}
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			{/* edit category modal */}
			<div className="modal fade cmn-modal" id="catEditModal" tabIndex={-1} data-bs-backdrop="static" aria-labelledby="catEditModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="catEditModalLabel">
								{t('fund_catagory.edit_category')}
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="ediModalClose">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<form onSubmit={handleSubmit2(onSubmit2)}>
								<div className="row">
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_category.category_name')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('admin_category.category_name')}
												{...register2('name', { required: t('fund_catagory.category_name_is_required') })}
											/>
											{errors2.name && <InputErrorMsg error={errors2.name?.message} />}
										</div>
									</div>
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_category.category_name_fr')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('admin_category.category_name_fr')}
												{...register2('name_fr', { required: t('fund_catagory.category_name_fr_is_required') })}
											/>
											{errors2.name_fr && <InputErrorMsg error={errors2.name_fr?.message} />}
										</div>
									</div>
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_category.category_name_ar')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('admin_category.category_name_ar')}
												{...register2('name_ar', { required: t('fund_catagory.category_name_ar_is_required') })}
											/>
											{errors2.name_ar && <InputErrorMsg error={errors2.name_ar?.message} />}
										</div>
									</div>
									<div className="col-12">
										<div className="cmn-btn-tag-wrap">
											<button type="submit" className="cmn-btn-tag">
												{t('admin_category.save')}
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default FundCategory;
