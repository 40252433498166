import { default as api } from 'config/adminApiConf.js';

export const listRaiseFundService = async (page) => {
	try {
		let result = await api.get(`/funder/list-fund-raise?page=${page}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const requestPayoutService = async (data) => {
	try {
		let result = await api.post(`/funder/request-payout`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const getPaymentReceiptService = async (raiseFundId) => {
	try {
		let result = await api.get(`/funder/get-payment-reciept/${raiseFundId}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const confirmPaymentService = async (data) => {
	try {
		let result = await api.post(`/funder/confirm-fund-payout-payment`, data);
		return result;
	} catch (error) {
		return error;
	}
};
