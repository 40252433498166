import { default as api } from 'config/adminApiConf.js';

export const donationListService = async (page) => {
	try {
		let result = await api.get('/fund/donation-list?page=' + page);
		return result;
	} catch (error) {
		return error;
	}
};

export const getPaymentReceiptService = async (id) => {
	try {
		let result = await api.get('/fund/get-donation-payment-reciept/' + id);
		return result;
	} catch (error) {
		return error;
	}
};

export const donationPaymentConfirmService = async (data) => {
	try {
		let result = await api.post('/fund/donation-payment-confirm', data);
		return result;
	} catch (error) {
		return error;
	}
};
