import { default as api } from 'config/frontApiConf.js';

export const listOrder = async (page) => {
	try {
		let result = await api.get(`/customer/list-order?page=${page}`);
		return result;
	} catch (error) {
		return error;
	}
};
export const changeOrderStausService = async (data) => {
	try {
		let result = await api.post(`/customer/change-order-status`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const clearPayment = async (data) => {
	try {
		let result = await api.post(`/customer/clear-payment`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const productDtlService = async (id) => {
	try {
		let result = await api.get(`/product-detail/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const deliveryConfirmService = async (data) => {
	try {
		let result = await api.post(`/customer/confirm-deliery`, data);
		return result;
	} catch (error) {
		return error;
	}
};
