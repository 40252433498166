import React, { useEffect, useState } from 'react';
import './Login.scss';
import './Login.responsive.scss';
import LoginBg from 'assets/images/loginbg.jpg';
import LoginReglogo from 'assets/images/logo.png';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import Alert from 'components/Alert';
import { loginUser } from './Login.service.js';
import { useSelector, useDispatch } from 'react-redux';
import allActions from 'redux/actions';
import { useTranslation } from 'react-i18next';
function Login() {
	const { t } = useTranslation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const dispatch = useDispatch();

	const selectedLang = localStorage.getItem('i18nextLng').split('-')[0] || 'en';

	const lang = useSelector((state) => state.lang);
	let dir = 'ltr';
	dir = lang === 'ar' ? 'rtl' : 'ltr';

	useEffect(() => {
		document.body.dir = dir;
		document.body.className = `style-${dir}`;
		dispatch(allActions.langAction.changeLanguage(selectedLang));
	}, [lang]);

	const onSubmit = async (data) => {
		let result = await loginUser(data);

		if (result.data.status === 200) {
			localStorage.setItem('auth_token', result.data.data.token);

			if (result.data.data.role !== 3) {
				window.location = '/admin/dashboard';
			} else {
				window.location = '/';
			}
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}
		window.scrollTo(0, 0);
	};
	return (
		<>
			<section className="login-regbg d-flex align-items-center justify-content-center" style={{ background: `url(${LoginBg})` }}>
				<div className="login-reg-wrap">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="login-reg-box position-relative">
							<div className="log-back-home">
								<Link to="/" className="log-back-home-btn">
									<i className="fas fa-home"></i>
								</Link>
							</div>
							<span className="log-reg-logo">
								<img className="img-block" src={LoginReglogo} alt="LoginReglogo" />
							</span>
							{alertType && <Alert type={alertType} message={alertMessage} />}
							<div className="login-input">
								<label className="lbl-heading">{t('email')}</label>
								<input
									type="text"
									className="form-control login-input-style"
									placeholder={t('enter_your_email')}
									{...register('email', { required: t('email_is_reqired') })}
								/>
								{errors.email && <InputErrorMsg error={errors.email?.message} />}
							</div>
							<div className="login-input">
								<label className="lbl-heading">{t('password')}</label>
								<div className="add-icon">
									<input
										type={showPassword ? 'text' : 'password'}
										className="form-control login-input-style"
										placeholder={t('enter_your_password')}
										{...register('password', { required: t('password_is_reqired') })}
									/>
									<span className="pass-icon">
										<i
											className={showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'}
											onClick={() => {
												setShowPassword(!showPassword);
											}}
										></i>
									</span>
								</div>
								{errors.password && <InputErrorMsg error={errors.password?.message} />}
							</div>
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="checkbox remember-me">
										<input type="checkbox" id="remember" />
										<label htmlFor="remember">{t('remember_me')}</label>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="forget-pass">
										<Link to={'/forget-password'} className="forget-pass-btn">
											{t('forget_password')}
										</Link>
									</div>
								</div>
							</div>
							<div className="logreg-btn-wrap">
								<button type="submit" className="logreg-btn">
									{t('log_in')}
								</button>
								<p>
									{t('dont_have_account')}{' '}
									<Link to="/register" className="logreg-btn2">
										{t('sign_up')}
									</Link>
								</p>
							</div>
						</div>
					</form>
				</div>
			</section>
		</>
	);
}

export default Login;
