import { default as api } from 'config/adminApiConf.js';

export const raiseFundService = async (data) => {
	try {
		let result = await api.post(`/funder/raise-fund`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const listRegion = async (data = {}) => {
	try {
		let result = await api.get('/region-list');
		return result;
	} catch (error) {
		return error;
	}
};

export const fundRaiseCatListService = async (data) => {
	try {
		let result = await api.get(`/funder/list-category`);
		return result;
	} catch (error) {
		return error;
	}
};
export const checkUserBankDetailsService = async (data) => {
	try {
		let result = await api.get(`/funder/check-bank-details`);
		return result;
	} catch (error) {
		return error;
	}
};
