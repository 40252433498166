// import axios from "axios";

// const auth_token = localStorage.getItem("auth_token");
// const api = axios.create({
//     baseURL: process.env.REACT_APP_API_URL + "/admin/users",
//     timeout: 3000,
//     headers: { Authorization: "Bearer " + auth_token },
// });

// api.interceptors.response.use((res) => {
//     // Add configurations here
//     if (res.data.status === 401) {
//         window.location.href = "/";
//     }
//     return res;
// });

import { default as api } from 'config/adminApiConf.js';

export const usersList = async (page, search = '') => {
	try {
		let result = await api.get('/users/list?page=' + page + '&search=' + search);
		return result;
	} catch (error) {
		return error;
	}
};
export const userDelete = async (id) => {
	try {
		let result = await api.delete('/users/delete/' + id);
		return result;
	} catch (error) {
		return error;
	}
};
export const userStatusChange = async (id, status, data = {}) => {
	try {
		let result = await api.post(`/users/change-status/${id}/${status}`, data);
		return result;
	} catch (error) {
		return error;
	}
};
