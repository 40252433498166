import React, { useState } from 'react';
import './OrderDetail.scss';
import './OrderDetail.responsive.scss';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import { addRatingService } from './OrderDetail.service.js';
import { toast } from 'react-toastify';

function OrderDetail({ data }) {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const { t } = useTranslation();
	const [productDtl, setProductDtl] = useState({});
	const [vendorProductId, setVendorProductId] = useState(0);

	const lang = useSelector((state) => state.lang);
	const auth = useSelector((state) => state.auth);
	const [refresh, setRefresh] = useState(0);
	const status = [
		{ en: 'Order Placed', fr: 'Commande passée', ar: 'تم الطلب' },
		{ en: 'Delivery Agent Assigned', fr: 'Agent de livraison affecté', ar: 'تم تعيين وكيل التسليم' },
		{ en: 'Shipping Charge Added', fr: "Frais d'expédition ajoutés", ar: 'تمت إضافة رسوم الشحن' },
		{ en: 'Shipping Charge Confirmed', fr: "Frais d'expédition confirmés", ar: 'تم تأكيد رسوم الشحن' },
		{ en: 'Payment Cleard', fr: 'Paiement effacé', ar: 'مسح الدفع' },
		{ en: 'Payment Confirmed', fr: 'Paiement accepté', ar: 'تم تأكيد عملية الدفع' },
		{ en: 'Ready To Ship', fr: "Prêt pour l'expédition", ar: 'جاهز للشحن' },
		{ en: 'Shipped', fr: 'Expédié', ar: 'تم  الشحن' },
		{ en: 'Delivered', fr: 'Livré', ar: 'تم التوصيل' },
		{ en: 'Delivery Confirmed', fr: 'Livraison confirmée', ar: 'تم تأكيد التسليم' },
	];

	const onSubmit = async (data) => {
		let formData = {
			...data,
			vendor_product_id: vendorProductId,
		};
		const result = await addRatingService(formData);
		if (result.data.status === 200) {
			document.querySelector('#reviewModalCloseBtn').click();
			toast.success(`{${t('review.thank_you_for_review')}} !`);
			setRefresh(!refresh);
		} else if (result.data.status === 401) {
			toast.error(`{${t('review.unauthorized_action')}} !`);
		} else {
			toast.error(result.data.error);
		}
	};
	return (
		<>
			<div className="order-details-wrap">
				<div className="row">
					<div className="col-12">
						<div className="order-progress">
							<ul className="steps">
								{status.map((res, i) => {
									return (
										<li key={i} className={`step ${data?.status >= i ? 'is-complete' : ''} ${data?.status + 1 === i ? 'is-active' : ''}`} data-step={i + 1}>
											<span className="prog-name">{res?.[lang]}</span>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
				<div className="row g-3">
					<div className="col-lg-4 col-md-4 col-sm-6 col-12">
						<div className="address-box">
							<h4>{t('admin_order.shipping_details')}</h4>
							<p>
								<strong>{t('admin_order.name')}</strong> : {data?.get_shipping_detail?.first_name} {data?.get_shipping_detail?.last_name}
							</p>
							<p>
								<strong>{t('admin_order.email')}</strong> : {data?.get_shipping_detail?.email}
							</p>
							<p>
								<strong>{t('admin_order.phone_number')}</strong> : {data?.get_shipping_detail?.ph_no}
							</p>
							<p>
								<strong>{t('admin_order.city')}</strong> : {data?.get_shipping_detail?.city}
							</p>
							<p>
								<strong>{t('admin_order.region')}</strong> : {data?.get_shipping_detail?.get_region?.name}
							</p>
							<p>
								<strong>{t('admin_order.address')}</strong> : {data?.get_shipping_detail?.address}
							</p>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-6 col-12">
						<div className="address-box">
							<h4>{t('admin_order.order_details')}</h4>
							<p>
								<strong>{t('admin_order.order_id')}</strong> : {data?.auto_gen_id}
							</p>
							<p>
								<strong>{t('admin_order.order_date')}</strong> : {moment(data?.created_at).format('DD-MM-YYYY')}
							</p>
							<p>
								<strong>{t('admin_order.payment_method')}</strong> : {data?.get_shipping_detail?.payment_method}
							</p>
						</div>
					</div>
					{data.status >= 3 && (
						<>
							<div className="col-lg-4 col-md-4 col-sm-6 col-12">
								<div className="address-box">
									<h4>{t('admin_order.delivery_agent_details')}</h4>
									<p>
										<strong>{t('admin_order.name')}</strong> : {data?.get_delivery_agent_dtl?.[0]?.get_delivery_agent?.full_name}
									</p>
									<p>
										<strong>{t('admin_order.email')}</strong> : {data?.get_delivery_agent_dtl?.[0]?.get_delivery_agent?.email}
									</p>
									<p>
										<strong>{t('admin_order.phone_number')}</strong> : {data?.get_delivery_agent_dtl?.[0]?.get_delivery_agent?.ph_no}
									</p>
								</div>
							</div>
						</>
					)}
				</div>
				<div className="row">
					<div className="col-12">
						<div className="order-product-list table-responsive">
							<table className="table mb-0">
								<thead>
									<tr>
										<th>{t('admin_order.product_name')}</th>
										<th className="align-td-center">{t('admin_order.seller_name_ph_no')}</th>
										<th className="align-td-center">{t('admin_order.qty')}</th>
										<th className="align-td-center">{t('admin_order.price_mru')}</th>
										<th className="align-td-end">{t('admin_order.total')}</th>
									</tr>
								</thead>
								<tbody>
									{data?.get_products?.map((res, i) => {
										return (
											<tr key={i}>
												<td>
													<div className="order-item-product d-flex">
														<Link
															className="order-image"
															to="#"
															onClick={() => {
																setProductDtl(res);
															}}
															data-bs-toggle="modal"
															data-bs-target="#staticBackdrop"
														>
															<img className="img-block" src={res?.get_vendor_product?.image} alt="Product" />
														</Link>
														<div className="order-item-product-wrap">
															<h4>
																{' '}
																<Link
																	to="#"
																	onClick={() => {
																		setProductDtl(res);
																	}}
																	data-bs-toggle="modal"
																	data-bs-target="#staticBackdrop"
																>
																	{res?.get_vendor_product?.name?.[lang]}
																</Link>
															</h4>
															{auth.role === 3 && data.status === 9 && res?.get_vendor_product?.get_my_review?.created_by !== auth.id && (
																<p
																	data-bs-toggle="modal"
																	data-bs-target="#writeReview"
																	onClick={() => {
																		setVendorProductId(res?.vendor_product_id);
																	}}
																>
																	{t('review.write_a_review')}
																</p>
															)}
														</div>
													</div>
												</td>
												<td className="align-td-center">{`${res?.get_vendor?.first_name} ${res?.get_vendor?.last_name} (${res?.get_vendor?.ph_no})`}</td>
												<td className="align-td-center">{res?.quantity}</td>
												<td className="align-td-center">{res?.price}</td>
												<td className="align-td-end">{res?.quantity * res?.price}</td>
											</tr>
										);
									})}
								</tbody>
								<tfoot>
									<tr>
										<td className="align-td-end bb-none" colSpan="4">
											<strong>{t('admin_order.shipping_charge')}</strong> :
										</td>
										<td className="align-td-end bb-none">
											<strong>
												{t('admin_order.mru')} {data?.shipping_charge}
											</strong>
										</td>
									</tr>
									<tr>
										<td className="align-td-end bb-none" colSpan="4">
											<strong>{t('admin_order.total')}</strong> :
										</td>
										<td className="align-td-end bb-none">
											<strong>
												{t('admin_order.mru')} {data?.shipping_charge + data?.total_amount}
											</strong>
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
			</div>

			{/* product detail modal */}
			<div
				className="modal fade cmn-modal"
				id="staticBackdrop"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-xl modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="staticBackdropLabel">
								{t('product')}
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="product-modal-img d-flex align-items-center justify-content-center">
										<img className="img-block" src={productDtl?.get_vendor_product?.image} alt={productDtl?.get_product?.name?.[lang]} />
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 col-12">
									<div className="product-modal-text">
										<h4>{productDtl?.get_vendor_product?.name?.[lang]}</h4>
										<p>
											{t('sold_by')} : {productDtl?.get_vendor?.first_name + ' ' + productDtl?.get_vendor?.last_name}
										</p>
										<h4>
											MRU {productDtl?.price}/{productDtl?.get_vendor_product?.get_measure_unit?.name?.[lang]}
										</h4>
										<p>
											{`${t('review.quality_available')}`} : {productDtl?.get_vendor_product?.quality?.[lang]}
										</p>
										<p>{t('description')}</p>
										<p className="sort-dsc mt-0 text-grey">{productDtl?.get_vendor_product?.description?.[lang]}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Write a review */}
			<div
				className="modal fade cmn-modal"
				id="writeReview"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="writeReviewLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-md modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="writeReviewLabel">
								{t('review.write_a_review')}
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="reviewModalCloseBtn">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<form onSubmit={handleSubmit(onSubmit)}>
								<div>
									<label className="admin-lbl">{t('review.rating')}</label>
								</div>
								<div className="star-rating">
									<input id="star-5" type="radio" name="rating" value="5" {...register('rating', { required: t('review.rating_is_required') })} />
									<label for="star-5" title="5 stars" className="star-gap">
										<i className="active fa fa-star" aria-hidden="true"></i>
									</label>
									<input id="star-4" type="radio" name="rating" value="4" {...register('rating', { required: t('review.rating_is_required') })} />
									<label for="star-4" title="4 stars" className="star-gap">
										<i className="active fa fa-star" aria-hidden="true"></i>
									</label>
									<input id="star-3" type="radio" name="rating" value="3" {...register('rating', { required: t('review.rating_is_required') })} />
									<label for="star-3" title="3 stars" className="star-gap">
										<i className="active fa fa-star" aria-hidden="true"></i>
									</label>
									<input id="star-2" type="radio" name="rating" value="2" {...register('rating', { required: t('review.rating_is_required') })} />
									<label for="star-2" title="2 stars" className="star-gap">
										<i className="active fa fa-star" aria-hidden="true"></i>
									</label>
									<input id="star-1" type="radio" name="rating" value="1" {...register('rating', { required: t('review.rating_is_required') })} />
									<label for="star-1" title="1 star" className="star-gap">
										<i className="active fa fa-star" aria-hidden="true"></i>
									</label>
								</div>
								<div>{errors.rating && <InputErrorMsg error={errors.rating?.message} />}</div>

								<div className="admim-textare-wrap">
									<label className="admin-lbl">{t('review.type_review')}</label>
									<textarea rows={4} className="form-control admim-textare-style" placeholder="Type Review" {...register('review')}></textarea>
								</div>
								<div className="cmn-btn-tag-wrap">
									<button type="submit" className="cmn-btn-tag">
										{t('funding_details.submit')}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default OrderDetail;
