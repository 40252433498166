import { default as adminApi } from 'config/adminApiConf.js';

export const listOrder = async (page) => {
	try {
		let result = await adminApi.get(`/admin-order/complete/list?page=${page}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const getDeliveryAgentService = async (id) => {
	try {
		let result = await adminApi.get(`/admin-order/delivery-service-by-region/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const assignDeliveryAgentService = async (data) => {
	try {
		let result = await adminApi.post(`/admin-order/assign-delivery-service`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const getAssignedDeliveryService = async (data) => {
	try {
		let result = await adminApi.post(`/admin-order/get-assign-delivery-service`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const confirmDeliveryChargeService = async (data) => {
	try {
		let result = await adminApi.post(`/admin-order/confirm-delivery-charge`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const getPaymentReceiptService = async (orderId) => {
	try {
		let result = await adminApi.get(`/admin-order/get-payment-reciept/${orderId}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const confirmPaymentService = async (data) => {
	try {
		let result = await adminApi.post(`/admin-order/confirm-payment`, data);
		return result;
	} catch (error) {
		return error;
	}
};
