import { default as api } from 'config/adminApiConf.js';

export const listRaiseFundService = async (page) => {
	try {
		let result = await api.get(`/fund/admin-fund-raise-list?page=${page}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const RaiseFundStatusChangeService = async (id, status) => {
	try {
		let result = await api.get(`/fund/raise-fund-status-change/${id}/${status}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const clearPaymentService = async (data) => {
	try {
		let result = await api.post(`/payout/clear-fund-payment`, data);
		return result;
	} catch (error) {
		return error;
	}
};
