import { default as api } from 'config/adminApiConf.js';

export const listCountry = async (data = {}) => {
	try {
		let result = await api.get('/country-list');
		return result;
	} catch (error) {
		return error;
	}
};

export const getUserDtl = async (id) => {
	try {
		let result = await api.get('/users/get-user-dtl/' + id);
		return result;
	} catch (error) {
		return error;
	}
};

export const listRole = async (data = {}) => {
	try {
		let result = await api.get('/role-list');
		return result;
	} catch (error) {
		return error;
	}
};
export const listRegion = async (data = {}) => {
	try {
		let result = await api.get('/region-list');
		return result;
	} catch (error) {
		return error;
	}
};

export const editUser = async (data = {}, id) => {
	try {
		let result = await api.put('/users/edit/' + id, data);
		return result;
	} catch (error) {
		return error;
	}
};
