import { default as api } from 'config/adminApiConf.js';

export const myDonationService = async (page) => {
	try {
		let result = await api.get('/fund/my-donation?page=' + page);
		return result;
	} catch (error) {
		return error;
	}
};

export const clearPaymentService = async (data) => {
	try {
		let result = await api.post('/fund/donation-payment-receipt', data);
		return result;
	} catch (error) {
		return error;
	}
};
