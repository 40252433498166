import React from 'react';
import './Partner.scss';
import './Partner.responsive.scss';
import partner1 from 'assets/images/partner/partner-logo.png';
import partner2 from 'assets/images/partner/partner-logo1.jpeg';
import partner3 from 'assets/images/partner/partner-logo2.png';
import { useTranslation } from 'react-i18next';

function Partner() {
	const { t } = useTranslation();
	return (
		<>
			<section className="partner-home-sec">
				<div className="container-fluid left-right-40">
					<div className="row">
						<div className="col-12">
							<div className="sec-heading">
								<h3>{t('our_partners')}</h3>
							</div>
						</div>
					</div>
					<div className="partner-wrap d-flex justify-content-center">
						<div className="partner-box">
							<span className="partner-box-img">
								<img className="img-block" src={partner1} alt="partner one" />
							</span>
						</div>
						<div className="partner-box">
							<span className="partner-box-img">
								<img className="img-block" src={partner2} alt="partner two" />
							</span>
						</div>
						<div className="partner-box">
							<span className="partner-box-img">
								<img className="img-block" src={partner3} alt="partner three" />
							</span>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Partner;
