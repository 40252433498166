import React from 'react';
import './Footer.scss';
import './Footer.responsive.scss';
import footer_logo from 'assets/images/ftr-logo.png';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
function Footer() {
	const { t } = useTranslation();
	const lang = useSelector((state) => state.lang);
	const homeContent = useSelector((state) => state?.content?.home);
	return (
		<>
			<footer className="footer">
				<div className="container-fluid left-right-40">
					<div className="row">
						<div className="col-lg-3 col-md-3 col-sm-6 col-12">
							<div className="ftr-about">
								<span className="ftr-logo">
									<img className="img-block" src={footer_logo} alt />
								</span>
								<ul className="d-flex ftr-social">
									<li>
										<a href={'#!'}>
											<i className="fab fa-facebook-f" />
										</a>
									</li>
									<li>
										<a href={'#!'}>
											<i className="fab fa-youtube" />
										</a>
									</li>
									<li>
										<a href={'#!'}>
											<i className="fab fa-twitter" />
										</a>
									</li>
									<li>
										<a href={'#!'}>
											<i className="fab fa-linkedin" />
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-6 col-12">
							<div className="ftr-menu-box">
								<h4>{t('information')}</h4>
								<ul>
									<li>
										<a href={'#how_to_use_platfrom'}>
											<span
												dangerouslySetInnerHTML={{
													__html: `${homeContent?.how_to_use_platform?.[lang].substring(0, 249)} ...` ?? t('hassad_digital_platform'),
												}}
											></span>
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-6 col-12">
							<div className="ftr-menu-box">
								<h4>{t('my_account')}</h4>
								<ul>
									<li>
										<Link to="/my-order">{t('my_order')}</Link>
									</li>
									<li>
										<Link to="/my-donation">{'My Donation'}</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-6 col-12">
							<div className="ftr-menu-box">
								<h4>{t('contact_info')}</h4>

								<ul className="cntct-info">
									<li>
										<a href={'#!'}>
											<i className="fas fa-map-marker" />
											<div
												dangerouslySetInnerHTML={{
													__html:
														homeContent?.contact_info?.address?.[lang] ??
														"center d'innovation agrocole<br /> Institut supérieur d’enseignement technologique <br /> Mauritania",
												}}
											></div>
										</a>
									</li>
									<li>
										<a href={'#!'}>
											<i className="fas fa-envelope" />
											{homeContent?.contact_info?.email ? homeContent?.contact_info?.email : 'email@email.com'}
										</a>
									</li>
									<li>
										<a href={'#!'}>
											<i className="fas fa-phone-alt" />
											{homeContent?.contact_info?.ph_no ? homeContent?.contact_info?.ph_no : '+222 30 00 00'}
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<section className="ftr-btm">
					<div className="container-fluid left-right-40">
						<div className="row">
							<div className="col-12">
								<div className="ftr-btm-text">
									<p>
										© 2022 by Hadina RIMTIC. Designed & Developed by
										<a href="http://www.aqualeafitsol.com/">Aqualeaf It Solutions Pvt. Ltd.</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			</footer>
		</>
	);
}

export default Footer;
