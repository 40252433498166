import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from 'pages/Home';
import Login from 'pages/Login';
import Register from 'pages/Register';
import ForgetPassword from 'pages/ForgetPassword';
import NotFound from 'components/NotFound';
import Dashboard from 'pages/Admin/Dashboard';
import AdminLayout from 'pages/Admin/AdminLayout';
import Profile from 'pages/Admin/Profile';
import EditProfile from 'pages/Admin/EditProfile';
import Users from 'pages/Admin/Users';
import EditUser from 'pages/Admin/EditUser';
import AddUser from 'pages/Admin/AddUser';
import ListCategory from 'pages/Admin/ListCategory';
import SubCategory from 'pages/Admin/SubCategory';
import AddProduct from 'pages/Admin/AddProduct';
import ListProduct from 'pages/Admin/ListProduct';
import EditProduct from 'pages/Admin/EditProduct';
import MarketPlace from 'pages/MarketPlace';
import AddVendorProduct from 'pages/Admin/AddVendorProduct';
import ListVendorProduct from 'pages/Admin/ListVendorProduct';
import EditVendorProduct from 'pages/Admin/EditVendorProduct';

import OrderList from 'pages/Admin/OrderList';
import MyOrder from 'pages/MyOrder';
import About from 'pages/About';
import DataObservatory from 'pages/DataObservatory';
import Funding from 'pages/Funding';
import Forum from 'pages/Forum';
import FundDetails from 'pages/FundDetails';
import Cart from 'pages/Cart';
import Checkout from 'pages/Checkout';
import OrderSucess from 'pages/OrderSuccess';

import AddProductRequest from 'pages/Admin/AddProductRequest';
import ProductRequest from 'pages/Admin/ProductRequest';
import AdminProductRequest from 'pages/Admin/AdminProductRequest';

import AdminOrderList from 'pages/Admin/AdminOrderList';
import AdminCompleteOrderList from 'pages/Admin/AdminCompleteOrderList';
import DeliveryServiceOrderList from 'pages/Admin/DeliveryServiceOrderList';

import FundCategory from 'pages/Admin/FundCategory';
import AddFundRaise from 'pages/Admin/AddFundRaise/AddFundRaise';
import ListFundRaise from 'pages/Admin/ListFundRaise';
import MyDonation from 'pages/Admin/MyDonation';
import DonationList from 'pages/Admin/DonationList';
import AdminFundRaiseList from 'pages/Admin/AdminFundRaiseList';
import FrontMyDonation from 'pages/FrontMyDonation';

import EditBankDetail from 'pages/Admin/EditBankDetail';

// cms
import AboutUsContent from 'pages/Admin/AboutUsContent';
import DataObvervatoryContent from 'pages/Admin/DataObvervatoryContent';
import HomeContent from 'pages/Admin/HomeContent';

import AddForum from 'pages/Admin/AddForum';
import ListForum from 'pages/Admin/ListForum';
import EditForum from 'pages/Admin/EditForum';

import PayoutOrderList from 'pages/Admin/PayoutOrderList';

import ListEvent from 'pages/Admin/ListEvent';
import AddEvent from 'pages/Admin/AddEvent';
import EditEvent from 'pages/Admin/EditEvent';
import Events from 'pages/Events/Events';

import ListRequestForm from 'pages/Admin/ListRequestForm/ListRequestForm';
import AddRequestForm from 'pages/Admin/AddRequestForm/AddRequestForm';
import EditRequestForm from 'pages/Admin/EditRequestForm/EditRequestForm';

import ListEventRegister from 'pages/Admin/ListEventRegister/ListEventRegister';
import EditFundRaise from 'pages/Admin/EditFundRaise/EditFundRaise';

const AppRouter = () => {
	return (
		<Routes>
			<Route path="*" element={<NotFound />} /> <Route path="/" element={<Home />} /> <Route path="/home" element={<Home />} /> <Route path="/about-us" element={<About />} />{' '}
			<Route path="/market-place" element={<MarketPlace />} /> <Route path="/data-observatory" element={<DataObservatory />} />{' '}
			<Route path="/funding" element={<Funding />} /> <Route path="/forum" element={<Forum />} /> <Route path="/events" element={<Events />} />{' '}
			<Route path="/login" element={<Login />} /> <Route path="/register" element={<Register />} /> <Route path="/forget-password" element={<ForgetPassword />} />{' '}
			<Route path="/fund-details/:id" element={<FundDetails />} /> <Route path="/my-cart" element={<Cart />} /> <Route path="/checkout" element={<Checkout />} />{' '}
			<Route path="/order-success" element={<OrderSucess />} /> {/* <Route path="/otp-confirm" element={<OtpConfirm />} /> */}
			{/* admin route */}{' '}
			<Route path="/admin" element={<AdminLayout />}>
				<Route path="dashboard" element={<Dashboard />} /> <Route path="profile" element={<Profile />} /> <Route path="edit-profile" element={<EditProfile />} />{' '}
				<Route path="users" element={<Users />} /> <Route path="users/add" element={<AddUser />} /> <Route path="user/edit/:id" element={<EditUser />} />{' '}
				<Route path="my-donation" element={<MyDonation />} />
				<Route path="bank-details" element={<EditBankDetail />} />
				{/* category route */} <Route path="category/list" element={<ListCategory />} /> {/* end category route */}
				{/* sub category route */} <Route path="sub-category/list" element={<SubCategory />} /> {/* end sub category route */}
				{/* product route */} <Route path="product/add" element={<AddProduct />} /> <Route path="product/list" element={<ListProduct />} />{' '}
				<Route path="product/edit/:id" element={<EditProduct />} /> <Route path="product/product-request" element={<AdminProductRequest />} /> {/* END product route */}
				{/* vendor product route */} <Route path="vendor-product/add" element={<AddVendorProduct />} /> <Route path="vendor-product/list" element={<ListVendorProduct />} />{' '}
				<Route path="vendor-product/edit/:id" element={<EditVendorProduct />} /> {/* end vendor product route */}
				{/* vendor order */} <Route path="vendor-order/list" element={<OrderList />} /> {/* end vendor order */}
				{/* vendor product request route */} <Route path="vendor-product-req/add" element={<AddProductRequest />} />{' '}
				<Route path="vendor-product-req/list" element={<ProductRequest />} /> {/* end product request route */}
				{/* admin order route */} <Route path="order/list" element={<AdminOrderList />} /> <Route path="complete-order/list" element={<AdminCompleteOrderList />} />{' '}
				{/* end admin order route */}
				{/* delivery service route */} <Route path="delivery-order/list" element={<DeliveryServiceOrderList />} /> {/* end delivery service route */}
				{/* fund category route */} <Route path="fund/category" element={<FundCategory />} /> {/* end fund category route */}
				{/* fund raiser route */} <Route path="fund-raiser/add-fund" element={<AddFundRaise />} /> <Route path="fund-raiser/list-fund" element={<ListFundRaise />} />{' '}
				{/* end fund raiser route */}
				{/* admin fund roter */} <Route path="fund/donaiton-list" element={<DonationList />} /> <Route path="fund/raise-fund-list" element={<AdminFundRaiseList />} />{' '}
				{/* end admin fund route */}
				{/* admin cms */} <Route path="cms/about-us" element={<AboutUsContent />} /> <Route path="cms/data-observatory" element={<DataObvervatoryContent />} />{' '}
				<Route path="cms/home" element={<HomeContent />} /> {/* end admin cms */}
				{/* forum route */} <Route path="forum/add" element={<AddForum />} /> <Route path="forum/list" element={<ListForum />} />{' '}
				<Route path="forum/edit/:id" element={<EditForum />} /> {/* end forum route */}
				{/* admin payout */} <Route path="payout/order-list" element={<PayoutOrderList />} /> {/* Event route */} <Route path="event/add" element={<AddEvent />} />{' '}
				<Route path="event/list" element={<ListEvent />} /> <Route path="event/edit/:id" element={<EditEvent />} />{' '}
				<Route path="event/register/list" element={<ListEventRegister />} /> {/* End Event Route */} {/* Request Form */}{' '}
				<Route path="request-form/list" element={<ListRequestForm />} /> <Route path="request-form/add" element={<AddRequestForm />} />{' '}
				<Route path="request-form/edit/:id" element={<EditRequestForm />} /> {/* End Request Form */} <Route path="fund/edit-fund/:id" element={<EditFundRaise />} />{' '}
			</Route>{' '}
			{/* end admin route */}
			{/* customer route */} <Route path="/my-order" element={<MyOrder />} /> <Route path="/my-donation" element={<FrontMyDonation />} /> {/* end customer route */}{' '}
		</Routes>
	);
};

export default AppRouter;
