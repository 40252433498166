import { default as api } from 'config/adminApiConf.js';

export const listEventRegisterService = async (page, search = '', lang) => {
	try {
		let result = await api.get('/event-register/list?page=' + page + '&search=' + search + '&lang=' + lang);
		return result;
	} catch (error) {
		return error;
	}
};
