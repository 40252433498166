import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import allActions from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { getCartService, cartQtyUpdateService, removeFromCartService } from './Cart.service.js';
import './Cart.scss';
import { Link } from 'react-router-dom';
import cartImg from 'assets/images/empty-cart.gif';
import forum_bg from 'assets/images/forum.jpg';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function Cart() {
	const [cartData, setCartData] = useState([]);
	const lang = useSelector((state) => state.lang);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());
		getCart();
	}, []);

	const getCart = async () => {
		let cart = await getCartService();
		if (cart.data.status === 200) {
			console.log('cart data : ', cart.data.data.cart);
			setCartData(cart.data.data.cart);
			setTimeout(() => {
				dispatch(allActions.loadingAction.hideLoading());
			}, 1000);
		}
	};
	const removeFromCart = async (productId = null) => {
		dispatch(allActions.loadingAction.showLoading());
		let formData = {
			product_id: productId,
		};
		let result = await removeFromCartService(formData);
		if (result.data.status === 200) {
			getCart();

			dispatch(allActions.cartAction.removeFromCart(productId));
		}
	};
	const handleQty = async (productId = null, type = null, qty = null, index = null) => {
		if (!Number(qty)) {
			return false;
		}
		if (type === 'update') {
			let arr = [...cartData];
			arr[index].qty = qty;
			setCartData(arr);
		}
		if (type !== 'update') {
			dispatch(allActions.loadingAction.showLoading());
		}
		if (type === 'decrement' && qty <= 1) {
			dispatch(allActions.loadingAction.hideLoading());
			return false;
		}

		let formData = {
			product_id: productId,
			type,
			qty,
		};
		let result = await cartQtyUpdateService(formData);
		if (result.data.status === 200) {
			getCart();
		} else {
			await getCart();
			toast.error(result.data.error);
		}
	};

	var total = 0;
	return (
		<>
			<Header />
			<section className="inner-banner-sec d-flex align-items-center justify-content-center" style={{ background: `url(${forum_bg}) no-repeat` }}>
				<div className="inner-banner-head">
					<h3>{t('cart_page.cart')}</h3>
				</div>
			</section>
			<section className="about-content-sec">
				<div className="container-fluid left-right-40">
					{cartData.length > 0 ? (
						<div className="row">
							<div className="col-12">
								<div className="cartpage w-100 table-responsive">
									<div className="table-responsive">
										<table className="table text-nowrap">
											<thead>
												<tr>
													<th className="first-box">{t('admin_dashboard_menu.product')}</th>
													<th className="text-center">{t('payout_order.price')}</th>
													<th className="text-center">{t('payout_order.quantity')}</th>
													<th className="last-box">{t('admin_order.total')}</th>
												</tr>
											</thead>
											<tbody>
												{cartData.map((val, i) => {
													total += val?.get_vendor_product?.price * val?.qty;

													return (
														<tr key={i}>
															<td>
																<div className="cart-item-product d-flex">
																	<Link className="cart-image" to="#">
																		<img className="img-block" src={val?.get_vendor_product?.image} alt="Product" />
																	</Link>
																	<div className="cart-item-product-wrap">
																		<h4>{val?.get_vendor_product?.name?.[lang]}</h4>
																		<Link
																			className="cart-item-remove"
																			to="#"
																			onClick={() => {
																				removeFromCart(val?.vendor_product_id);
																			}}
																		>
																			<i className="fas fa-trash-alt"></i>Remove
																		</Link>
																	</div>
																</div>
															</td>
															<td className="total-price text-center">MRU {val?.get_vendor_product?.price}</td>
															<td>
																<div className="cart-item-quantity d-flex justify-content-center w-100">
																	<button
																		className="qty-btn"
																		type="button"
																		onClick={() => {
																			handleQty(val?.vendor_product_id, 'decrement', val?.qty);
																		}}
																	>
																		<i className="fas fa-minus"></i>
																	</button>
																	<div className="qty-input-box d-flex position-relative">
																		<input
																			className="qty-input form-control"
																			type="number"
																			value={val?.qty}
																			onChange={(e) => {
																				handleQty(val?.vendor_product_id, 'update', e.target.value, i);
																			}}
																		/>
																		<span className="qty-type">{val?.get_vendor_product?.get_measure_unit?.name?.[lang]}</span>
																	</div>
																	<button
																		className="qty-btn"
																		type="button"
																		onClick={() => {
																			handleQty(val?.vendor_product_id, 'increment', val?.qty);
																		}}
																	>
																		<i className="fas fa-plus"></i>
																	</button>
																</div>
															</td>
															<td className="total-price last-box"> MRU {val?.get_vendor_product?.price * val?.qty} </td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className="col-12">
								<div className="row justify-content-end align-items-center">
									<div className="col-auto">
										<div className="total-amount">
											<p>
												{t('admin_order.total')} : MRU {total}
											</p>
										</div>
									</div>
									<div className="col-auto">
										<Link to="/checkout" className="checkout-btn">
											{t('cart_page.checkout')}
										</Link>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="row">
							<div className="col-md-12">
								<div className="col-sm-12 empty-cart-cls text-center shadow-box">
									<img src={cartImg} width={250} className="img-fluid mb-4 mr-3" />
									<h3>
										<strong>{t('cart_page.your_cart_is_empty')}</strong>
									</h3>
									<h4>{t('cart_page.Add_something_to_make_me_happy')}</h4>
									<Link to="/market-place" className="cmn-a-tag m-3" data-abc="true">
										{t('cart_page.continue_shopping')}
									</Link>
								</div>
							</div>
						</div>
					)}
				</div>
			</section>
			<Footer />
		</>
	);
}

export default Cart;
