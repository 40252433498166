import { default as api } from 'config/adminApiConf.js';

export const addForumService = async (data) => {
	try {
		let result = await api.post(`/forum/create`, data);
		return result;
	} catch (error) {
		return error;
	}
};
