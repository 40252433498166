import { default as api } from 'config/adminApiConf.js';

export const listOrder = async (page) => {
	try {
		let result = await api.get(`/vendor-order/list?page=${page}`);
		return result;
	} catch (error) {
		return error;
	}
};
// export const changeOrderStausService = async(data) => {
//     try {
//         let result = await api.post(`/order/change-order-status`, data);
//         return result;
//     } catch (error) {
//         return error;
//     }
// };

export const readyToShipService = async (data) => {
	try {
		let result = await api.post(`/vendor-order/ready-to-ship`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const productShipService = async (data) => {
	try {
		let result = await api.post(`/vendor-order/product-shipped`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const requestPayoutService = async (data) => {
	try {
		let result = await api.post(`/vendor-order/payout-request`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const getPaymentReceiptService = async (orderProductId) => {
	try {
		let result = await api.get(`/vendor-order/get-payout-order-reciept/${orderProductId}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const confirmPaymentService = async (data) => {
	try {
		let result = await api.post(`/vendor-order/confirm-payout-order-payment`, data);
		return result;
	} catch (error) {
		return error;
	}
};
