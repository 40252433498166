import { default as api } from 'config/adminApiConf.js';

export const subCatList = async (page) => {
	try {
		let result = await api.get('/products/sub-category/list?page=' + page);
		return result;
	} catch (error) {
		return error;
	}
};

export const catList = async () => {
	try {
		let result = await api.get('/products/sub-category/cat-list');
		return result;
	} catch (error) {
		return error;
	}
};

export const addSubCategory = async (data) => {
	try {
		let result = await api.post('/products/sub-category/create', data);
		return result;
	} catch (error) {
		return error;
	}
};
export const subCategoryDtl = async (id) => {
	try {
		let result = await api.get(`/products/sub-category/detail/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const editSubCategory = async (id, data = {}) => {
	try {
		let result = await api.put(`/products/sub-category/edit/${id}`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const delSubCategory = async (id) => {
	try {
		let result = await api.delete(`/products/sub-category/delete/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};
