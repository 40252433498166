import React from 'react';

import OwlCarousel from 'react-owl-carousel';
import { useSelector } from 'react-redux';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarouselRtl from 'react-owl-carousel-rtl';
import './HomeBanner.scss';
import './HomeBanner.responsive.scss';
import slider1 from 'assets/images/slider1.jpeg';
import slider2 from 'assets/images/slider2.jpeg';
import slider3 from 'assets/images/slider3.jpeg';
import slider4 from 'assets/images/slider4.jpeg';
import slider5 from 'assets/images/slider5.jpeg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
function HomeBanner() {
	const { t } = useTranslation();
	const lang = useSelector((state) => state.lang);
	const homeContent = useSelector((state) => state?.content?.home);
	var options = {};
	if (lang === 'ar') {
		options = {
			autoplay: true,
			rtl: true,
			autoplayTimeout: 5000,
			smartSpeed: 2000,
			animateOut: 'fadeOut',
			loop: true,
			margin: 20,
			nav: true,
			dots: false,
			navElement: 'div',
			navText: ["<i class='fas fa-arrow-alt-circle-left'></i>", "<i class='fas fa-arrow-alt-circle-right'></i>"],
			responsive: {
				0: {
					items: 1,
				},
				600: {
					items: 1,
				},
				1000: {
					items: 1,
				},
			},
		};
	} else {
		options = {
			autoplay: true,
			autoplayTimeout: 5000,
			smartSpeed: 2000,
			animateOut: 'fadeOut',
			loop: true,
			margin: 20,
			nav: true,
			dots: false,
			navElement: 'div',
			navText: ["<i class='fas fa-arrow-alt-circle-left'></i>", "<i class='fas fa-arrow-alt-circle-right'></i>"],
			responsive: {
				0: {
					items: 1,
				},
				600: {
					items: 1,
				},
				1000: {
					items: 1,
				},
			},
		};
	}
	return (
		<>
			<section className="slider-wrap index-z">
				{homeContent?.banner?.length !== undefined && (
					<div className="home-slider">
						{lang === 'ar' ? (
							<>
								{homeContent?.banner?.length > 0 ? (
									<>
										<OwlCarouselRtl className="owl-theme" {...options}>
											<>
												{homeContent?.banner?.map((val, i) => (
													<div className="item">
														<div className="bannar-text-wrap">
															<img src={val?.img} alt="" />
															<div className="bannar-text-box">
																<h2 dangerouslySetInnerHTML={{ __html: val?.description?.[lang] }}></h2>
																<ul className="bannar-btn d-flex justify-content-center">
																	<li>
																		<Link to="/register">{t('join_us')}</Link>
																	</li>
																	<li>
																		<Link to="/login">{t('sign_in')}</Link>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												))}
											</>
										</OwlCarouselRtl>
									</>
								) : (
									<>
										<OwlCarouselRtl className="owl-theme" {...options}>
											<div className="item">
												<div className="bannar-text-wrap">
													<img src={slider1} alt="" />
													<div className="bannar-text-box">
														<h2>{t('connect_small_scale')}</h2>
														<ul className="bannar-btn d-flex justify-content-center">
															<li>
																<Link to="/register">{t('join_us')}</Link>
															</li>
															<li>
																<Link to="/login">{t('sign_in')}</Link>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div className="item">
												<div className="bannar-text-wrap">
													<img src={slider2} alt="" />
													<div className="bannar-text-box">
														<h2>{t('provide_farmers')}</h2>
														<ul className="bannar-btn d-flex justify-content-center">
															<li>
																<Link to="/register">{t('join_us')}</Link>
															</li>
															<li>
																<Link to="/login">{t('sign_in')}</Link>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div className="item">
												<div className="bannar-text-wrap">
													<img src={slider3} alt="" />
													<div className="bannar-text-box">
														<h2>{t('training_and_access')}</h2>
														<ul className="bannar-btn d-flex justify-content-center">
															<li>
																<Link to="/register">{t('join_us')}</Link>
															</li>
															<li>
																<Link to="/login">{t('sign_in')}</Link>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div className="item">
												<div className="bannar-text-wrap">
													<img src={slider4} alt="" />
													<div className="bannar-text-box">
														<h2>{t('advance_research')}</h2>
														<ul className="bannar-btn d-flex justify-content-center">
															<li>
																<Link to="/register">{t('join_us')}</Link>
															</li>
															<li>
																<Link to="/login">{t('sign_in')}</Link>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div className="item">
												<div className="bannar-text-wrap">
													<img src={slider5} alt="" />
													<div className="bannar-text-box">
														<h2>{t('knowledge_sharing')}</h2>
														<ul className="bannar-btn d-flex justify-content-center">
															<li>
																<Link to="/register">{t('join_us')}</Link>
															</li>
															<li>
																<Link to="/login">{t('sign_in')}</Link>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</OwlCarouselRtl>
									</>
								)}
							</>
						) : (
							<>
								{homeContent?.banner?.length > 0 ? (
									<>
										<OwlCarousel className="owl-theme" {...options}>
											<>
												{homeContent?.banner?.map((val, i) => (
													<div className="item">
														<div className="bannar-text-wrap">
															<img src={val?.img} alt="" />
															<div className="bannar-text-box">
																<h2 dangerouslySetInnerHTML={{ __html: val?.description?.[lang] }}></h2>
																<ul className="bannar-btn d-flex justify-content-center">
																	<li>
																		<Link to="/register">{t('join_us')}</Link>
																	</li>
																	<li>
																		<Link to="/login">{t('sign_in')}</Link>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												))}
											</>
										</OwlCarousel>
									</>
								) : (
									<>
										<OwlCarousel className="owl-theme" {...options}>
											<div className="item">
												<div className="bannar-text-wrap">
													<img src={slider1} alt="" />
													<div className="bannar-text-box">
														<h2>{t('connect_small_scale')}</h2>
														<ul className="bannar-btn d-flex justify-content-center">
															<li>
																<Link to="/register">{t('join_us')}</Link>
															</li>
															<li>
																<Link to="/login">{t('sign_in')}</Link>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div className="item">
												<div className="bannar-text-wrap">
													<img src={slider2} alt="" />
													<div className="bannar-text-box">
														<h2>{t('provide_farmers')}</h2>
														<ul className="bannar-btn d-flex justify-content-center">
															<li>
																<Link to="/register">{t('join_us')}</Link>
															</li>
															<li>
																<Link to="/login">{t('sign_in')}</Link>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div className="item">
												<div className="bannar-text-wrap">
													<img src={slider3} alt="" />
													<div className="bannar-text-box">
														<h2>{t('training_and_access')}</h2>
														<ul className="bannar-btn d-flex justify-content-center">
															<li>
																<Link to="/register">{t('join_us')}</Link>
															</li>
															<li>
																<Link to="/login">{t('sign_in')}</Link>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div className="item">
												<div className="bannar-text-wrap">
													<img src={slider4} alt="" />
													<div className="bannar-text-box">
														<h2>{t('advance_research')}</h2>
														<ul className="bannar-btn d-flex justify-content-center">
															<li>
																<Link to="/register">{t('join_us')}</Link>
															</li>
															<li>
																<Link to="/login">{t('sign_in')}</Link>
															</li>
														</ul>
													</div>
												</div>
											</div>
											<div className="item">
												<div className="bannar-text-wrap">
													<img src={slider5} alt="" />
													<div className="bannar-text-box">
														<h2>{t('knowledge_sharing')}</h2>
														<ul className="bannar-btn d-flex justify-content-center">
															<li>
																<Link to="/register">{t('join_us')}</Link>
															</li>
															<li>
																<Link to="/login">{t('sign_in')}</Link>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</OwlCarousel>
									</>
								)}
							</>
						)}
					</div>
				)}
			</section>
		</>
	);
}

export default HomeBanner;
