import React, { useEffect, useState } from 'react';
import Breadcrumb from 'components/Admin/Breadcrumb';
import Alert from 'components/Alert';
import DataTable from 'react-data-table-component';
import { listOrder, readyToShipService, productShipService, requestPayoutService, getPaymentReceiptService, confirmPaymentService } from './OrderList.service.js';
import moment from 'moment';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function OrderList() {
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [orders, setOrders] = useState([]);
	const [orderMeta, setOrderMeta] = useState({});
	const [page, setPage] = useState(1);
	const [paymentReceipt, setPaymentReceipt] = useState({});
	const [orderProductId, setOrderProductId] = useState(0);
	const { t } = useTranslation();

	useEffect(() => {
		getOrderList();
	}, [page]);

	const getOrderList = async () => {
		let list = await listOrder(page);
		console.log(list);
		if (list.data.status === 200) {
			setOrders(list.data.data.records);
			setOrderMeta(list.data.data.meta);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};

	const readyToShip = (orderProductId, orderId) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-confirmation-box">
						<h1>{t('admin_user.are_you_sure')}</h1>
						<p>{t('vendor_order.you_want_to_do_this')}</p>
						<button onClick={onClose} className="no-btn">
							{t('admin_user.no')}
						</button>
						<button
							className="yes-btn"
							onClick={async () => {
								let formdata = {
									order_product_id: orderProductId,
									order_id: orderId,
								};

								let result = await readyToShipService(formdata);
								if (result.data.status === 200) {
									await getOrderList();
									toast.success(`{${t('vendor_order.product_is_ready_to_ship')}}`);
								} else {
									toast.error(result.data.error);
									window.scrollTo(0, 0);
								}
								onClose();
							}}
						>
							{t('admin_user.yes')}
						</button>
					</div>
				);
			},
		});
	};

	const orderShipped = (orderProductId, orderId) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-confirmation-box">
						<h1>{t('admin_user.are_you_sure')}</h1>
						<p>{t('vendor_order.you_want_to_do_this')}</p>
						<button onClick={onClose} className="no-btn">
							{t('admin_user.no')}
						</button>
						<button
							className="yes-btn"
							onClick={async () => {
								let formdata = {
									order_product_id: orderProductId,
									order_id: orderId,
								};

								let result = await productShipService(formdata);
								if (result.data.status === 200) {
									await getOrderList();
									toast.success(`{${t('vendor_order.product_has_shipped')}} !`);
								} else {
									toast.error(result.data.error);
									window.scrollTo(0, 0);
								}
								onClose();
							}}
						>
							{t('admin_user.yes')}
						</button>
					</div>
				);
			},
		});
	};

	const requestPayout = (orderProductId) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-confirmation-box">
						<h1>{t('admin_user.are_you_sure')}</h1>
						<p>{t('vendor_order.you_want_to_do_this')}</p>
						<button onClick={onClose} className="no-btn">
							{t('admin_user.no')}
						</button>
						<button
							className="yes-btn"
							onClick={async () => {
								let formdata = {
									order_product_id: orderProductId,
								};

								let result = await requestPayoutService(formdata);
								if (result.data.status === 200) {
									await getOrderList();
									toast.success(`{${t('vendor_order.payout_requested')}} !`);
								} else {
									toast.error(result.data.error);
									window.scrollTo(0, 0);
								}
								onClose();
							}}
						>
							{t('admin_user.yes')}
						</button>
					</div>
				);
			},
		});
	};

	const showPaymentReceipt = async (id) => {
		setOrderProductId(id);
		let result = await getPaymentReceiptService(id);

		if (result.data.status === 200) {
			console.log('payment receipt ', result.data.data.payment_receipt);
			setPaymentReceipt(result.data.data.payment_receipt);
		}
	};

	const confirmPayment = async () => {
		let formData = {
			order_product_id: orderProductId,
		};
		let result = await confirmPaymentService(formData);
		if (result.data.status === 200) {
			await getOrderList();
			document.querySelector('#confirmPaymentModalClose').click();
			toast.success(`{${t('admin_order.payment_confirmed')}} !`);
		} else {
			toast.error(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const columns = [
		{
			name: t('admin_order.order_id'),
			selector: (row) => <div className="order-id-text">{row?.get_order?.auto_gen_id}</div>,
			sortable: true,
		},
		{
			name: t('admin_order.order_date'),
			selector: (row) => moment(row?.created_at).format('DD-MM-YYYY'),
			sortable: true,
		},
		{
			name: t('payout_order.order_by'),
			selector: (row) => row?.get_order?.get_shipping_detail?.first_name + ' ' + row?.get_order?.get_shipping_detail?.last_name,
			sortable: true,
		},
		{
			name: t('payout_order.product_image'),
			cell: (row) => (
				<span className="list-table-img">
					<img className="img-block" src={row?.get_vendor_product?.image} alt="Product" />
				</span>
			),
		},
		{
			name: t('payout_order.product_name'),
			selector: (row) => row?.get_vendor_product?.name?.en,
			sortable: true,
		},
		{
			name: t('payout_order.price_mru'),
			selector: (row) => row?.price,
			sortable: true,
		},
		{
			name: t('payout_order.quantity'),
			selector: (row) => row?.quantity + ` (${row?.get_vendor_product?.get_measure_unit?.name?.en})`,
			sortable: true,
		},
		{
			name: t('payout_order.total_amount_mru'),
			selector: (row) => row?.quantity * row?.price,
			sortable: true,
		},

		{
			name: t('admin_user.status'),
			selector: (row) => {
				if (row.status === 0) {
					return <span className="badge bg-warning text-dark">{t('admin_my_donation.pending')}</span>;
				}
				if (row.status === 1) {
					return <span className="badge bg-primary">{t('admin_order.ready_to_ship')}</span>;
				}
				if (row.status === 2) {
					return <span className="badge bg-success">{t('admin_order.shipped')}</span>;
				}
			},
			sortable: false,
		},
		{
			name: t('payout_order.payout_status'),
			selector: (row) => {
				if (row.payout_status === 0) {
					return <span className="badge bg-warning text-dark">{t('payout_order.not_requested')}</span>;
				}
				if (row.payout_status === 1) {
					return <span className="badge bg-primary">{t('payout_order.requested')}</span>;
				}
				if (row.payout_status === 2) {
					return <span className="badge bg-success">{t('payout_order.payment_cleared')}</span>;
				}
				if (row.payout_status === 3) {
					return <span className="badge bg-success">{t('payout_order.confirmed')}</span>;
				}
			},
			sortable: false,
		},
		{
			name: t('admin_user.action'),
			cell: (row) => (
				<div className="dropdown">
					<div className="actionList " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg
							style={{ cursor: 'pointer' }}
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-sliders dropdown-toggle"
							data-toggle="dropdown"
							role="button"
							aria-expanded="false"
						>
							<line x1={4} y1={21} x2={4} y2={14} />
							<line x1={4} y1={10} x2={4} y2={3} />
							<line x1={12} y1={21} x2={12} y2={12} />
							<line x1={12} y1={8} x2={12} y2={3} />
							<line x1={20} y1={21} x2={20} y2={16} />
							<line x1={20} y1={12} x2={20} y2={3} />
							<line x1={1} y1={14} x2={7} y2={14} />
							<line x1={9} y1={8} x2={15} y2={8} />
							<line x1={17} y1={16} x2={23} y2={16} />
						</svg>
					</div>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						{row.status === 0 && row?.get_order?.status === 5 && (
							<>
								<Link
									to="#"
									className="dropdown-item"
									onClick={() => {
										readyToShip(row?.id, row?.get_order?.id);
									}}
								>
									{t('admin_order.ready_to_ship')}
								</Link>
							</>
						)}
						{row.status === 1 && (
							<>
								<Link
									to="#"
									className="dropdown-item"
									onClick={() => {
										orderShipped(row?.id, row?.get_order?.id);
									}}
								>
									{t('admin_order.shipped')}
								</Link>
							</>
						)}
						{row?.get_order?.status === 9 && row?.payout_status === 0 && (
							<Link
								to="#"
								className="dropdown-item"
								onClick={() => {
									requestPayout(row?.id);
								}}
							>
								{t('fund_raise.request_payout')}
							</Link>
						)}
						{row?.payout_status === 2 && (
							<>
								<Link
									to="#"
									className="dropdown-item"
									data-bs-toggle="modal"
									data-bs-target="#confirmPaymentModal"
									onClick={() => {
										showPaymentReceipt(row?.id);
									}}
								>
									{t('donation_list.confirm_payment')}
								</Link>
							</>
						)}
					</div>
				</div>
			),
		},
	];

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('admin_order.order') },
					{ link: '#', name: t('admin_order.list') },
				]}
				title={t('payout_order.orders')}
				refresh={true}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="datatable-wrap">
				<DataTable
					columns={columns}
					data={orders}
					striped
					pagination
					paginationServer
					paginationTotalRows={orderMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					onChangePage={(page) => setPage(page)}
				/>
			</div>

			<div
				className="modal fade cmn-modal"
				id="confirmPaymentModal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="confirmPaymentModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="confirmPaymentModalLabel">
								{t('donation_list.confirm_payment')}
							</h5>
							<button type="button" className="btn-close" id="confirmPaymentModalClose" data-bs-dismiss="modal" aria-label="Close">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<div className="payment-receipt">
								<span className="payment-receipt-img">
									<img className="img-block" src={paymentReceipt?.image} alt="payment reciept" />
								</span>
								<span className="payment-btn-wrap cmn-btn-tag-wrap">
									<button type="button" className="cmn-btn-tag" onClick={confirmPayment}>
										{t('donation_list.confirm_payment')}
									</button>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default OrderList;
