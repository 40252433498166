import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { listOrder, changeOrderStausService, clearPayment, productDtlService, deliveryConfirmService } from './MyOrder.service.js';
import Header from 'components/Header';
import Footer from 'components/Footer';
import bannerImg from 'assets/images/forum.jpg';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import OrderDetail from 'components/OrderDetail';
import allActions from 'redux/actions';

function MyOrder() {
	const dispatch = useDispatch();
	const {
		formState: { errors },
	} = useForm();
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [orders, setOrders] = useState([]);
	const [orderMeta, setOrderMeta] = useState({});
	const [page, setPage] = useState(1);
	const { t } = useTranslation();
	const [receiptImg, setReceiptImg] = useState('');
	const [orderId, setOrderId] = useState(0);
	const [productDtl, setProductDtl] = useState({});

	const lang = useSelector((state) => state.lang);
	let dir = 'ltr';
	dir = lang === 'ar' ? 'rtl' : 'ltr';

	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());
		Promise.all([getOrderList()]).then(() => dispatch(allActions.loadingAction.hideLoading()));
	}, [page]);

	const getOrderList = async () => {
		let list = await listOrder(page);
		if (list.data.status === 200) {
			setOrders(list.data.data.records);
			setOrderMeta(list.data.data.meta);
		} else if (list.data.status === 401) {
			window.location = '/';
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};
	const handlePayment = async (order_id) => {
		setReceiptImg('');
		setOrderId(order_id);
	};
	const changeStatus = async (id, status) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-confirmation-box">
						<h1>{t('admin_user.are_you_sure')}</h1>
						<p>{t('admin_user.you_want_cancel_this')}</p>
						<button onClick={onClose} className="no-btn">
							{t('admin_user.no')}
						</button>
						<button
							className="yes-btn"
							onClick={async () => {
								const formdata = { id, status };
								const result = await changeOrderStausService(formdata);
								if (result.data.status === 200) {
									getOrderList();
									toast.success(`${t('admin_user.order_canceled_successfully')} !`);
								} else {
									toast.success(result.data.error);
								}
								onClose();
							}}
						>
							{t('admin_user.yes')}
						</button>
					</div>
				);
			},
		});
	};

	const handleFile = async (e) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			toast.error(t('about_content.image_size_less_200_kb'));
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setReceiptImg(img);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};
	const uploadPaymentReceipt = async () => {
		if (!receiptImg) {
			toast.error(t('payout_order.please_upload_the_reciept'));
			return false;
		}
		const order_id = orderId;
		const image = receiptImg;
		const formData = {
			order_id,
			image,
		};
		let result = await clearPayment(formData);
		if (result.data.status === 200) {
			await getOrderList();
			document.querySelector('.btn-close').click();
			toast.success(`${t('admin_user_type.payment_succefuly_cleared')} !`);
		} else if (result.data.status === 401) {
			toast.error(`${t('payout_order.unauthorized_action')} !`);
		} else {
			toast.error(result.data.error);
		}
	};

	const showProductDtl = async (id) => {
		dispatch(allActions.loadingAction.showLoading());
		let result = await productDtlService(id);
		if (result.data.status === 200) {
			setProductDtl(result.data.data.product);
			setTimeout(() => {
				dispatch(allActions.loadingAction.hideLoading());
			}, 1000);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const handleCOnfirmDelivery = async (id) => {
		dispatch(allActions.loadingAction.showLoading());
		let formData = {
			id,
		};
		let result = await deliveryConfirmService(formData);
		if (result.data.status === 200) {
			await getOrderList();
			dispatch(allActions.loadingAction.hideLoading());
			toast.success(`${t('order_sucess.delivery_confirmed')}`);
		} else if (result.data.status === 401) {
			dispatch(allActions.loadingAction.hideLoading());
			toast.error(`${t('payout_order.unauthorized_action')}`);
		} else {
			dispatch(allActions.loadingAction.hideLoading());
			toast.error(result.data.error);
		}
	};

	const columns = [
		{
			name: t('admin_order.order_id'),
			selector: (row) => <div className="order-id-text">{row?.auto_gen_id}</div>,
			sortable: true,
		},
		{
			name: t('admin_order.order_date'),
			selector: (row) => moment(row?.created_at).format('DD-MM-YYYY'),
			sortable: true,
		},
		{
			name: t('admin_order.shipping_charge_mru'),
			selector: (row) => row?.shipping_charge,
			sortable: true,
		},
		{
			name: t('admin_order.total_amount_mru'),
			selector: (row) => row?.total_amount + row?.shipping_charge,
			sortable: true,
		},
		{
			name: t('admin_order.payment_method'),
			selector: (row) => {
				return row?.get_shipping_detail?.payment_method;
			},
			sortable: true,
		},
		{
			name: t('order_sucess.online_payment_number'),
			selector: (row) => {
				return row?.get_shipping_detail?.payment_method !== 'cash' ? '43083217' : 'N/A';
			},
			sortable: true,
		},
		{
			name: t('admin_user.action'),
			cell: (row) => (
				<div className="dropdown">
					<div className="actionList " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg
							style={{ cursor: 'pointer' }}
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-sliders dropdown-toggle"
							data-toggle="dropdown"
							role="button"
							aria-expanded="false"
						>
							<line x1={4} y1={21} x2={4} y2={14} />
							<line x1={4} y1={10} x2={4} y2={3} />
							<line x1={12} y1={21} x2={12} y2={12} />
							<line x1={12} y1={8} x2={12} y2={3} />
							<line x1={20} y1={21} x2={20} y2={16} />
							<line x1={20} y1={12} x2={20} y2={3} />
							<line x1={1} y1={14} x2={7} y2={14} />
							<line x1={9} y1={8} x2={15} y2={8} />
							<line x1={17} y1={16} x2={23} y2={16} />
						</svg>
					</div>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						{row.status === 3 && (
							<>
								<Link
									to="#"
									className="dropdown-item"
									data-bs-toggle="modal"
									data-bs-target="#paymentModal"
									onClick={() => {
										handlePayment(row.id);
									}}
								>
									{t('payout_order.clear_payment')}
								</Link>
							</>
						)}
						{row.status === 8 && (
							<>
								<Link
									to="#"
									className="dropdown-item"
									onClick={() => {
										handleCOnfirmDelivery(row.id);
									}}
								>
									{t('order_sucess.confirm_delivery')}
								</Link>
							</>
						)}
					</div>
				</div>
			),
		},
	];

	return (
		<>
			<Header />
			<section className="inner-banner-sec d-flex align-items-center justify-content-center" style={{ background: `url(${bannerImg}) no-repeat` }}>
				<div className="inner-banner-head">
					<h3>{t('my_order')}</h3>
				</div>
			</section>

			<section className="catagory-list-sec sec-gap">
				<div className="container-fluid left-right-40">
					<div className="row">
						<div className="col-12">
							<div className="datatable-wrap">
								<DataTable
									direction={dir}
									columns={columns}
									data={orders}
									striped
									pagination
									paginationServer
									paginationTotalRows={orderMeta.totalRecords}
									paginationPerPage={15}
									paginationComponentOptions={{
										noRowsPerPage: true,
									}}
									expandableRows
									expandableRowsComponent={OrderDetail}
									onChangePage={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
			<div
				className="modal fade cmn-modal"
				id="paymentModal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="paymentModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="paymentModalLabel">
								{t('payout_order.payment_receipt')}
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-12">
									<div className="product-img-wrap">
										<div className="product-img-upload">
											<input type="file" id="receiptImg" onChange={handleFile} />
											<label htmlFor="receiptImg">
												{!receiptImg && (
													<div className="product-upload-text">
														<i className="fa fa-download"></i>
														<p>{`${t('payout_order.upload_receipt')}...`}</p>
													</div>
												)}
												{receiptImg && (
													<div className="preview-img">
														<img src={receiptImg} className="img-block" alt="product" />
													</div>
												)}
											</label>
										</div>
									</div>
								</div>
								<div className="col-12 cmn-btn-tag-wrap">
									<button className="cmn-btn-tag" type="button" onClick={uploadPaymentReceipt}>
										{t('payout_order.submit')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default MyOrder;
