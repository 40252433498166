import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import { eventRegisterService } from './EventRegister.service.js';
import { toast } from 'react-toastify';
import './EventRegister.scss';
import './EventRegister.responsive.scss';

function EventRegister({ title, content, isOpen, onClose, eventId }) {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const { t } = useTranslation();
	if (!isOpen) {
		return null;
	}
	const onSubmit = async (data) => {
		let result = await eventRegisterService(data);
		if (result.data.status === 200) {
			reset();
			toast.success(`${t('user_list.event_register_successfully')}`);
		} else {
			toast.error(result.data.error);
		}

		document.querySelector('.custom-modal-close').click();
		window.scrollTo(0, 0);
	};

	return (
		<>
			{/* <!-- Event register Modal --> */}

			<div className="modal-custom">
				<div className="modal-custom-content">
					<span className="custom-modal-close" onClick={onClose}>
						<i class="far fa-times-circle"></i>
					</span>
					<div className="modal-content-box">
						<h2>{t('user_list.register_event')}</h2>
						<div className="modal-body">
							<form onSubmit={handleSubmit(onSubmit)}>
								<input type="hidden" {...register('event_id')} value={eventId} />
								<div className="row g-2">
									<div className="col-12">
										<div class="admin-input-wrap">
											<label class="admin-lbl">{t('admin_profile.name')}</label>
											<input
												type="text"
												class="form-control admin-input-style"
												placeholder={t('user_list.enter_your_name')}
												{...register('name', { required: t('home_content_page.name_is_required') })}
											/>
											{errors.name && <InputErrorMsg error={errors.name?.message} />}
										</div>
									</div>
									<div className="col-12">
										<div class="admin-input-wrap">
											<label class="admin-lbl">{t('email')}</label>
											<input
												type="text"
												class="form-control admin-input-style"
												placeholder={t('email')}
												{...register('email', {
													required: t('email_is_reqired'),
												})}
											/>
											{errors.email && <InputErrorMsg error={errors.email?.message} />}
										</div>
									</div>
									<div className="col-12">
										<div class="admin-input-wrap">
											<label class="admin-lbl">{t('phone_no')}</label>
											<input
												type="text"
												class="form-control admin-input-style"
												placeholder={t('phone_no')}
												{...register('phone_no', {
													required: t('phone_no_is_required'),
												})}
											/>
											{errors.phone_no && <InputErrorMsg error={errors.phone_no?.message} />}
										</div>
									</div>
									<div className="col-12">
										<div class="logreg-btn-wrap">
											<button type="submit" class="logreg-btn m-0">
												{t('payout_order.submit')}
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default EventRegister;
