import React, { useEffect, useState } from 'react';
import Breadcrumb from 'components/Admin/Breadcrumb';
import Alert from 'components/Alert';
import { listProductRequest, acceptProductRequest } from './AdminProductRequest.service';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function AdminProductRequest() {
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [products, setProducts] = useState([]);
	const [productMeta, setProductMeta] = useState({});
	const [page, setPage] = useState(1);
	const lang = useSelector((state) => state.lang);
	const { t } = useTranslation();

	useEffect(() => {
		getProductList();
	}, [page]);

	const getProductList = async () => {
		let list = await listProductRequest(page);
		if (list.data.status === 200) {
			setProducts(list.data.data.records);
			setProductMeta(list.data.data.meta);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};
	const acceptReq = (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-confirmation-box">
						<h1>{t('admin_request_product.are_you_sure')}</h1>
						<p>{t('admin_request_product.you_want_to_delete_this')}</p>
						<button onClick={onClose} className="no-btn">
							{t('admin_request_product.no')}
						</button>
						<button
							className="yes-btn"
							onClick={async () => {
								let result = await acceptProductRequest({ id });
								if (result.data.status === 200) {
									getProductList();
									SetAlertType('success');
									SetAlertMessage(`${t('fund_catagory.product_request_accepted')} !`);
								} else {
									SetAlertType('error');
									SetAlertMessage(result.data.error);
								}
								onClose();
								window.scrollTo(0, 0);
							}}
						>
							{t('admin_request_product.yes')}
						</button>
					</div>
				);
			},
		});
	};
	const columns = [
		{
			name: t('admin_request_product.image'),
			cell: (row) => (
				<span className="list-table-img">
					<img className="img-block" src={row.image} alt="Product" />
				</span>
			),
		},
		{
			name: t('admin_request_product.category'),
			selector: (row) => row?.get_category?.name_lang?.en,
			sortable: true,
		},
		{
			name: t('admin_request_product.sub_category'),
			selector: (row) => row?.get_sub_category?.name_lang?.en,
			sortable: true,
		},
		{
			name: t('admin_request_product.product_type_name'),
			selector: (row) => row.name?.en,
			sortable: true,
		},
		{
			name: t('admin_request_product.created_by'),
			selector: (row) => row?.get_created_by?.first_name + ' ' + row?.get_created_by?.last_name,
			sortable: true,
		},
		{
			name: t('admin_request_product.status'),
			selector: (row) => {
				if (row.status === 0) {
					return <span className="badge bg-warning text-dark">{t('admin_my_donation.pending')}</span>;
				}
				if (row.status === 1) {
					return <span className="badge bg-success">{t('admin_my_donation.accepted')}</span>;
				}
				if (row.status === 2) {
					return <span className="badge bg-danger">{t('vendor_product.rejected')}</span>;
				}
			},
		},
		{
			name: t('admin_request_product.action'),
			cell: (row) => {
				if (row.status === 0) {
					return (
						<div className="dropdown">
							<div className="actionList " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
								<svg
									style={{ cursor: 'pointer' }}
									xmlns="http://www.w3.org/2000/svg"
									width={24}
									height={24}
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth={2}
									strokeLinecap="round"
									strokeLinejoin="round"
									className="feather feather-sliders dropdown-toggle"
									data-toggle="dropdown"
									role="button"
									aria-expanded="false"
								>
									<line x1={4} y1={21} x2={4} y2={14} />
									<line x1={4} y1={10} x2={4} y2={3} />
									<line x1={12} y1={21} x2={12} y2={12} />
									<line x1={12} y1={8} x2={12} y2={3} />
									<line x1={20} y1={21} x2={20} y2={16} />
									<line x1={20} y1={12} x2={20} y2={3} />
									<line x1={1} y1={14} x2={7} y2={14} />
									<line x1={9} y1={8} x2={15} y2={8} />
									<line x1={17} y1={16} x2={23} y2={16} />
								</svg>
							</div>
							<div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
								<Link
									to={`#`}
									className="dropdown-item"
									onClick={() => {
										acceptReq(row.id);
									}}
								>
									{t('admin_complete_order.accept')}
								</Link>
							</div>
						</div>
					);
				}
			},
		},
	];

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('admin_request_product.request_product') },
					{ link: '#', name: t('admin_user.list') },
				]}
				title={t('admin_request_product.request_product')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}

			<div className="datatable-wrap">
				<DataTable
					columns={columns}
					data={products}
					striped
					pagination
					paginationServer
					paginationTotalRows={productMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					onChangePage={(page) => setPage(page)}
				/>
			</div>
		</>
	);
}

export default AdminProductRequest;
