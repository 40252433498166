import { default as commonApi } from 'config/commonApiConf';

export const listEventService = async (page) => {
	try {
		let result = await commonApi.get(`/front/event/list?page=${page}`);
		return result;
	} catch (error) {
		return error;
	}
};
