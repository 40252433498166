import { default as api } from 'config/adminApiConf.js';

export const raiseFundService = async (data) => {
	/* try {
        let result = await api.post(`/funder/raise-fund`, data);
        return result;
    } catch (error) {
        return error;
    } */
};
export const editRaiseFundService = async (id, data) => {
	try {
		let result = await api.put(`/fund/edit/${id}`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const listRegion = async (data = {}) => {
	try {
		let result = await api.get('/region-list');
		return result;
	} catch (error) {
		return error;
	}
};

export const fundRaiseCatListService = async (data) => {
	try {
		let result = await api.get(`/fund/list-fund-category`);
		return result;
	} catch (error) {
		return error;
	}
};
export const fundRaiseDetailsService = async (id) => {
	try {
		let result = await api.get(`/fund/detais/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};
