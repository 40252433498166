import { default as api } from 'config/adminApiConf.js';

export const addContentService = async (data = {}) => {
	try {
		let result = await api.post('/content-management/create-data-observ-content', data);
		return result;
	} catch (error) {
		return error;
	}
};

export const getContentService = async (pageType = 3) => {
	try {
		let result = await api.get(`/content-management/get-page-content/${pageType}`);
		return result;
	} catch (error) {
		return error;
	}
};
