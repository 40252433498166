import React, { useEffect, useState } from 'react';
import './Header.scss';
import './Header.responsive.scss';
import globe from 'assets/images/globe.png';
import user_line from 'assets/images/user-line.png';
import logo from 'assets/images/logo.png';
import $ from 'jquery';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import allActions from 'redux/actions';
import { Link, NavLink } from 'react-router-dom';
import NoImage from 'assets/images/avatar.png';
import { getAuthUser, getCartService } from './Header.service.js';
import { useTranslation } from 'react-i18next';
const languageMap = {
	en: { label: 'English', dir: 'ltr', active: true, value: 'en' },
	fr: { label: 'Français', dir: 'ltr', active: false, value: 'fr' },
	ar: { label: 'العربية', dir: 'rtl', active: false, value: 'ar' },
};

function Header() {
	const { t } = useTranslation();
	const selected = localStorage.getItem('i18nextLng').split('-')[0] || 'en';
	const [langSeleted, setLangSelected] = useState(selected);
	const auth = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	const lang = useSelector((state) => state.lang);
	const cartItem = useSelector((state) => state.cart);

	let dir = 'ltr';
	dir = lang === 'ar' ? 'rtl' : 'ltr';

	useEffect(() => {
		var header = $('.start-style');
		$(window).scroll(function () {
			var scroll = $(window).scrollTop();

			if (scroll >= 10) {
				header.removeClass('start-style').addClass('scroll-on');
			} else {
				header.removeClass('scroll-on').addClass('start-style');
			}
		});
		//  Back To top Start
		$(window).scroll(function () {
			if ($(this).scrollTop() > 200) {
				$('.scrollup').fadeIn();
				$('.arrow-show').fadeIn();
			} else {
				$('.scrollup').fadeOut();
				$('.arrow-show').fadeOut();
			}
		});
		$('.scrollup').click(function (e) {
			e.preventDefault();
			$('html, body').animate({ scrollTop: 0 }, 300);
			return false;
		});
		//  Back To top End

		// Auth check
		const auth_token = localStorage.getItem('auth_token');
		if (auth_token) {
			getAuthUser().then((res) => {
				if (res.data.status === 200) {
					const user = res.data.data.user;
					dispatch(
						allActions.authAction.setAuthUser({
							id: user.id,
							role: user.role,
							avatar: user.get_user_details?.avatar ? user.get_user_details.avatar : NoImage,
							name: user.first_name + ' ' + user.last_name,
						}),
					);

					// for cartItem
					if (user.role === 3) {
						getCart();
					}
				} else {
					dispatch(allActions.authAction.setAuthUser({ id: '', role: '', avatar: NoImage, name: '' }));
				}
			});
		}

		// mobile menu

		document.getElementById('menu_res').onclick = function () {
			document.getElementById('res_nav').classList.toggle('left0');
			this.classList.toggle('menu_responsiveTo');
		};
	}, []);

	useEffect(() => {
		document.body.dir = languageMap[langSeleted].dir;
		document.body.className = `style-${languageMap[langSeleted].dir}`;
		dispatch(allActions.langAction.changeLanguage(languageMap[langSeleted].value));
	}, [langSeleted]);

	const changeLanguage = (e) => {
		i18next.changeLanguage(e.target.value);

		setLangSelected(e.target.value);
	};
	const logOut = () => {
		localStorage.removeItem('auth_token');
		window.location.href = '/';
	};
	const getCart = async () => {
		let cart = await getCartService();
		if (cart.data.status === 200) {
			console.log('cart list : ', cart.data.data.cart);
			let cartItem = cart.data.data.cart;
			let cartItemId = await cartItem.map((val) => val.vendor_product_id);
			console.log('cartItemId', cartItemId);
			dispatch(allActions.cartAction.addToCart(cartItemId));
		}
	};
	return (
		<>
			<a className="scrollup" href="javascript:void(0);" aria-label="Scroll to top">
				<i className="fas fa-chevron-up" />
			</a>

			<header className="menu-header">
				<div className="top-header-bar">
					<div className="container-fluid left-right-40">
						<div className="row justify-content-between">
							<div className="col-auto">
								<div className="header-language">
									<select className="form-control selectOption lnguge-top-select" onChange={changeLanguage} value={langSeleted}>
										{Object.keys(languageMap)?.map((item) => {
											return (
												<option value={item} key={item}>
													{languageMap[item].label}
												</option>
											);
										})}
									</select>
									<span className="glob-icon">
										<img className="img-block" src={globe} alt="" />
									</span>
								</div>
							</div>
							<div className="col-auto d-flex">
								{auth.id === '' ? (
									<div className="log-reg-nav">
										<ul className="d-flex align-items-center">
											<li>
												<Link to="#">
													<img src={user_line} alt="" />
												</Link>
											</li>
											<li>
												<Link to="/login" className="reg-nav">
													{t('sign_in')}
												</Link>
											</li>
										</ul>
									</div>
								) : (
									<>
										{auth.role === 3 && (
											<div className="cart-wrap">
												<Link to={'/my-cart'} className="cart-wrap-icon">
													<i className="fas fa-shopping-cart"></i>
													<span className="cart-wrap-total">{cartItem.length}</span>
												</Link>
											</div>
										)}
										<div className="after-login-wrap position-relative">
											<div className="after-login d-flex align-items-center">
												<span className="user-img">
													<img className="img-block" src={auth.avatar} alt={auth.name} />
												</span>
												<h5>{auth.name.length > 20 ? `${auth.name.substring(0, 20)}...` : auth.name}</h5>
												<span className="after-login-icon">
													<i className="fas fa-angle-down" />
												</span>
											</div>
											<div className="after-login-menu-wrap">
												<ul>
													{auth.role === 3 ? (
														<>
															<li>
																<Link to="/my-order">
																	<i className="fas fa-shopping-bag"></i> {t('my_order')}
																</Link>
															</li>
															<li>
																<Link to="/my-donation">
																	<i className="fas fa-shopping-bag"></i> {'My Donation'}
																</Link>
															</li>
														</>
													) : (
														<>
															<li>
																<Link to="/admin/dashboard">
																	<i className="fas fa-tachometer-alt"></i> {t('dashboard')}
																</Link>
															</li>
														</>
													)}
													<li>
														<Link to="#" onClick={logOut}>
															<i className="fas fa-sign-out-alt"></i> {t('log_out')}
														</Link>
													</li>
												</ul>
											</div>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="topHeader start-style start-header">
					<div className="container-fluid left-right-40">
						<div className="row justify-content-between">
							<div className="logo">
								<Link to="/">
									<span className="logo-wrap">
										<img className="img-block" src={logo} alt="logo" />
									</span>
								</Link>
							</div>
							<div className="col">
								<div className="navArea d-flex justify-content-start align-items-center">
									<nav id="res_nav" className="navigation">
										<button id="menu_res" className="menu-responsive">
											<span />
											<span />
											<span />
										</button>
										<ul className="d-flex justify-content-between">
											<li>
												<NavLink to="/">{t('home')}</NavLink>
											</li>
											<li>
												<NavLink to="/about-us">{t('about_us')}</NavLink>
											</li>
											<li>
												<NavLink to="/market-place">{t('market_place')}</NavLink>
											</li>
											<li>
												<NavLink to="/data-observatory">{t('data_observatory')}</NavLink>
											</li>
											<li>
												<NavLink to="/funding">{t('funding')}</NavLink>
											</li>
											<li>
												<NavLink to="/forum">{t('forum')}</NavLink>
											</li>
											<li>
												<NavLink to="/events">{t('event.events')}</NavLink>
											</li>
										</ul>
									</nav>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}

export default Header;
