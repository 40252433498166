import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import './OrderSucess.scss';
import { Link } from 'react-router-dom';
import forum_bg from 'assets/images/forum.jpg';
import allActions from 'redux/actions';
import { useDispatch } from 'react-redux';
import orderSuccessImg from 'assets/images/truck.gif';
import { useTranslation } from 'react-i18next';
function OrderSucess() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());
		setTimeout(() => {
			dispatch(allActions.loadingAction.hideLoading());
		}, 1000);
	}, []);
	return (
		<>
			<Header />
			<section className="inner-banner-sec d-flex align-items-center justify-content-center" style={{ background: `url(${forum_bg}) no-repeat` }}>
				<div className="inner-banner-head">
					<h3>{t('admin_order.order')}</h3>
				</div>
			</section>
			<section className="payment-page-sec">
				<div className="container-fluid left-right-40">
					<div className="row">
						<div className="col-12">
							<div className="payment-page pay-success shadow-box">
								<img src={orderSuccessImg} width={250} className="img-fluid mb-4 mr-3" />
								<h2>{t('order_sucess.order_placed')}</h2>
								<p>{t('order_sucess.order_reserved_for_confirmation_delivery_service_upload_order_payment_receipt')}</p>
								<ul className="d-flex justify-content-center order-success-btn-wrap">
									<li>
										<Link to="/market-place">{t('order_sucess.go_to_marketplace')}</Link>
									</li>
									<li>
										<Link to="/my-order">{t('order_sucess.go_to_order')}</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
}

export default OrderSucess;
