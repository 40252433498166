import axios from 'axios';
const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL + '/auth',
	timeout: 15000,
});

export const registerUser = async (data) => {
	try {
		let result = await api.post('/register', data);
		return result;
	} catch (error) {
		return error;
	}
};

export const listCountry = async (data = {}) => {
	try {
		let result = await api.get('/country-list');
		return result;
	} catch (error) {
		return error;
	}
};

export const listRegion = async (data = {}) => {
	try {
		let result = await api.get('/region-list');
		return result;
	} catch (error) {
		return error;
	}
};

export const sendOtpService = async (data) => {
	try {
		let result = await api.post('/send-otp', data);
		return result;
	} catch (error) {
		return error;
	}
};

// const data = { registerUser };

// export default data;
