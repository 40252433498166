import { default as api } from 'config/adminApiConf.js';

export const listEventService = async (page) => {
	try {
		let result = await api.get(`/event/list?page=${page}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const deleteEventService = async (id) => {
	try {
		let result = await api.delete(`/event/delete/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};
