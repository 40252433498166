import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import Breadcrumb from 'components/Admin/Breadcrumb';
import Alert from 'components/Alert';
import { toast } from 'react-toastify';
import { editRequestFormService, requestFormDetailsService, listRequestType } from './EditRequestForm.service.js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function EditRequestForm() {
	const params = useParams();
	const id = atob(params.id);
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const { t } = useTranslation();
	const lang = useSelector((state) => state.lang);
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const [requestFormDetails, setRequestFormDetails] = useState({});
	const [attachFile, setRequestAttachFile] = useState('');
	const [requestTypeList, SetRequestTypeList] = useState([]);

	useEffect(() => {
		getRequestFormDetails();
		getRequestType();
	}, []);

	useEffect(() => {
		setDefaultValueForm();
	}, [requestFormDetails]);

	const getRequestType = async () => {
		let result = await listRequestType();
		console.log(result);
		if (result.data.status === 200) {
			let request_types = result.data.data.request_types;
			SetRequestTypeList(request_types);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};

	const onSubmit = async (data) => {
		let formData = {
			request_type: data.request_type,
			subject: data.subject,
			subject_ar: data.subject_ar,
			subject_fr: data.subject_fr,
			description: data.description,
			description_fr: data.description_fr,
			description_ar: data.description_ar,
			attach_file: attachFile,
		};
		const result = await editRequestFormService(id, formData);
		if (result.data.status === 200) {
			toast.success(`${t('user_list.request_form_updated_successfully')} !`);
		} else {
			toast.error(result.data.error);
		}
	};

	const getRequestFormDetails = async () => {
		const result = await requestFormDetailsService(id);
		if (result.data.status === 200) {
			setRequestFormDetails(result.data.data.request_form);
		} else {
			toast.error(result.data.error);
		}
	};

	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.request_type = requestFormDetails?.request_type;
		defaultValues.subject = requestFormDetails?.subject?.en;
		defaultValues.subject_fr = requestFormDetails?.subject?.fr;
		defaultValues.subject_ar = requestFormDetails?.subject?.ar;
		defaultValues.description = requestFormDetails?.description?.en;
		defaultValues.description_fr = requestFormDetails?.description?.fr;
		defaultValues.description_ar = requestFormDetails?.description?.ar;
		reset({ ...defaultValues });
	};
	const handleFile = async (e) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			SetAlertType('error');
			SetAlertMessage(t('about_content.image_size_less_200_kb'));
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setRequestAttachFile(img);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('request_form.request_form') },
					{ link: '#', name: t('admin_product_type.add') },
				]}
				title={t('request_form.edit_request_form')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}

			<div className="admin-white-box">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-12">
							<label className="admin-lbl">{t('request_form.subject')}</label>
							<div className="admim-input-wrap">
								<input
									type="text"
									className="form-control admim-input-style"
									placeholder={t('request_form.subject')}
									{...register('subject', { required: t('request_form.subject_is_required') })}
								/>
								{errors.subject && <InputErrorMsg error={errors.subject?.message} />}
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-12">
							<label className="admin-lbl">{t('request_form.request_type')}</label>
							<select
								className="form-select"
								{...register('request_type', {
									required: t('request_form.request_type_is_required'),
								})}
							>
								<option value="">{t('request_form.select_request_type')}</option>
								{requestTypeList?.map((value, index) => {
									return (
										<option key={index} value={value.id}>
											{value.name?.[lang]}
										</option>
									);
								})}
							</select>
							{errors.request_type && <InputErrorMsg error={errors.request_type?.message} />}
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-12">
							<div className="mb-3">
								<label htmlFor="formFile" className="form-label admin-lbl">
									{t('request_form.attach_file')}
								</label>
								<input className="form-control" type="file" id="formFile" onChange={handleFile} />
							</div>
						</div>
					</div>
					<div className="row g-3 mt-0">
						<div className="col-12">
							<label className="admin-lbl">{t('about_content.description')}</label>
							<div className="admim-input-wrap">
								<textarea
									className="form-control resize-none"
									placeholder={t('about_content.description')}
									rows="5"
									{...register('description', {
										required: t('about_content.title_is_required'),
									})}
								></textarea>
								{errors.description && <InputErrorMsg error={errors.description?.message} />}
							</div>
						</div>
						<div className="col-12 cmn-btn-tag-wrap mt-3">
							<button className="cmn-btn-tag">{t('payout_order.submit')}</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
}

export default EditRequestForm;
