import { default as api } from 'config/adminApiConf.js';

export const catList = async (page, search = '') => {
	try {
		let result = await api.get('/products/category/list?page=' + page + '&search=' + search);
		return result;
	} catch (error) {
		return error;
	}
};
export const addCategory = async (data = {}) => {
	try {
		let result = await api.post('/products/category/create', data);
		return result;
	} catch (error) {
		return error;
	}
};

export const categoryDtl = async (id) => {
	try {
		let result = await api.get(`/products/category/detail/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const editCategory = async (id, data = {}) => {
	try {
		let result = await api.put(`/products/category/edit/${id}`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const delCategory = async (id) => {
	try {
		let result = await api.delete(`/products/category/delete/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};
