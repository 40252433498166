import React, { useEffect, useState } from 'react';
import './Profile.scss';
import './Profile.responsive.scss';
import { Link } from 'react-router-dom';
import { getProfileDtl } from './Profile.service.js';
import NoImage from 'assets/images/avatar.png';
import Alert from 'components/Alert';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { useTranslation } from 'react-i18next';

function Profile() {
	const [profileDtl, setProfileDtl] = useState({});
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const { t } = useTranslation();

	useEffect(() => {
		profile();
	}, []);
	const profile = async () => {
		let result = await getProfileDtl();
		console.log('result', result.data.data.user);
		if (result.data.status === 200) {
			setProfileDtl(result.data.data.user);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};
	return (
		<>
			<Breadcrumb data={[{ link: '#', name: t('admin_profile.profile') }]} title={t('admin_profile.profile')} />
			<div className="my-profile-wrap">
				<div className="my-profile-box position-relative">
					<div className="my-profile-edit">
						<Link to="/admin/edit-profile" className="profile-edit-btn">
							<i className="far fa-edit"></i>
						</Link>
					</div>
					{alertType && <Alert type={alertType} message={alertMessage} />}
					<div className="profile-img">
						<img className="img-block" src={profileDtl?.get_user_details?.avatar ? profileDtl.get_user_details.avatar : NoImage} alt="profile_img" />
					</div>
					<div className="my-profile-list d-flex align-items-center">
						<div className="my-profile-list-lft">{t('admin_profile.name')}</div>
						<div className="my-profile-list-rgt">
							{profileDtl.first_name} {profileDtl.last_name}
						</div>
					</div>
					<div className="my-profile-list d-flex align-items-center">
						<div className="my-profile-list-lft">{t('admin_profile.email')}</div>
						<div className="my-profile-list-rgt">{profileDtl.email}</div>
					</div>
					<div className="my-profile-list d-flex align-items-center">
						<div className="my-profile-list-lft">{t('admin_profile.company_name')}</div>
						<div className="my-profile-list-rgt">{profileDtl.get_user_details?.company_name}</div>
					</div>
					<div className="my-profile-list d-flex align-items-center">
						<div className="my-profile-list-lft">{t('admin_profile.company_VAT')}</div>
						<div className="my-profile-list-rgt">{profileDtl.get_user_details?.company_vat}</div>
					</div>
					<div className="my-profile-list d-flex align-items-center">
						<div className="my-profile-list-lft">{t('admin_profile.phone_number')}</div>
						<div className="my-profile-list-rgt">{profileDtl.ph_no}</div>
					</div>
					<div className="my-profile-list d-flex align-items-center">
						<div className="my-profile-list-lft">{t('admin_profile.address')}</div>
						<div className="my-profile-list-rgt">{profileDtl.get_user_details?.address}</div>
					</div>
					<div className="my-profile-list d-flex align-items-center">
						<div className="my-profile-list-lft">{t('admin_profile.zip_code')}</div>
						<div className="my-profile-list-rgt">{profileDtl.get_user_details?.zip_code}</div>
					</div>
					<div className="my-profile-list d-flex align-items-center">
						<div className="my-profile-list-lft">{t('admin_profile.city')}</div>
						<div className="my-profile-list-rgt">{profileDtl.get_user_details?.city}</div>
					</div>
					<div className="my-profile-list d-flex align-items-center">
						<div className="my-profile-list-lft">{t('admin_profile.region')}</div>
						<div className="my-profile-list-rgt">{profileDtl.get_user_details?.get_region?.name}</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Profile;
