import React from 'react';
import './Breadcrumb.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Breadcrumb(props) {
	const refresh = props?.refresh ?? false;
	const { t } = useTranslation();

	return (
		<>
			<div className="row justify-content-between breadcrumb-title-wrap align-items-center">
				<div className="col-auto">
					<h4>
						{props.title}{' '}
						{refresh && (
							<button
								type="button"
								className="reset-filter"
								onClick={() => {
									window.location.reload();
								}}
							>
								<i className="fas fa-sync-alt"></i>
							</button>
						)}
					</h4>
				</div>
				<div className="col-auto">
					<ul className="d-flex breadcrumb">
						<li>
							<Link to="/admin/dashboard">{t('admin_dashboard.dashboard')}</Link>
						</li>
						{props.data.map((res, i) => {
							return (
								<li className="active" key={i}>
									<Link to={res.link}>{res.name}</Link>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</>
	);
}

export default Breadcrumb;
