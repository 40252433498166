import { default as commonApi } from 'config/commonApiConf';

export const listForumService = async (page) => {
	try {
		let result = await commonApi.get(`/front/forum/list?page=${page}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const postCommentService = async (data) => {
	try {
		let result = await commonApi.post(`/front/forum/post-comment`, data);
		return result;
	} catch (error) {
		return error;
	}
};
