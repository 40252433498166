import { default as api } from 'config/frontApiConf.js';
import { default as commonApi } from 'config/commonApiConf';

export const fundDetailsService = async (id) => {
	try {
		let result = await api.get(`/fund/fund-details/${id}`);
		return result;
	} catch (error) {
		return error;
	}
};

export const loginService = async (data) => {
	try {
		let result = await commonApi.post(`/auth/login`, data);
		return result;
	} catch (error) {
		return error;
	}
};

export const fundDonationService = async (data) => {
	try {
		let result = await commonApi.post(`/front/fund/donate-fund`, data);
		return result;
	} catch (error) {
		return error;
	}
};
export const fundAddCommentService = async (data) => {
	try {
		let result = await api.post(`/fund/add-comment`, data);
		return result;
	} catch (error) {
		return error;
	}
};
