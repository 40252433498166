import { default as api } from 'config/adminApiConf.js';

export const listProductRequest = async (page) => {
	try {
		let result = await api.get(`/products/vendor-product/list-product-request?page=${page}`);
		return result;
	} catch (error) {
		return error;
	}
};
