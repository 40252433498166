const cart = (state = [], action) => {
	let arr = [...state];
	switch (action.type) {
		case 'ADD_TO_CART':
			// return [...state, ...action.productId];
			action.productId.map((val) => {
				if (!arr.includes(val)) {
					arr.push(val);
				}
			});
			return arr;
		case 'REMOVE_FROM_CART':
			return arr.filter((val) => val !== action.productId);
		case 'EMPTY_CART':
			return [];
		default:
			return arr;
	}
};

export default cart;
