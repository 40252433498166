import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { Link } from 'react-router-dom';
import Alert from 'components/Alert';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { listEventService, deleteEventService } from './ListEvent.service.js';
import './ListEvent.scss';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function ListEvent() {
	const [event, setEvent] = useState([]);
	const [eventMeta, setEventMeta] = useState({});
	const [page, setPage] = useState(1);
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const { t } = useTranslation();

	const auth = useSelector((state) => state.auth);

	useEffect(() => {
		getEventList();
	}, [page]);

	const getEventList = async () => {
		const list = await listEventService(page);
		if (list.data.status === 200) {
			setEvent(list.data.data.records);
			setEventMeta(list.data.data.meta);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};
	const deleteProd = async (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-confirmation-box">
						<h1>{t('admin_user.are_you_sure')}</h1>
						<p>{t('admin_user.you_want_to_delete_this')}</p>
						<button onClick={onClose} className="no-btn">
							{t('admin_user.no')}
						</button>
						<button
							className="yes-btn"
							onClick={async () => {
								let result = await deleteEventService(id);
								if (result.data.status === 200) {
									getEventList();
									toast.success(t('admin_user.event_deleted_successfully'));
								} else {
									toast.error(result.data.error);
								}
								onClose();
							}}
						>
							{t('admin_user.yes_delete_it')}
						</button>
					</div>
				);
			},
		});
	};
	const columns = [
		{
			name: t('event.title'),
			selector: (row) => row.title?.en,
			sortable: true,
		},
		{
			name: t('event.event_type'),
			selector: (row) => row.get_event_type?.name?.en,
			sortable: true,
		},
		{
			name: t('event.event_date'),
			selector: (row) => moment(row.event_date).format('DD-MM-YYYY') + ' ' + row.event_time,
			sortable: true,
		},
		{
			name: t('event.event_location'),
			selector: (row) => row.location,
			sortable: false,
		},
		{
			name: t('admin_category.created_at'),
			selector: (row) => moment(row.created_at).format('DD-MM-YYYY'),
			sortable: true,
		},
		{
			name: t('admin_request_product.created_by'),
			selector: (row) => row?.get_created_by?.full_name,
			sortable: true,
		},
		{
			name: t('admin_user.status'),
			selector: (row) => {
				let status = '';
				if (row.status === 0) {
					status = <span class="badge bg-danger">{t('admin_user.inactive')}</span>;
				}
				if (row.status === 1) {
					status = <span class="badge bg-success">{t('admin_user.active')}</span>;
				}
				return status;
			},
			sortable: true,
		},
		{
			name: t('admin_request_product.action'),
			cell: (row) => (
				<div className="dropdown">
					<div className="actionList " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg
							style={{ cursor: 'pointer' }}
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-sliders dropdown-toggle"
							data-toggle="dropdown"
							role="button"
							aria-expanded="false"
						>
							<line x1={4} y1={21} x2={4} y2={14} />
							<line x1={4} y1={10} x2={4} y2={3} />
							<line x1={12} y1={21} x2={12} y2={12} />
							<line x1={12} y1={8} x2={12} y2={3} />
							<line x1={20} y1={21} x2={20} y2={16} />
							<line x1={20} y1={12} x2={20} y2={3} />
							<line x1={1} y1={14} x2={7} y2={14} />
							<line x1={9} y1={8} x2={15} y2={8} />
							<line x1={17} y1={16} x2={23} y2={16} />
						</svg>
					</div>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						<Link to={`/admin/event/edit/${btoa(row.id)}`} className="dropdown-item">
							{t('admin_request_product.edit')}
						</Link>
						<Link
							to="#"
							className="dropdown-item"
							onClick={() => {
								deleteProd(row.id);
							}}
						>
							{t('admin_request_product.delete')}
						</Link>
					</div>
				</div>
			),
		},
	];

	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('event.event') },
					{ link: '#', name: t('admin_user.list') },
				]}
				title={t('event.event')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}

			<div className="row mb-3 justify-content-between">
				<div className="col-lg-6">
					<div className="cmn-a-tag-wrap mb-3">
						<Link className="cmn-a-tag" to={`/admin/event/add`}>
							{t('event.add_event')}
						</Link>
					</div>
				</div>
			</div>
			<div className="datatable-wrap">
				<DataTable
					columns={columns}
					data={event}
					striped
					pagination
					paginationServer
					paginationTotalRows={eventMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					onChangePage={(page) => setPage(page)}
				/>
			</div>
		</>
	);
}

export default ListEvent;
