import React, { useEffect, useState } from 'react';
import './FundDetails.scss';
import './FundDetails.responsive.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Handshake from 'assets/images/handshake.png';
import Calendar from 'assets/images/calendar.png';
import Fundraiser from 'assets/images/fundraiser.png';
import HandheartLine from 'assets/images/hand-heart-line.png';

import { useParams } from 'react-router-dom';
import allActions from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { fundDetailsService } from './FundDetails.service.js';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import InputErrorMsg from 'components/InputErrorMsg';
import Alert from 'components/Alert';
import { loginService, fundDonationService, fundAddCommentService } from './FundDetails.service.js';
import ProfileImage from 'components/ProfileImage';
import { FacebookShareButton } from 'react-share';

function FundDetails() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const lang = useSelector((state) => state.lang);
	const [fundDetails, setFundDetails] = useState({ get_images: [] });
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const {
		register: register2,
		handleSubmit: handleSubmit2,
		formState: { errors: errors2 },
	} = useForm();
	const [showPassword, setShowPassword] = useState(false);
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [comment, setComment] = useState('');
	const [receiptImg, setReceiptImg] = useState('');

	const auth = useSelector((state) => state.auth);

	useEffect(() => {
		dispatch(allActions.loadingAction.showLoading());

		Promise.all([getFundDetails()]).then((res) => {
			dispatch(allActions.loadingAction.hideLoading());
		});
	}, []);

	const getFundDetails = async () => {
		let result = await fundDetailsService(id);
		if (result.data.status === 200) {
			setFundDetails(result.data.data.fund);
		} else {
			toast.error(result.data.error);
		}
	};

	const onSubmit = async (data) => {
		let result = await loginService(data);
		if (result.data.status === 200) {
			localStorage.setItem('auth_token', result.data.data.token);
			window.location.reload();
		} else {
			toast.error(result.data.error);
		}
	};

	const onSubmit2 = async (data) => {
		if (!receiptImg) {
			toast.error('Please upload the reciept');
			return false;
		}
		const image = receiptImg;
		const formData = {
			raise_fund_id: atob(id),
			amount: data.amount,
			payment_method: fundDetails?.get_user?.get_bank_details?.mobile_banking_name,
			image: image,
		};
		let result = await fundDonationService(formData);
		dispatch(allActions.loadingAction.showLoading());
		if (result.data.status === 200) {
			document.querySelector('#donationModalClose').click();
			dispatch(allActions.loadingAction.hideLoading());
			setReceiptImg('');
			toast.success(t('funding_details.donation_done_clear_payment'));
		} else {
			toast.error(result.data.error);
			dispatch(allActions.loadingAction.hideLoading());
		}
	};

	const submitComment = async () => {
		if (comment) {
			let formData = {
				raise_fund_id: fundDetails?.id,
				comment,
			};

			let result = await fundAddCommentService(formData);
			if (result.data.status === 200) {
				setComment('');
				getFundDetails();
				toast.success(t('funding_details.comment_done'));
			} else if (result.data.status === 401) {
				toast.error(t('funding_details.please_for_comment'));
			} else {
				toast.error(result.data.error);
			}
		}
	};

	var images = [
		{
			original: '',
			thumbnail: '',
		},
	];

	if (fundDetails?.get_images?.length > 0) {
		images = fundDetails?.get_images?.map((val, i) => {
			return {
				original: val?.media_path + '/' + val?.media_name,
				thumbnail: val?.media_path + '/' + val?.media_name,
			};
		});
	}
	const handleFile = async (e) => {
		let file = e.target.files[0];
		if (file.size > 200000) {
			toast.error('Image size is too large. Image size must be less than 200 KB.');
			return false;
		}
		let img = await getBase64(file);
		if (img) {
			setReceiptImg(img);
		}
	};
	const getBase64 = (file) => {
		return new Promise((resolve) => {
			let fileInfo;
			let baseURL = '';
			// Make new FileReader
			let reader = new FileReader();

			// Convert the file to base64 text
			reader.readAsDataURL(file);

			// on reader load somthing...
			reader.onload = () => {
				// Make a fileInfo Object
				baseURL = reader.result;
				resolve(baseURL);
			};
			console.log(fileInfo);
		});
	};

	return (
		<>
			<Header />
			<section className="fund-sec no-banner">
				<div className="container-fluid left-right-40">
					<div className="row">
						<div className="col-12">
							<div className="sec-heading-three">
								<h3>{fundDetails?.campaign_title?.[lang]}</h3>
							</div>
						</div>

						<div className="col-lg-8 col-md-6 col-sm-12 col-12">
							<div className="found-sliderbox">
								<ImageGallery items={images} showFullscreenButton={false} showPlayButton={false} lazyLoad={true} isRTL={lang === 'ar' ? true : false} />
								<div className="image-desc">
									<p>{fundDetails?.campaign_short_description?.[lang].substring(0, 400)}</p>
								</div>
							</div>
							<div className="raiser-dtls-tab">
								<div className="forumTab responsive-tabs-dtls">
									<ul className="nav nav-pills" role="tablist">
										<li className="nav-item">
											<a id="tab-A" href="#pane-A" className="nav-link active" data-bs-toggle="tab" role="tab">
												{t('funding_details.about')}
											</a>
										</li>
										<li className="nav-item">
											<a id="tab-C" href="#pane-C" className="nav-link" data-bs-toggle="tab" role="tab">
												{t('funding_details.document')} ({fundDetails?.get_documents?.length})
											</a>
										</li>
										<li className="nav-item">
											<a id="tab-D" href="#pane-D" className="nav-link" data-bs-toggle="tab" role="tab">
												{t('funding_details.images')} ({fundDetails?.get_images?.length})
											</a>
										</li>
										<li className="nav-item">
											<a id="tab-E" href="#pane-E" className="nav-link" data-bs-toggle="tab" role="tab">
												{t('funding_details.comments')} ({fundDetails?.get_fund_comments?.length})
											</a>
										</li>
									</ul>
									<div id="content" className="tab-content" role="tablist">
										<div id="pane-A" className="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-A">
											<div className="card-header" role="tab" id="heading-A">
												<h5 className="mb-0">
													<a data-bs-toggle="collapse" href="#collapse-A" aria-expanded="true" aria-controls="collapse-A">
														{t('funding_details.about')}
													</a>
												</h5>
											</div>
											<div id="collapse-A" className="collapse show" data-bs-parent="#content" role="tabpanel" aria-labelledby="heading-A">
												<div className="card-body">
													<div className="found-dtls-tab-text">
														<p>{fundDetails?.campaign_description?.[lang]}</p>
													</div>
												</div>
											</div>
										</div>
										<div id="pane-C" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-C">
											<div className="card-header" role="tab" id="heading-C">
												<h5 className="mb-0">
													<a data-bs-toggle="collapse" href="#collapse-C" aria-expanded="true" aria-controls="collapse-C">
														{t('funding_details.document')} ({fundDetails?.get_documents?.length})
													</a>
												</h5>
											</div>
											<div id="collapse-C" className="collapse" data-bs-parent="#content" role="tabpanel" aria-labelledby="heading-C">
												<div className="card-body">
													<div className="document-download-list">
														<div className="row g-3 row-cols-lg-4 row-cols-md-3 row-cols-sm-3 row-cols-3">
															{fundDetails?.get_documents?.map((val, i) => (
																<div className="col">
																	<div className="download-list-box">
																		<a href={val?.media_path + '/' + val?.media_name} download>
																			<i className="fas fa-file-download"></i>
																		</a>
																	</div>
																</div>
															))}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div id="pane-D" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-D">
											<div className="card-header" role="tab" id="heading-D">
												<h5 className="mb-0">
													<a data-bs-toggle="collapse" href="#collapse-D" aria-expanded="true" aria-controls="collapse-D">
														{t('funding_details.images')} ({fundDetails?.get_images?.length})
													</a>
												</h5>
											</div>
											<div id="collapse-D" className="collapse" data-bs-parent="#content" role="tabpanel" aria-labelledby="heading-D">
												<div className="card-body">
													<div className="image-list">
														<div className="row g-3 row-cols-lg-6 row-cols-md-3 row-cols-sm-3 row-cols-3">
															{fundDetails?.get_images?.map((val, i) => (
																<div className="col">
																	<div className="image-list-box">
																		<img className="img-block" src={val?.media_path + '/' + val?.media_name} alt="" />
																	</div>
																</div>
															))}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div id="pane-E" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-E">
											<div className="card-header" role="tab" id="heading-E">
												<h5 className="mb-0">
													<a data-bs-toggle="collapse" href="#collapse-E" aria-expanded="true" aria-controls="collapse-E">
														{t('funding_details.comments')} ({fundDetails?.get_fund_comments?.length})
													</a>
												</h5>
											</div>
											<div id="collapse-E" className="collapse" data-bs-parent="#content" role="tabpanel" aria-labelledby="heading-D">
												<div className="card-body">
													<div className="comments-wrap">
														<div className="cmnts-input-wrap">
															<div className="msg-type">
																<textarea
																	className="form-control cmnts-input-style"
																	placeholder={t('funding_details.type_your_comment')}
																	name="comment"
																	onChange={(e) => {
																		setComment(e.target.value);
																	}}
																	value={comment}
																></textarea>
															</div>
															<span className="cmnts-input-btn-wrap">
																<button className="cmnts-input-btn" type="button" onClick={submitComment}>
																	<i className="fas fa-paper-plane"></i>
																</button>
															</span>
														</div>
														{fundDetails?.get_fund_comments?.length > 0 ? (
															<>
																{fundDetails?.get_fund_comments?.map((val, i) => {
																	return (
																		<>
																			<div className="comments-wrap-list d-flex">
																				<div className="wrap-list-lft">
																					<ProfileImage
																						src={val?.get_comment_by?.get_user_details?.avatar}
																						firstName={val?.get_comment_by?.first_name}
																						lastName={val?.get_comment_by?.last_name}
																					/>
																				</div>
																				<div className="wrap-list-rgt">
																					<div className="list-rgt-top d-flex">
																						<h5>{val?.get_comment_by?.full_name}</h5>
																						<p>{val?.comment_time_ago}</p>
																					</div>
																					<div className="list-rgt-btm">
																						<p>{val?.comment}</p>
																					</div>
																				</div>
																			</div>
																		</>
																	);
																})}
															</>
														) : (
															<>
																<div className="not-comments-wrap">
																	<i className="far fa-comments"></i>
																	<p>no Comments yet</p>
																	<p>
																		<small>Be the first comment</small>
																	</p>
																</div>
															</>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-12 col-12">
							<div className="donate-wrap top-sticky">
								<div className="row grid-gap">
									{fundDetails?.days_left > 0 ? (
										!auth.id ? (
											<>
												<div className="col-lg-6 col-md-12 col-sm-6 col-12">
													<div className="donate-btn-wrap">
														<button type="button" className="donate-btn" data-bs-toggle="modal" data-bs-target="#loginModal">
															<i className="fas fa-hand-holding-heart" />
															{t('funding_details.donate_now')}
														</button>
													</div>
												</div>
												<div className="col-lg-6 col-md-12 col-sm-6 col-12">
													<div className="facebook-btn-wrap">
														<button type="button" className="facebook-btn" data-bs-toggle="modal" data-bs-target="#loginModal">
															<i className="fab fa-facebook-f" />
															{t('funding_details.spread_word')}
														</button>
													</div>
												</div>
											</>
										) : (
											<>
												<div className="col-lg-6 col-md-12 col-sm-6 col-12">
													<div className="donate-btn-wrap">
														<button type="button" className="donate-btn" data-bs-toggle="modal" data-bs-target="#donationModal">
															<i className="fas fa-hand-holding-heart" d />
															{t('funding_details.donate_now')}
														</button>
													</div>
												</div>
												<div className="col-lg-6 col-md-12 col-sm-6 col-12">
													<FacebookShareButton
														url={window.location.href}
														quote={FundDetails?.campaign_title?.[lang]}
														description={FundDetails?.campaign_description?.[lang]}
														className="fb-share-btn"
													>
														<div className="facebook-btn-wrap">
															<button type="button" className="facebook-btn">
																<i className="fab fa-facebook-f" />
																{t('funding_details.spread_word')}
															</button>
														</div>
													</FacebookShareButton>
												</div>
											</>
										)
									) : (
										<>
											<div className="col-lg-6 col-md-12 col-sm-6 col-12">
												<div className="donate-btn-wrap">
													<button
														type="button"
														className="donate-btn"
														onClick={() => {
															toast.error(`Sorry ! Donation Date Expired`);
														}}
													>
														<i className="fas fa-hand-holding-heart" />
														{t('funding_details.donate_now')}
													</button>
												</div>
											</div>
											<div className="col-lg-6 col-md-12 col-sm-6 col-12">
												<div className="facebook-btn-wrap">
													<button
														type="button"
														className="facebook-btn"
														onClick={() => {
															toast.error(`Sorry ! Donation Date Expired`);
														}}
													>
														<i className="fab fa-facebook-f" />
														{t('funding_details.spread_word')}
													</button>
												</div>
											</div>
										</>
									)}
								</div>
								<div className="total-donete">
									<h3>MRU {fundDetails?.total_fund ? fundDetails?.total_fund?.toLocaleString() : 0}</h3>
									<p>
										{t('funding_details.amount_raised_of')} MRU {fundDetails?.budget_needed?.toLocaleString()}
									</p>
								</div>
								<div className="supoters-cal-wrap">
									<ul>
										<li>
											<span className="supoters-cal-pic">
												<img className="img-block" src={Handshake} alt="Handshake" />
											</span>
											<p>
												{fundDetails?.get_success_donations?.length} {t('funding_details.supporters')}
											</p>
										</li>
										<li>
											<span className="supoters-cal-pic">
												<img className="img-block" src={Calendar} alt="" />
											</span>
											<p>
												{fundDetails?.days_left > 0 ? (
													<>
														{t('funding_details.days_left')} <strong>{fundDetails?.days_left}</strong>
													</>
												) : (
													<>
														<div className="expired">{t('funding_page.donation_date_expired')}</div>
													</>
												)}
											</p>
										</li>
									</ul>
								</div>
								<div className="rgt-green-head-box mt-4">
									<div className="rgt-green-head">
										<ul>
											<li>
												<span className="rgt-green-pic">
													<img className="img-block" src={Fundraiser} alt="" />
												</span>
												<p>{t('funding_details.fund_donation_team')}</p>
											</li>
										</ul>
									</div>
									<div className="rgt-green-body">
										<div className="raiser-name">
											<h4>
												{t('funding_details.raised_by')} {fundDetails?.get_user?.first_name} {fundDetails?.get_user?.last_name}
											</h4>
										</div>
										{fundDetails?.get_teams?.length > 0 ? (
											<>
												{fundDetails?.get_teams?.map((val, i) => {
													return (
														<div className="donetar-wrap d-flex flex-wrap align-items-center justify-content-between">
															<div className="donetar-name-wrap d-flex align-items-center">
																<ProfileImage src={val?.media_path + '/' + val?.image} firstName={val?.name} lastName={''} />
																<div className="donetar-name">{val?.name}</div>
															</div>
														</div>
													);
												})}
												{fundDetails?.get_success_donations?.length > 5 && (
													<div className="rgt-green-more">
														<button type="button" className="show-more" data-bs-toggle="modal" data-bs-target="#donnerViewModal">
															Show More
															<i className="fas fa-angle-right" />
														</button>
													</div>
												)}
											</>
										) : (
											<div className="not-comments-wrap"></div>
										)}
									</div>
								</div>
								<div className="rgt-green-head-box">
									<div className="rgt-green-head">
										<ul>
											<li>
												<span className="rgt-green-pic">
													<img className="img-block" src={HandheartLine} alt="" />
												</span>
												<p>{t('funding_details.top_donar')}</p>
											</li>
										</ul>
									</div>
									<div className="rgt-green-body">
										{fundDetails?.get_success_donations?.length > 0 ? (
											fundDetails?.get_success_donations?.map((val, i) => {
												if (i <= 2) {
													return (
														<div className="donetar-wrap d-flex flex-wrap align-items-center justify-content-between">
															<div className="donetar-name-wrap d-flex align-items-center">
																<ProfileImage
																	src={val?.get_donated_by?.get_user_details?.avatar}
																	firstName={val?.get_donated_by?.first_name}
																	lastName={val?.get_donated_by?.last_name}
																/>
																<div className="donetar-name">
																	{val?.get_donated_by?.full_name.length > 25
																		? `${val?.get_donated_by?.full_name.substring(0, 25)}...`
																		: val?.get_donated_by?.full_name}
																</div>
															</div>
															<div className="donetar-amount">MRU {val?.amount}</div>
														</div>
													);
												}
											})
										) : (
											<div className="not-comments-wrap">
												<i className="fas fa-hand-holding-usd"></i>
												<p>{t('funding_details.no_donation_yet')}</p>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />

			{/* Login Modal */}
			<div
				className="modal fade cmn-modal"
				id="loginModal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="loginModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="loginModalLabel">
								{t('login')}
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<form onSubmit={handleSubmit(onSubmit)}>
								{alertType && <Alert type={alertType} message={alertMessage} />}
								<div className="admin-input-wrap mb-2">
									<label className="admin-lbl">{t('email')}</label>
									<input
										type="text"
										className="form-control admin-input-style"
										placeholder={t('enter_your_email')}
										{...register('email', { required: t('email_is_reqired') })}
									/>
									{errors.email && <InputErrorMsg error={errors.email?.message} />}
								</div>
								<div className="admin-input-wrap">
									<label className="admin-lbl">{t('password')}</label>
									<div className="add-icon">
										<input
											type={showPassword ? 'text' : 'password'}
											className="form-control admin-input-style"
											placeholder={t('enter_your_password')}
											{...register('password', {
												required: t('password_is_reqired'),
											})}
										/>
										<span className="pass-icon">
											<i
												className={showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'}
												onClick={() => {
													setShowPassword(!showPassword);
												}}
											></i>
										</span>
									</div>
									{errors.password && <InputErrorMsg error={errors.password?.message} />}
								</div>
								<div className="logreg-btn-wrap">
									<button type="submit" className="logreg-btn">
										{t('login')}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			{/* donation modal */}

			<div
				className="modal fade cmn-modal"
				id="donationModal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="donationModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="donationModalLabel">
								{t('funding_details.fund_donate')}
							</h5>
							<button type="button" id="donationModalClose" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<form onSubmit={handleSubmit2(onSubmit2)}>
								{alertType && <Alert type={alertType} message={alertMessage} />}
								<div className="donet-view-dtls">
									<p>
										{`${t('admin_user.phone_number')}:`}
										<strong className="color-green">{fundDetails?.get_user?.get_bank_details?.phone_no}</strong>
									</p>
									<p>
										{`${t('bank.mobile_banking_solution')}:`}
										<strong className="color-green">
											{fundDetails?.get_user?.get_bank_details?.mobile_banking_name === '1'
												? t('funding_details.bankili')
												: fundDetails?.get_user?.get_bank_details?.mobile_banking_name === '2'
												  ? t('funding_details.masrivi')
												  : fundDetails?.get_user?.get_bank_details?.mobile_banking_name === '3'
												    ? t('funding_details.saddad')
												    : ''}
										</strong>
									</p>
								</div>
								<div className="admin-input-wrap mb-3">
									<label className="admin-lbl">{t('funding_details.amount')}</label>
									<input
										type="number"
										className="form-control admin-input-style"
										placeholder={t('funding_details.enter_amount')}
										{...register2('amount', {
											required: 'Donation amount required',
										})}
									/>
									{errors2.amount && <InputErrorMsg error={errors2.amount?.message} />}
								</div>
								<div className="admin-input-wrap">
									<div className="product-img-wrap mb-0">
										<div className="product-img-upload">
											<input type="file" id="receiptImg" onChange={handleFile} />
											<label htmlFor="receiptImg">
												{!receiptImg && (
													<div className="product-upload-text">
														<i className="fa fa-download"></i>
														<p>{`${t('payout_order.upload_receipt')}...`}</p>
													</div>
												)}
												{receiptImg && (
													<div className="preview-img">
														<img src={receiptImg} className="img-block" alt="product" />
													</div>
												)}
											</label>
										</div>
									</div>
								</div>
								<div className="logreg-btn-wrap">
									<button type="submit" className="logreg-btn mb-0">
										{t('funding_details.submit')}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			{/* donner view modal */}

			<div
				className="modal fade cmn-modal"
				id="donnerViewModal"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex={-1}
				aria-labelledby="donnerViewModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-scrollable">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="donnerViewModalLabel">
								{t('funding_details.fund_donation_team')}
							</h5>
							<button type="button" id="donnerViewModalClose" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							{fundDetails?.get_success_donations?.length > 0 ? (
								fundDetails?.get_success_donations?.map((val, i) => {
									return (
										<div className="donetar-wrap d-flex flex-wrap align-items-center justify-content-between">
											<div className="donetar-name-wrap d-flex align-items-center">
												<ProfileImage
													src={val?.get_donated_by?.get_user_details?.avatar}
													firstName={val?.get_donated_by?.first_name}
													lastName={val?.get_donated_by?.last_name}
												/>
												<div className="donetar-name">{val?.get_donated_by?.full_name}</div>
											</div>
											<div className="donetar-amount">MRU {val?.amount}</div>
										</div>
									);
								})
							) : (
								<div className="not-comments-wrap">
									<i className="fas fa-hand-holding-usd"></i>
									<p>{t('funding_details.no_donation_yet')}</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default FundDetails;
