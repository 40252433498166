import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Breadcrumb from 'components/Admin/Breadcrumb';
import { Link } from 'react-router-dom';
import Alert from 'components/Alert';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import InputErrorMsg from 'components/InputErrorMsg';
import { useForm, Controller } from 'react-hook-form';
import { subCatList, catList, addSubCategory, subCategoryDtl, editSubCategory, delSubCategory } from './SubCategory.service.js';
import Select from 'react-select';
import './SubCategory.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function SubCategory() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm();
	const {
		register: register2,
		handleSubmit: handleSubmit2,
		formState: { errors: errors2 },
		reset: reset2,
		control: control2,
	} = useForm();
	const [subCategory, setSubCategory] = useState([]);
	const [subCategoryMeta, setSubCategoryMeta] = useState({});
	const [page, setPage] = useState(1);
	const [alertType, SetAlertType] = useState('');
	const [alertMessage, SetAlertMessage] = useState('');
	const [SubCategoryDetail, SetSubCategoryDetail] = useState('');
	const [subCatId, setSubCatId] = useState(0);
	const [category, setCategory] = useState([]);
	const lang = useSelector((state) => state.lang);
	const { t } = useTranslation();

	useEffect(() => {
		getSubCategoryList();
	}, [page]);

	useEffect(() => {
		getCategoryList();
	}, []);

	const getSubCategoryList = async () => {
		let list = await subCatList(page);
		console.log(list);
		if (list.data.status === 200) {
			setSubCategory(list.data.data.records);
			setSubCategoryMeta(list.data.data.meta);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};
	const getCategoryList = async () => {
		let list = await catList();
		console.log(list);
		if (list.data.status === 200) {
			let cat = list.data.data.category.map((res, i) => {
				return { value: res.id, label: res.name };
			});
			setCategory(cat);
		} else {
			SetAlertType('error');
			SetAlertMessage(list.data.error);
			window.scrollTo(0, 0);
		}
	};
	const onSubmit = async (data) => {
		let formData = {
			name: data.name,
			name_ar: data.name_ar,
			name_fr: data.name_fr,
			category: data.category?.value,
		};

		let result = await addSubCategory(formData);
		if (result.data.status === 200) {
			SetAlertType('success');
			SetAlertMessage(`{${t('fund_catagory.sub_category_added_successfully')}} !`);
			getSubCategoryList();
			reset();
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}

		document.querySelector('.btn-close').click();
		window.scrollTo(0, 0);
	};
	const getSubCategoryDtl = async (id) => {
		let result = await subCategoryDtl(id);
		if (result.data.status === 200) {
			SetSubCategoryDetail(result.data.data.subcategory);
			setSubCatId(result.data.data.subcategory.id);
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
			window.scrollTo(0, 0);
		}
	};
	const setDefaultValueForm = async () => {
		let defaultValues = {};
		defaultValues.name = SubCategoryDetail.name;
		defaultValues.name_fr = SubCategoryDetail.name_lang?.fr;
		defaultValues.name_ar = SubCategoryDetail.name_lang?.ar;
		defaultValues.category = { value: SubCategoryDetail.get_category?.id, label: SubCategoryDetail.get_category?.name };

		await reset2({ ...defaultValues });
	};

	useMemo(() => {
		setDefaultValueForm();
	}, [SubCategoryDetail]);

	const onSubmit2 = async (data) => {
		let formData = {
			name: data.name,
			name_ar: data.name_ar,
			name_fr: data.name_fr,
			category: data.category?.value,
		};

		let result = await editSubCategory(subCatId, formData);
		if (result.data.status === 200) {
			SetAlertType('success');
			SetAlertMessage(`{${t('fund_catagory.sub_category_updated_successfully')}} !`);
			getSubCategoryList();
		} else {
			SetAlertType('error');
			SetAlertMessage(result.data.error);
		}

		document.querySelector('#ediModalClose').click();
		window.scrollTo(0, 0);
	};
	const deleteSubCat = async (id) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="custom-confirmation-box">
						<h1>{t('admin_sub_category.are_you_sure')}</h1>
						<p>{t('admin_sub_category.you_want_to_delete_this')}</p>
						<button onClick={onClose} className="no-btn">
							{t('admin_user.no')}
						</button>
						<button
							className="yes-btn"
							onClick={async () => {
								let result = await delSubCategory(id);
								if (result.data.status === 200) {
									getSubCategoryList();
									SetAlertType('success');
									SetAlertMessage(`{${t('fund_catagory.sub_category_deleted_successfully')}} !`);
								} else {
									SetAlertType('error');
									SetAlertMessage(result.data.error);
								}
								onClose();
								window.scrollTo(0, 0);
							}}
						>
							{t('admin_user.yes_delete_it')}
						</button>
					</div>
				);
			},
		});
	};
	const columns = [
		{
			name: t('admin_sub_category.sub_category_name'),
			selector: (row) => row.name_lang?.[lang],
			sortable: true,
		},
		{
			name: t('admin_sub_category.parent_category'),
			selector: (row) => row.get_category?.name_lang?.[lang],
			sortable: true,
		},
		{
			name: t('admin_sub_category.created_at'),
			selector: (row) => moment(row.created_at).format('DD-MM-YYYY'),
			sortable: true,
		},
		{
			name: t('admin_sub_category.action'),
			cell: (row) => (
				<div className="dropdown">
					<div className="actionList " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg
							style={{ cursor: 'pointer' }}
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth={2}
							strokeLinecap="round"
							strokeLinejoin="round"
							className="feather feather-sliders dropdown-toggle"
							data-toggle="dropdown"
							role="button"
							aria-expanded="false"
						>
							<line x1={4} y1={21} x2={4} y2={14} />
							<line x1={4} y1={10} x2={4} y2={3} />
							<line x1={12} y1={21} x2={12} y2={12} />
							<line x1={12} y1={8} x2={12} y2={3} />
							<line x1={20} y1={21} x2={20} y2={16} />
							<line x1={20} y1={12} x2={20} y2={3} />
							<line x1={1} y1={14} x2={7} y2={14} />
							<line x1={9} y1={8} x2={15} y2={8} />
							<line x1={17} y1={16} x2={23} y2={16} />
						</svg>
					</div>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
						<Link
							to={'#'}
							onClick={() => {
								getSubCategoryDtl(row.id);
							}}
							className="dropdown-item"
							data-bs-toggle="modal"
							data-bs-target="#editSubCatModal"
						>
							{t('admin_sub_category.edit')}
						</Link>
						<Link
							to="#"
							className="dropdown-item"
							onClick={() => {
								deleteSubCat(row.id);
							}}
						>
							{t('admin_sub_category.delete')}
						</Link>
					</div>
				</div>
			),
		},
	];

	const tableData = {
		columns,
		data: subCategory,
	};
	return (
		<>
			<Breadcrumb
				data={[
					{ link: '#', name: t('admin_sub_category.sub_category') },
					{ link: '#', name: t('admin_sub_category.list') },
				]}
				title={t('admin_sub_category.sub_category')}
			/>
			{alertType && <Alert type={alertType} message={alertMessage} />}
			<div className="cmn-btn-tag-wrap mb-3">
				<button type="button" className="cmn-btn-tag" data-bs-toggle="modal" data-bs-target="#exampleModal">
					{t('admin_sub_category.add_sub_category')}
				</button>
			</div>

			<div className="datatable-wrap">
				<DataTable
					columns={columns}
					data={subCategory}
					striped
					pagination
					paginationServer
					paginationTotalRows={subCategoryMeta.totalRecords}
					paginationPerPage={15}
					paginationComponentOptions={{
						noRowsPerPage: true,
					}}
					onChangePage={(page) => setPage(page)}
				/>
			</div>

			{/* Add sub Category Modal */}
			<div className="modal fade cmn-modal" id="exampleModal" tabIndex={-1} data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">
								{t('admin_sub_category.add_sub_category')}
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="row">
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_sub_category.sub_category_name')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('admin_sub_category.sub_category_name')}
												{...register('name', { required: t('fund_catagory.sub_category_name_is_required') })}
											/>
											{errors.name && <InputErrorMsg error={errors.name?.message} />}
										</div>
									</div>
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_sub_category.sub_category_name_fr')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('admin_sub_category.sub_category_name_fr')}
												{...register('name_fr', { required: t('fund_catagory.sub_category_name_fr_is_required') })}
											/>
											{errors.name && <InputErrorMsg error={errors.name?.message} />}
										</div>
									</div>
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_sub_category.sub_category_name_ar')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('admin_sub_category.sub_category_name_ar')}
												{...register('name_ar', { required: t('fund_catagory.sub_category_name_ar_is_required') })}
											/>
											{errors.name && <InputErrorMsg error={errors.name?.message} />}
										</div>
									</div>
									<div className="col-12">
										<label className="lbl-heading">{t('admin_sub_category.category')}</label>
										<Controller
											name="category"
											control={control}
											render={({ field }) => <Select {...field} options={category} />}
											rules={{ required: t('admin_dashboard_menu.category_is_required') }}
										/>
										{errors.category && <InputErrorMsg error={errors.category?.message} />}
									</div>
									<div className="col-12">
										<div className="cmn-btn-tag-wrap mt-2">
											<button type="submit" className="cmn-btn-tag">
												{t('admin_sub_category.save')}
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			{/* edit sub Category Modal */}
			<div className="modal fade cmn-modal" id="editSubCatModal" tabIndex={-1} data-bs-backdrop="static" aria-labelledby="editSubCatModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="editSubCatModalLabel">
								{t('fund_catagory.edit_sub_category')}
							</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="ediModalClose">
								<i className="far fa-times-circle"></i>
							</button>
						</div>
						<div className="modal-body">
							<form onSubmit={handleSubmit2(onSubmit2)}>
								<div className="row">
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_sub_category.sub_category_name')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('admin_sub_category.sub_category_name')}
												{...register2('name', { required: t('fund_catagory.sub_category_name_is_required') })}
											/>
											{errors2.name && <InputErrorMsg error={errors2.name?.message} />}
										</div>
									</div>
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_sub_category.sub_category_name_fr')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('admin_sub_category.sub_category_name_fr')}
												{...register2('name_fr', { required: t('fund_catagory.sub_category_name_fr_is_required') })}
											/>
											{errors2.name_fr && <InputErrorMsg error={errors2.name_fr?.message} />}
										</div>
									</div>
									<div className="col-lg-12 col-md-12 col-sm-12 col-12">
										<label className="admin-lbl">{t('admin_sub_category.sub_category_name_ar')}</label>
										<div className="admim-input-wrap">
											<input
												type="text"
												className="form-control admim-input-style"
												placeholder={t('admin_sub_category.sub_category_name_ar')}
												{...register2('name_ar', { required: t('fund_catagory.sub_category_name_ar_is_required') })}
											/>
											{errors2.name_ar && <InputErrorMsg error={errors2.name_ar?.message} />}
										</div>
									</div>
									<div className="col-12">
										<label className="admin-lbl">{t('admin_sub_category.category')}</label>
										<Controller
											name="category"
											control={control2}
											render={({ field }) => <Select {...field} options={category} />}
											rules={{ required: t('admin_dashboard_menu.category_is_required') }}
										/>
										{errors2.category && <InputErrorMsg error={errors2.category?.message} />}
									</div>
									<div className="col-12">
										<div className="cmn-btn-tag-wrap mt-2">
											<button type="submit" className="cmn-btn-tag">
												{t('admin_sub_category.save')}
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SubCategory;
