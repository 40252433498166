const counter = (state = 1, action) => {
	switch (action.type) {
		case 'INCREMENT':
			return state + action.by;
		case 'DECREMENT':
			// return state - action.by;
			if (state === 0) {
				return state;
			}
			return state - action.by;
		default:
			return state;
	}
};

export default counter;
