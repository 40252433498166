import axios from 'axios';
const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL + '/auth',
	timeout: 15000,
});

export const loginUser = async (data) => {
	try {
		let result = await api.post('/login', data);
		return result;
	} catch (error) {
		return error;
	}
};
