import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarouselRtl from 'react-owl-carousel-rtl';
import './Quotes.scss';
import './Quotes.responsive.scss';
import quote_user from 'assets/images/quote-user.jpg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function Quotes() {
	const { t } = useTranslation();
	const lang = useSelector((state) => state.lang);
	const homeContent = useSelector((state) => state?.content?.home);
	var options = {};
	if (lang === 'ar') {
		options = {
			rtl: true,
			autoplayTimeout: 5000,
			smartSpeed: 2000,
			animateOut: 'fadeOut',
			loop: true,
			margin: 20,
			stagePadding: 15,
			nav: true,
			dots: false,
			navElement: 'div',
			navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
			responsive: {
				0: {
					items: 1,
				},
				600: {
					items: 2,
				},
				1000: {
					items: 3,
				},
			},
		};
	} else {
		options = {
			autoplayTimeout: 5000,
			smartSpeed: 2000,
			animateOut: 'fadeOut',
			loop: true,
			margin: 20,
			stagePadding: 15,
			nav: true,
			dots: false,
			navElement: 'div',
			navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
			responsive: {
				0: {
					items: 1,
				},
				600: {
					items: 2,
				},
				1000: {
					items: 3,
				},
			},
		};
	}

	return (
		<>
			<section className="quotes-user-sec">
				<div className="container-fluid left-right-40">
					<div className="row">
						<div className="col-12">
							<div className="sec-heading mb-3">
								<h3>{t('user_testimonies')}</h3>
							</div>
						</div>
						{homeContent?.testimonial?.length !== undefined && (
							<div className="testimonial-slider">
								{lang === 'ar' ? (
									<OwlCarouselRtl className="owl-theme slider-arrow" {...options}>
										{homeContent?.testimonial?.length > 0 ? (
											<>
												{homeContent?.testimonial?.map((val, i) => (
													<>
														<div className="item">
															<div className="quotes-user-box">
																<span className="quotes-user-img">
																	<img className="img-block" src={val?.img} alt="" />
																</span>
																<h4>{val?.name}</h4>
																<div className="quotes-user-content" dangerouslySetInnerHTML={{ __html: val?.description?.[lang] }}></div>
															</div>
														</div>
													</>
												))}
											</>
										) : (
											<>
												<div className="item">
													<div className="quotes-user-box">
														<span className="quotes-user-img">
															<img className="img-block" src={quote_user} alt="" />
														</span>
														<h4>jhon doe</h4>
														<div className="quotes-user-content">
															<p>
																Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
																aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.{' '}
															</p>
														</div>
													</div>
												</div>
												<div className="item">
													<div className="quotes-user-box">
														<span className="quotes-user-img">
															<img className="img-block" src={quote_user} alt="" />
														</span>
														<h4>jhon doe</h4>
														<div className="quotes-user-content">
															<p>
																Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
																aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.{' '}
															</p>
														</div>
													</div>
												</div>
												<div className="item">
													<div className="quotes-user-box">
														<span className="quotes-user-img">
															<img className="img-block" src={quote_user} alt="" />
														</span>
														<h4>jhon doe</h4>
														<div className="quotes-user-content">
															<p>
																Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
																aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.{' '}
															</p>
														</div>
													</div>
												</div>
											</>
										)}
									</OwlCarouselRtl>
								) : (
									<OwlCarousel className="owl-theme slider-arrow" {...options}>
										{homeContent?.testimonial?.length > 0 ? (
											<>
												{homeContent?.testimonial?.map((val, i) => (
													<>
														<div className="item">
															<div className="quotes-user-box">
																<span className="quotes-user-img">
																	<img className="img-block" src={val?.img} alt="" />
																</span>
																<h4>{val?.name}</h4>
																<div className="quotes-user-content" dangerouslySetInnerHTML={{ __html: val?.description?.[lang] }}></div>
															</div>
														</div>
													</>
												))}
											</>
										) : (
											<>
												<div className="item">
													<div className="quotes-user-box">
														<span className="quotes-user-img">
															<img className="img-block" src={quote_user} alt="" />
														</span>
														<h4>jhon doe</h4>
														<div className="quotes-user-content">
															<p>
																Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
																aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.{' '}
															</p>
														</div>
													</div>
												</div>
												<div className="item">
													<div className="quotes-user-box">
														<span className="quotes-user-img">
															<img className="img-block" src={quote_user} alt="" />
														</span>
														<h4>jhon doe</h4>
														<div className="quotes-user-content">
															<p>
																Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
																aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.{' '}
															</p>
														</div>
													</div>
												</div>
												<div className="item">
													<div className="quotes-user-box">
														<span className="quotes-user-img">
															<img className="img-block" src={quote_user} alt="" />
														</span>
														<h4>jhon doe</h4>
														<div className="quotes-user-content">
															<p>
																Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
																aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.{' '}
															</p>
														</div>
													</div>
												</div>
											</>
										)}
									</OwlCarousel>
								)}
							</div>
						)}
					</div>
				</div>
			</section>
		</>
	);
}

export default Quotes;
