import { default as api } from 'config/adminApiConf.js';

export const getProfileDtl = async () => {
	try {
		let result = await api.get('/profile-dtl');
		return result;
	} catch (error) {
		return error;
	}
};
